import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { build } from '../../container_helpers'
import * as EntityPropType from '../../shared_prop_types'
import EditTable from '../../EditTable/EditTable'

const Addresses = props => {
  const columns = [
    {
      field: 'type',
      title: 'Type',
      lookup: props.addressTypes.reduce((accum, val) => {
        accum[val.id] = val.displayName
        return accum
      }, {}),
    },
    { field: 'street', title: 'Street' },
    { field: 'city', title: 'City' },
    { field: 'state', title: 'State' },
    { field: 'zip', title: 'Zip' },
    { field: 'note', title: 'Note' },
  ]

  return (
    <EditTable
      title="Addresses"
      columns={columns}
      values={props.addresses}
      onChange={props.onChange}
      editable={props.editable}
    />
  )
}

Addresses.propTypes = {
  addresses: PropTypes.arrayOf(EntityPropType.addressType),
  addressTypes: PropTypes.arrayOf(EntityPropType.enumPropType),
  editable: PropTypes.bool,
  onChange: PropTypes.func,
}

Addresses.defaultProps = {
  editable: true,
}

const mapStateToProps = state => {
  return {
    addressTypes: state.enum.addressTypes,
  }
}

export default build({
  component: Addresses,
  mapStateToProps,
})
