import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import './DataImport.styl'
import ProductPriceImport from './ProductPriceImport/ProductPriceImport'
import TradeImport from './TradeImport/TradeImport'
import HolidayImport from './HolidayImport/HolidayImport'
import ProductImport from './ProductImport/ProductImport'

const DataImport = () => (
  <Tabs>
    <TabList>
      <Tab>Product Pricing Import</Tab>
      <Tab>Trade Blotter Import</Tab>
      <Tab>Holiday Import</Tab>
      <Tab>Product Import</Tab>
    </TabList>

    <TabPanel>
      <ProductPriceImport />
    </TabPanel>
    <TabPanel>
      <TradeImport />
    </TabPanel>
    <TabPanel>
      <HolidayImport />
    </TabPanel>
    <TabPanel>
      <ProductImport />
    </TabPanel>
  </Tabs>
)

export default DataImport
