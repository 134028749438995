import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers' // 'material-ui-pickers'
import _ from 'lodash'
import moment from 'moment'
import { actions, build } from '../container_helpers'

class PickHistoricalDate extends Component {
  async componentWillMount() {
    const { getAllProductPriceDates } = this.props
    await getAllProductPriceDates()
  }

  componentWillReceiveProps(props) {
    const {
      value,
      dates,
      updateTradeFilters,
      mode,
      alternateValue,
      initializeDate,
    } = props
    if (!dates || !dates.length) return

    if (!value && mode !== 'reset') {
      updateTradeFilters(
        {
          date: _.max(dates).format('MM-DD-YYYY'),
        },
        false
      )
    } else if (
      mode === 'reset' &&
      initializeDate &&
      this.props.onChange &&
      !alternateValue &&
      alternateValue !== ''
    ) {
      const latestDate = _.max(dates).format('MM-DD-YYYY')
      if (latestDate !== alternateValue) this.props.onChange(latestDate)
    }
  }

  onDateSelected = date => {
    date = date ? date.format('MM-DD-YYYY') : ''
    if (this.props.mode !== 'reset') {
      this.props.updateTradeFilters({ date }, !this.props.noupdate)
    }
    if (this.props.onChange) this.props.onChange(date)
  }
  render() {
    const { value, dates, alternateValue, mode } = this.props
    const minDate = dates[dates.length - 1]
    const maxDate = dates[0]

    let theValue = mode === 'reset' ? alternateValue : value

    if (mode !== 'reset' && !theValue) return null

    theValue = theValue ? moment(theValue, 'MM-DD-YYYY') : null
    return (
      <DatePicker
        style={{ width: '256px' }}
        label={this.props.label || 'Select Date'}
        autoOk
        minDate={this.props.minDate || minDate}
        maxDate={maxDate}
        shouldDisableDate={date => !_.find(dates, x => x.isSame(date))}
        value={theValue}
        onChange={this.onDateSelected}
        format="MM/DD/YYYY"
        clearable={this.props.clearable}
      />
    )
  }
}

PickHistoricalDate.propTypes = {
  getAllProductPriceDates: PropTypes.func,
  onChange: PropTypes.func,
  updateTradeFilters: PropTypes.func,
  dates: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string,
  alternateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mode: PropTypes.string,
  noupdate: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.string,
  clearable: PropTypes.bool,
  initializeDate: PropTypes.bool,
}

PickHistoricalDate.defaultProps = {
  initializeDate: true,
}

const mapDispatchToProps = {
  getAllProductPriceDates: actions.productPrice.getAllDates,
  updateTradeFilters: actions.tradeBlotter.updateTradeFilters,
}

const mapStateToProps = state => {
  return {
    dates: _.map(state.productPriceDate, x => moment(x)),
    value: state.tradeBlotter.selected.date,
  }
}

export default build({
  component: PickHistoricalDate,
  mapDispatchToProps,
  mapStateToProps,
})
