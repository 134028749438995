import moment from 'moment'

const getDateRangeText = (startDate, endDate) => {
  let format = 'MM.DD.YYYY'
  if (endDate) {
    format = 'MM.DD.YY'
    return `${moment(startDate).format(format)} to ${moment(
      endDate,
      'MM-DD-YYYY'
    ).format(format)}`
  }
  return `${moment(startDate, 'MM-DD-YYYY').format(format)}`
}

export const getFileName = (entityName, reportName, startDate, endDate) =>
  `${entityName} (${reportName}) ---${getDateRangeText(startDate, endDate)}.pdf`

const getUrl = (baseUrl, entityId, startDate, endDate) =>
  `/${baseUrl}/${entityId}/${moment(startDate, 'MM-DD-YYYY').format(
    'YYYY-MM-DD'
  )}/${endDate ? moment(endDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''}`

export const getRenderData = (
  report,
  entityId,
  startDate,
  endDate,
  entityName
) => {
  return {
    url: getUrl(report.baseUrl, entityId, startDate, endDate),
    fileName: getFileName(
      entityName,
      report.reportFileName,
      startDate,
      endDate
    ),
    scale: report.scale,
    pageLayout: report.pageLayout,
    margins: report.margins,
  }
}
