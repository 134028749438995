import { handleActions } from 'redux-actions'

const defaultState = []

const reducerMap = {
  'IMPORT/PRODUCTPRICES': (state, { payload }) => payload,
  'IMPORT/TRADES': (state, { payload }) => payload,
}

export default handleActions(reducerMap, defaultState)
