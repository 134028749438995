import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './MarkToMarketSummary.styl'
import {
  tradeBlotterPropType,
  bankPropType,
  bankCustomerPropType,
  entityTypePropType,
} from '../../shared_prop_types'
import { currencyDiv } from '../../../helpers/formatCurrency'
import pluralize from 'pluralize'

const gainOrLoss = item => {
  if (item.markToMarket === 0) return '-'
  return item.markToMarket > 0
    ? 'has a mark to market gain with'
    : 'has a mark to market loss with'
}

const SubTable = props => {
  const { csvData, items, totals } = props

  return (
    <React.Fragment>
      <tr className="sub-header">
        <th colSpan="3" className="sub-header">
          <h4>{props.tableTitle.toUpperCase()}</h4>
        </th>
        <th colSpan="3" />
      </tr>
      {items.map(item => (
        <tr key={item.name}>
          <td>{props.bank}</td>
          <td>{gainOrLoss(item)}</td>
          <td>{item.name}</td>
          <td>
            {csvData ? item.markToMarket : currencyDiv(item.markToMarket)}
          </td>
          <td>
            {csvData ? (
              item.netOptionPremium
            ) : (
              currencyDiv(item.netOptionPremium)
            )}
          </td>
          <td>{csvData ? item.net : currencyDiv(item.net)}</td>
        </tr>
      ))}
      <tr className="sub-total">
        <th colSpan="3">{pluralize.singular(props.tableTitle)} Total</th>
        <td>
          {csvData ? totals.markToMarket : currencyDiv(totals.markToMarket)}
        </td>
        <td>
          {csvData ? (
            totals.netOptionPremium
          ) : (
            currencyDiv(totals.netOptionPremium)
          )}
        </td>
        <td>{csvData ? totals.net : currencyDiv(totals.net)}</td>
      </tr>
    </React.Fragment>
  )
}

SubTable.propTypes = {
  bank: PropTypes.string,
  tableTitle: PropTypes.string,
  items: PropTypes.array,
  totals: PropTypes.object,
  csvData: PropTypes.bool,
}
export default SubTable
