import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as EntityPropType from '../../shared_prop_types'
import { build } from '../../container_helpers'
import EditTable from '../../EditTable/EditTable'

const Emails = props => {
  const columns = [
    {
      field: 'type',
      title: 'Type',
      lookup: props.emailTypes.reduce((accum, val) => {
        accum[val.id] = val.displayName
        return accum
      }, {}),
    },
    { field: 'emailAddress', title: 'Email', type: 'string' },
    { field: 'note', title: 'Note', type: 'string' },
  ]

  const handleChange = (emails, newEmail) => {
    let newEmails = emails
    if (newEmail && newEmail.type === 'Main') {
      newEmails = newEmails.map(x => {
        if (x !== newEmail && x.type === 'Main') {
          x.type = 'Other'
        }
        return x
      })
    }
    if (props.onChange) props.onChange(newEmails)
  }

  return (
    <EditTable
      title="Emails"
      columns={columns}
      values={props.emails}
      onChange={handleChange}
      editable={props.editable}
      options={{
        padding: 'dense',
        search: false,
        paging: false,
        // paging: addresses && addresses.length && addresses.length > 5,
      }}
    />
  )
}

Emails.propTypes = {
  emails: PropTypes.arrayOf(EntityPropType.emailType),
  emailTypes: PropTypes.arrayOf(EntityPropType.enumPropType),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

Emails.defaultProps = {
  editable: true,
}

const mapStateToProps = state => {
  return {
    emailTypes: state.enum.emailTypes,
  }
}

export default build({
  component: Emails,
  mapStateToProps,
})
