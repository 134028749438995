import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { DatePicker } from 'material-ui-pickers'
import * as EntityPropType from '../../shared_prop_types'
import EditTable from '../../EditTable/EditTable'

const HedgeLines = props => {
  const columns = [
    { field: 'value', title: 'Value' },
    {
      field: 'writtenDate',
      title: 'Written Date',
      // type: 'date',
      render: rowData => (
        <span>
          {rowData.writtenDate ? (
            moment(rowData.writtenDate).format('MM/DD/YYYY')
          ) : (
            ''
          )}
        </span>
      ),
      editComponent: p => (
        <DatePicker
          name="writtenDate"
          format="MM/DD/YYYY"
          views={['year', 'month', 'day']}
          value={p.value}
          onChange={date => {
            p.onChange(date)
          }}
          clearable
          showTodayButton
        />
      ),
    },
    {
      field: 'approvedDate',
      title: 'Approved Date',
      type: 'date',
      render: rowData => (
        <span>
          {rowData.approvedDate ? (
            moment(rowData.approvedDate).format('MM/DD/YYYY')
          ) : (
            ''
          )}
        </span>
      ),
      editComponent: p => (
        <DatePicker
          name="approvedDate"
          format="MM/DD/YYYY"
          views={['year', 'month', 'day']}
          value={p.value}
          onChange={date => {
            p.onChange(date)
          }}
          showTodayButton
        />
      ),
    },
  ]

  return (
    <EditTable
      title="Hedge Lines"
      columns={columns}
      values={props.hedgeLines}
      onChange={props.onChange}
      editable={props.editable}
      options={{
        padding: 'dense',
        search: false,
        paging: false,
        // paging: addresses && addresses.length && addresses.length > 5,
      }}
    />
  )
}

HedgeLines.propTypes = {
  hedgeLines: PropTypes.arrayOf(EntityPropType.hedgelineType),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

HedgeLines.defaultProps = {
  editable: true,
}

export default HedgeLines
