import { createActions } from 'redux-actions'

const reportActions = createActions({
  MTMSUMMARY: {
    SET: undefined,
  },
  MARKTOMARKET: {
    SET: undefined,
  },
})

reportActions.getMTMSummaryForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { settleDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/summaries/markToMarket?settleDate=${settleDate ||
      ''}`
  )
  dispatch(reportActions.mtmsummary.set(response.data))
}

reportActions.getMarkToMarketForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { settleDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/reports/markToMarket?settleDate=${settleDate ||
      ''}`
  )
  dispatch(reportActions.marktomarket.set(response.data))
}

export default reportActions
