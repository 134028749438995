import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { withRouter, Link } from 'react-router-dom'
import Permissions from './Permissions'
import { getTradeBlotterColumnNames } from '../../TradeBlotter/blotter_columns'

const PermissionsTab = props => (
  <Tabs>
    <TabList>
      <Tab>Page</Tab>
      <Tab>Report</Tab>
      <Tab>Trade Blotter</Tab>
    </TabList>
    <TabPanel>
      <Permissions
        permissions={Object.keys(props.permissions.pages).reduce((perms, x) => {
          perms[x] = {
            ...props.permissions.pages[x],
            content: (
              <Link to={`/${x}`} target="_blank">
                {x}
              </Link>
            ),
          }
          return perms
        }, {})}
        onChanged={pages =>
          props.onChanged({
            ...props.permissions,
            pages,
          })}
      />
    </TabPanel>
    <TabPanel>
      <Permissions
        permissions={props.permissions.reports}
        onChanged={reports =>
          props.onChanged({
            ...props.permissions,
            reports,
          })}
      />
    </TabPanel>
    <TabPanel>
      <Permissions
        permissions={_.pick(
          props.permissions.tradeBlotterColumns,
          getTradeBlotterColumnNames()
        )}
        onChanged={tradeBlotterColumns =>
          props.onChanged({
            ...props.permissions,
            tradeBlotterColumns,
          })}
      />
    </TabPanel>
  </Tabs>
)

PermissionsTab.propTypes = {
  permissions: PropTypes.object,
  onChanged: PropTypes.func,
}

Permissions.defaultProps = {
  editable: true,
}

export default withRouter(PermissionsTab)
