import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import classnames from 'classnames'
// import '../../../application.styl'
import styles from './ThresholdSummaryReport.styl'
import { build, actions } from '../../container_helpers'
import { currencyDiv } from '../../../helpers/formatCurrency'
import { formatPercentage } from '../../../helpers/formatPercentage'
import * as EntityPropType from '../../shared_prop_types'

class ThresholdSummaryReport extends Component {
  constructor() {
    super()
    this.state = {
      firstUpdate: false,
    }
  }

  componentWillMount() {
    const { id, settleDate } = this.props.match.params
    this.props.getThresholdSummaries(id, { settleDate })
    this.props.getEntity(id)
  }

  componentWillReceiveProps(props) {
    if (
      this.props.match.params.id !== props.match.params.id ||
      this.props.match.params.settleDate !== props.match.params.settleDate
    ) {
      const { id, settleDate } = props.match.params
      this.props.getThresholdSummaries(id, { settleDate })
      this.props.getEntity(id)
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { thresholdSummaries, entity } = this.props
    return !_.isEmpty(entity) && !_.isEmpty(thresholdSummaries)
  }

  renderReport(csvData) {
    try {
      const { thresholdSummaries, entity } = this.props
      const { settleDate } = this.props.match.params
      if (!thresholdSummaries) return null
      const dateText = moment(settleDate).format('MM/DD/YYYY')

      const totals = {
        combined: _.sumBy(
          thresholdSummaries.map(x => {
            return { ...x, combined: parseFloat(x.combined.toFixed(2)) }
          }),
          'combined'
        ),
        impliedMargin: _.sumBy(
          thresholdSummaries.map(x => {
            return {
              ...x,
              impliedMargin: parseFloat(x.impliedMargin.toFixed(2)),
            }
          }),
          'impliedMargin'
        ),
        markToMarket: _.sumBy(
          thresholdSummaries.map(x => {
            return { ...x, markToMarket: parseFloat(x.markToMarket.toFixed(2)) }
          }),
          'markToMarket'
        ),
      }
      const logo = `${process.env.API_URL}/${entity.logo}`
      const customersOrCounterparties = _.every(
        thresholdSummaries,
        x => x.isBankCustomer
      )
        ? 'CUSTOMERS'
        : 'COUNTERPARTIES'
      return (
        <div className={styles.thresholdSummary} align="center">
          <div>
            <table
              className={classnames('summary-table', csvData && 'csv-table')}
            >
              <thead>
                <tr className="summary-header-1">
                  <th align="center" colSpan="8">
                    <div>
                      <img
                        className="tappian-report-logo"
                        src="/img/tappian-logo-color.svg"
                        alt="tappian logo"
                      />
                      <h4>COMPANY THRESHOLD SUMMARY</h4>
                      <img className="report-logo" src={logo} alt="bank logo" />
                    </div>
                  </th>
                </tr>
                <tr className="summary-header-2">
                  <th>{dateText}</th>
                  <th>Combined</th>
                  <th>Implied Margin</th>
                  <th>Current MTM</th>
                  <th>Hedge Line</th>
                  <th>Exposure</th>
                  <th>Capacity Utilization</th>
                  <th>Good to Trade?</th>
                </tr>
                <tr className="summary-header-3">
                  <th>{customersOrCounterparties}</th>
                  <th colSpan="7" />
                </tr>
              </thead>
              <tbody>
                {thresholdSummaries.map(x => {
                  let color
                  if (x.capacityUtilization <= 0.7) color = '#87C947'
                  else if (
                    x.capacityUtilization > 0.7 &&
                    x.capacityUtilization <= 0.8
                  )
                    color = 'yellow'
                  else color = 'red'
                  return (
                    <tr key={x.name}>
                      <td>{x.name}</td>
                      <td>{csvData ? x.combined : currencyDiv(x.combined)}</td>
                      <td>
                        {csvData ? (
                          x.impliedMargin
                        ) : (
                          currencyDiv(x.impliedMargin)
                        )}
                      </td>
                      <td>
                        {csvData ? x.markToMarket : currencyDiv(x.markToMarket)}
                      </td>
                      <td>
                        {csvData ? x.hedgeLine : currencyDiv(x.hedgeLine)}
                      </td>
                      <td>
                        {csvData ? (
                          x.exposure
                        ) : (
                          formatPercentage(x.exposure * 100)
                        )}
                      </td>
                      <td>
                        {csvData ? (
                          x.capacityUtilization
                        ) : (
                          formatPercentage(x.capacityUtilization * 100)
                        )}
                      </td>
                      <td style={{ backgroundColor: color }} />
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td align="right">Totals</td>
                  <td>
                    {csvData ? totals.combined : currencyDiv(totals.combined)}
                  </td>
                  <td>
                    {csvData ? (
                      totals.impliedMargin
                    ) : (
                      currencyDiv(totals.impliedMargin)
                    )}
                  </td>
                  <td>
                    {csvData ? (
                      totals.markToMarket
                    ) : (
                      currencyDiv(totals.markToMarket)
                    )}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    } catch (e) {
      return null
    }
  }

  render() {
    return (
      <div>
        {this.renderReport()}
        {this.props.csvData && this.renderReport(true)}
      </div>
    )
  }
}

ThresholdSummaryReport.propTypes = {
  getThresholdSummaries: PropTypes.func,
  thresholdSummaries: PropTypes.arrayOf(PropTypes.object),
  match: EntityPropType.settleDateRouteType.isRequired,
  getEntity: PropTypes.func,
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
}

const mapDispatchToProps = {
  getThresholdSummaries:
    actions.tradeSummary.getAllThresholdSummariesForEntityId,
  getEntity: actions.entity.get,
}

const mapStateToProps = state => {
  return {
    thresholdSummaries: state.tradeSummaries,
    entity: state.entity,
  }
}

export default build({
  component: ThresholdSummaryReport,
  mapDispatchToProps,
  mapStateToProps,
})
