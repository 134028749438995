import React, { Component } from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { build, actions } from '../container_helpers'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { menuItems } from '../../constants'

export function requireAuthentication(WrappedComponent, children, belt) {
  class AuthenticatedComponent extends Component {
    async componentWillMount() {
      if (isEmpty(this.props.user)) {
        await this.props.getUser()
      }
      this.checkAuth()
    }

    async componentDidUpdate() {
      if (isEmpty(this.props.user)) {
        await this.props.getUser()
      }
      this.checkAuth()
    }

    checkAuth() {
      const { isAdmin, user, isAuthenticated, history, location } = this.props
      const actualPath = location.pathname

      if (!isAuthenticated && actualPath) {
        history.push({ pathname: '/login' })
      }
      const access = user.allowedPages
      const cleanedPath = _.first(actualPath.substring(1).split('/'))
      if (!isAdmin && user && access && !access.find(x => x === cleanedPath)) {
        history.push({ pathname: '/unauthorized' })
      }
    }

    render() {
      return (
        <div>
          {this.props.isAuthenticated && (
            <WrappedComponent {...this.props} children={children} belt={belt} />
          )}
        </div>
      )
    }
  }

  AuthenticatedComponent.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    isAdmin: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    user: PropTypes.object,
    getUser: PropTypes.func,
  }

  const mapDispatchToProps = {
    getUser: actions.login.get,
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: !isEmpty(state.login.user),
      isAdmin: state.login.user.isAdmin,
      user: state.login.user,
    }
  }

  return withRouter(
    build({
      component: AuthenticatedComponent,
      mapDispatchToProps,
      mapStateToProps,
    })
  )
}
