import _ from 'lodash'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { build, actions } from '../../container_helpers'

export const useEntityForm = props => {
  const [newEntity, setEntity] = useState(props.defaults)
  const [errors, setErrors] = useState({})

  // mounted for first time
  useEffect(() => {
    const { getData, selected, getEntity } = props
    if (getData) getData()

    // refresh entity
    if (!_.isEmpty(selected)) getEntity(selected)
  }, [])

  // when the selected entity data is updated.
  useEffect(
    () => {
      setEntity(
        _.mergeWith(
          { ...props.defaults },
          props.selected,
          (o, s) => (_.isNull(s) ? o : s)
        )
      )
    },
    [props.selected]
  )

  const createEntity = async () => {
    let result
    if (!_.isEmpty(props.selected)) {
      result = await props.updateEntity(newEntity)
    } else {
      result = await props.createEntity(newEntity)
    }
    // if (result) {
    //   props.toggleSidebar()
    // }
    return result
  }

  return [
    newEntity,
    newState =>
      setEntity(oldState => {
        return { ...oldState, ...newState }
      }),
    errors,
    setErrors,
    createEntity,
  ]
}

useEntityForm.propTypes = {
  selected: PropTypes.object,
  defaults: PropTypes.object,
  getEntity: PropTypes.func,
  getData: PropTypes.func,
  toggleSidebar: PropTypes.func,
}

const mapDispatchToProps = {
  toggleSidebar: actions.sidebar.toggle,
}

export default build({
  component: useEntityForm,
  mapDispatchToProps,
})
