import React from 'react'
import './Login.styl'
import '../../application.styl'
import LoginSignUpBg from '../LoginSignUpBg/LoginSignUpBg'
import LoginForm from '../Forms/Login/LoginForm'

const Login = () => (
  <div className="container-fluid">
    <div className="row">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoginSignUpBg />
        <div className="col-xs-12 col-sm-10 col-md-6 col-lg-4 form-container">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
)

export default Login
