import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 256,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    select: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
    },
  }
})

const Dropdown = props => {
  const classes = props.styles || useStyles()
  return (
    <FormControl className={classes.formControl} error={props.errorText}>
      <InputLabel>{props.floatingLabelText}</InputLabel>
      <Select
        value={props.value}
        onChange={props.onChange}
        disabled={!!props.disabled}
      >
        {props.children}
      </Select>
      <FormHelperText>{props.errorText}</FormHelperText>
    </FormControl>
  )
}

Dropdown.propTypes = {
  floatingLabelText: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  errorText: PropTypes.string,
}

export default Dropdown
