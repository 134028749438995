import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Item from './../Item/Item'
import { build } from '../container_helpers'
import './Dashboard.styl'

const Dashboard = props => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Item title="TRADE BLOTTER" link="/trade-blotter" imgUrl="businessman" />

    {props.user.role !== 'User' && (
      <React.Fragment>
        <Item
          title="MARK TO MARKET"
          link="/mark-to-market"
          imgUrl="oil-price"
        />

        <Item title="THRESHOLD" link="/threshold-summary" imgUrl="line-chart" />
      </React.Fragment>
    )}

    <Item title="REPORTING" link="/report-generator" imgUrl="pie-chart" />
  </div>
)

Dashboard.propTypes = {
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
  }
}

export default build({
  component: Dashboard,
  mapStateToProps,
})
