import { createActions } from 'redux-actions'

const { resetpassword: resetPasswordActions } = createActions({
  RESETPASSWORD: {
    SET: undefined,
  },
})

resetPasswordActions.resetPassword = email => ({ axios }) => async dispatch => {
  try {
    await axios.put(`api/user/${email}/password/reset`)
    dispatch(resetPasswordActions.set({success: true}))
  } catch (e) {
    dispatch(resetPasswordActions.set({success: false}))
  }
}

resetPasswordActions.clearStatus = () => () => dispatch => {
  dispatch(resetPasswordActions.set(null))
}

export default resetPasswordActions
