import React from "react"
import PropTypes from "prop-types"
import { DatePicker } from "material-ui-pickers"

const date = ({ props } = {}) => {
  const Date = ({ value, onValue }) => (
    <DatePicker
      {...props}
      value={value}
      onChange={onValue}
      showTodayButton
    />
  )
  Date.propTypes = {
    value: PropTypes.any,
    onValue: PropTypes.func,
  }

  return Date
}

export default date
