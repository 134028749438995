import { createActions } from 'redux-actions'
import { tryCatchAction } from '../storeHelpers/reduxActionHelpers'

const { entity: entityActions } = createActions({
  ENTITY: {
    SET: undefined,
    SETLOGO: undefined,
  },
})

entityActions.get = id => ({ axios }) => async dispatch => {
  const response = await axios.get(`api/businessEntity/${id}`)
  dispatch(entityActions.set(response.data))
}
entityActions.uploadLogo = (file, id) => ({ axios }) => async dispatch => {
  tryCatchAction(
    async () => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        await axios.post(`/api/businessEntity/${id}/logo`, {
          data: reader.result,
          fileName: file.name,
        })
      }
    },
    dispatch,
    'Logo Updated Successfully.'
  )
}

entityActions.clearTrades = id => ({ axios }) => async dispatch => {
  tryCatchAction(
    async () => {
      await axios.delete(`/api/businessEntity/${id}/trades`)
    },
    dispatch,
    'Trades Cleared Successfully.'
  )
}
export default entityActions
