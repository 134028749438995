import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import classnames from 'classnames'
import '../../../application.styl'
import styles from './OptionPremiumReport.styl'
import { build, actions } from '../../container_helpers'
import {
  formatCurrency,
  currencyFragment,
} from '../../../helpers/formatCurrency'
import * as EntityPropType from '../../shared_prop_types'

class OptionPremiumReport extends Component {
  constructor() {
    super()
    this.state = {
      firstUpdate: false,
    }
  }

  componentWillMount() {
    const { id, startDate, endDate } = this.props.match.params
    this.props.getTradeSummaries(id, {
      tradeDate: startDate,
      tradeEndDate: endDate,
      optionsOnly: true,
    })
    this.props.getEntity(id)
  }

  componentWillReceiveProps(props) {
    if (
      this.props.match.params.id !== props.match.params.id ||
      this.props.match.params.startDate !== props.match.params.startDate ||
      this.props.match.params.endDate !== props.match.params.endDate
    ) {
      const { id, startDate, endDate } = props.match.params
      this.props.getTradeSummaries(id, {
        tradeDate: startDate,
        tradeEndDate: endDate,
        optionsOnly: true,
      })
      this.props.getEntity(id)
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { tradeSummaries, entity } = this.props
    return !_.isEmpty(entity) && !_.isEmpty(tradeSummaries)
  }

  getSettlementText = totalSum => {
    const { tradeSummaries, entity } = this.props

    const first = _.first(tradeSummaries)
    if (!first) return ''

    if (totalSum === 0) return 'No Option Premium Due'

    let settlementText = `Total Option Premium to be PAID`
    if (
      (entity.isIndependentEntity && totalSum < 0) ||
      (!entity.isIndependentEntity && totalSum > 0)
    )
      settlementText = `Total Option Premium to be REC'D`

    const hasReceivedByDate = !_.some(
      tradeSummaries,
      x => x.receivedByDate !== first.receivedByDate
    )
    if (hasReceivedByDate) {
      settlementText += ` by ${moment(first.receivedByDate).format(
        'MM/DD/YYYY'
      )}`
    }
    return settlementText
  }

  renderReport(csvData) {
    try {
      const { tradeSummaries, entity } = this.props
      const { startDate, endDate } = this.props.match.params
      if (!tradeSummaries) return null

      const byReferenceId = Object.values(
        _.groupBy(tradeSummaries, 'referenceId')
      )

      const totalSum = _.sumBy(tradeSummaries, 'optionPremium')
      const settlementText = this.getSettlementText(totalSum)
      const dateText =
        moment(startDate).format('MM/DD/YYYY') +
        (endDate ? ` to ${moment(endDate).format('MM/DD/YYYY')}` : '')
      const logo = `${process.env.API_URL}/${entity.logo}`
      return (
        <div className={styles.optionPremiumReport} align="center">
          <div className="table-wrapper">
            <table
              className={classnames(
                'summary-table',
                'odd-rows-print',
                csvData && 'csv-table'
              )}
            >
              <thead>
                <tr className="summary-header-1">
                  <th align="center" colSpan="19">
                    <div>
                      <img
                        className="tappian-report-logo"
                        src="/img/tappian-logo-color.svg"
                        alt="tappian logo"
                      />
                      <h4>{entity.name}</h4>
                      <img className="report-logo" src={logo} alt="bank logo" />
                    </div>
                  </th>
                </tr>
                <tr className="summary-header-2">
                  <th colSpan="2">
                    <h4>{dateText}</h4>
                  </th>
                  <th colSpan="6">
                    <h4>OPTION PREMIUM INVOICE</h4>
                  </th>
                  <th colSpan="7">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h4 style={{ marginLeft: '1em' }}>{settlementText}</h4>
                      <h4>
                        {csvData ? (
                          Math.abs(totalSum)
                        ) : (
                          formatCurrency(Math.abs(totalSum))
                        )}
                      </h4>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Trade Date</th>
                  <th>Reference ID</th>
                  <th>
                    Trade<br />Group ID
                  </th>
                  <th>Buyer</th>
                  <th>Seller</th>
                  <th>
                    Monthly<br />Volume
                  </th>
                  <th>Product Type</th>
                  <th>Product</th>
                  <th>Strike Price</th>
                  <th>Instrument</th>
                  <th>Trade Price</th>
                  <th>Start Month</th>
                  <th>End Month</th>
                  <th style={{ minWidth: '140px' }}>Option Premium</th>
                </tr>
              </thead>
              <tbody>
                {byReferenceId.map(summaries => {
                  const first = _.first(summaries)
                  return (
                    <tr
                      key={`tr ${first.tradeGroupId} ${first.buyerId} ${first.sellerId} ${first.tradeDateTime}`}
                    >
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`tradeDateTime ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {moment(x.tradeDateTime).format('MM/DD/YYYY')}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`referenceId ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.referenceId}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`tradeGroupId ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.tradeGroupId}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`buyerName ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.buyerName}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`sellerName ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.sellerName}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`monthlyVolume ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.monthlyVolume.toLocaleString('en-US')}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`productType ${x.tradeGroupId} ${x.buyerId} ${x.sellerId}`}
                          >
                            {x.productType}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`productId ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.productId}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`strikePrice ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                            className="currency"
                          >
                            {csvData ? (
                              x.strikePrice
                            ) : (
                              currencyFragment(x.strikePrice)
                            )}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`instrumentType ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {x.instrumentType}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`tradePrice ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                            className="currency"
                          >
                            {csvData ? (
                              x.tradePrice
                            ) : (
                              currencyFragment(x.tradePrice, 4)
                            )}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`startDate ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {moment(first.startDate).format('MMM-YY')}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`endDate ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                          >
                            {moment(x.endDate).format('MMM-YY')}
                          </div>
                        ))}
                      </td>
                      <td>
                        {summaries.map(x => (
                          <div
                            key={`optionPremium ${x.tradeGroupId} ${x.buyerId} ${x.sellerId} ${x.tradeDateTime}`}
                            className="currency"
                          >
                            {csvData ? (
                              x.optionPremium
                            ) : (
                              currencyFragment(x.optionPremium)
                            )}
                          </div>
                        ))}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )
    } catch (e) {
      return null
    }
  }

  render() {
    return (
      <div>
        {this.renderReport()}
        {this.props.csvData && this.renderReport(true)}
      </div>
    )
  }
}

OptionPremiumReport.propTypes = {
  tradeSummaries: PropTypes.arrayOf(PropTypes.Object),
  getTradeSummaries: PropTypes.func,
  getEntity: PropTypes.func,
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
  match: EntityPropType.startEndDateRouteType.isRequired,
  generateReport: PropTypes.func,
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
}

const mapDispatchToProps = {
  getTradeSummaries: actions.tradeSummary.getAllForEntityId,
  generateReport: actions.pdf.generate,
  getEntity: actions.entity.get,
}

const mapStateToProps = state => {
  return {
    tradeSummaries: state.tradeSummaries,
    entity: state.entity,
  }
}

export default build({
  component: OptionPremiumReport,
  mapDispatchToProps,
  mapStateToProps,
})
