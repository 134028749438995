import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Table from 'reactabular-table'
import _ from 'lodash'

import ContentAdd from 'material-ui/svg-icons/content/add-circle-outline'
import ContentRemove from 'material-ui/svg-icons/content/remove-circle-outline'
import IconButton from 'material-ui/IconButton'
import ColumnVisibilityModal from '../ColumnVisibilityModal/ColumnVisibilityModal'
import { actions, build } from '../container_helpers'

const iconStyles = {
  icon: {
    width: 20,
    height: 20,
    fill: '#757575',
  },
  button: {
    width: 20,
    height: 20,
    padding: 0,
  },
}
const plusIcon = props => (
  <IconButton iconStyle={iconStyles.icon} style={iconStyles.button} {...props}>
    <ContentAdd />
  </IconButton>
)

const minusIcon = props => (
  <IconButton iconStyle={iconStyles.icon} style={iconStyles.button} {...props}>
    <ContentRemove />
  </IconButton>
)

const TradeBlotterTable = props => {
  const { cols, onRow, paginatedRows, openColumnVisibility } = props
  // hack to work around rectabular-table bug that overwrites the row.index values
  paginatedRows.forEach(row => {
    row.indexName = row.index
    delete row.index
  })

  const [columns, setColumns] = useState(cols)
  useEffect(
    () => {
      setColumns(cols)
    },
    [cols]
  ) // Only rerun if initialDesc changes

  const onToggleColumn = ({ columnIndex }) => {
    const newCols = _.cloneDeep(columns)
    newCols[columnIndex].visible = !newCols[columnIndex].visible
    setColumns(newCols)
  }

  return (
    <div>
      <div style={{ paddingTop: '20px' }}>
        {plusIcon({ onClick: openColumnVisibility })}
        <ColumnVisibilityModal />
      </div>
      <Table.Provider
        className="pure-table pure-table-striped"
        columns={columns.filter(col => col.visible)}
        style={{
          marginTop: '20px',
          display: 'block',
          overflow: 'auto',
        }}
      >
        <Table.Header />

        <Table.Body onRow={onRow} rows={paginatedRows} rowKey="id" />
      </Table.Provider>
    </div>
  )
}

TradeBlotterTable.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.objectOf(PropTypes.string),
  onRow: PropTypes.func,
  paginatedRows: PropTypes.arrayOf(PropTypes.object),
  openColumnVisibility: PropTypes.func,
}

const mapDispatchToProps = {
  openColumnVisibility: actions.tradeBlotter.columnvisibility.open,
}

export default build({
  component: TradeBlotterTable,
  mapDispatchToProps,
})
