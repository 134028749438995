import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Dropdown from '../Dropdown/Dropdown'
import * as EntityPropTypes from '../shared_prop_types'

const useStyles = makeStyles(theme => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 256,
    },
  }
})
const SubEntitySelector = props => {
  const menuItems = [
    ...props.entities.map(el => (
      <MenuItem key={el.id} value={el.id}>
        {el.displayName}
      </MenuItem>
    )),
  ]

  if (!props.noAll && menuItems.length > 1) {
    menuItems.splice(
      0,
      0,
      <MenuItem key={-1} value={-1}>
        All
      </MenuItem>
    )
  }
  return (
    <Dropdown
      floatingLabelText="Select Entity"
      value={props.selected != -1 ? props.selected : -1}
      onChange={props.onChange}
      hintText="Select"
      styles={props.styles || useStyles()}
    >
      {menuItems}
    </Dropdown>
  )
}

SubEntitySelector.propTypes = {
  entities: PropTypes.oneOfType([
    PropTypes.arrayOf(EntityPropTypes.bankPropType),
    PropTypes.arrayOf(EntityPropTypes.privateEquityPropType),
    PropTypes.arrayOf(EntityPropTypes.bankCustomerPropType),
    PropTypes.arrayOf(EntityPropTypes.counterpartyPropType),
  ]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  noAll: PropTypes.bool,
}

const EntitySelector = props => {
  const [selectedIndependentEntity, setSelectedIndependentEntity] = useState(
    props.selectedIndependentEntity || -1
  )
  const [selectedDependentEntity, setSelectedDependentEntity] = useState(-1)
  const [dependentEntities, setDependentEntities] = useState([])
  const showIndependentEntitySelector = props.independentEntities.length > 1
  const showDependentEntitySelector =
    !props.noDependent && Boolean(dependentEntities.length)

  useEffect(
    () => {
      if (selectedIndependentEntity) {
        setDependentEntities(
          props.dependentEntities.filter(
            x => x.bankId === selectedIndependentEntity
          )
        )
      }
    },
    [selectedIndependentEntity]
  )

  useEffect(
    () => {
      if (props.independentEntities.length === 1) {
        const id = props.independentEntities[0].id
        setSelectedIndependentEntity(id)
        setDependentEntities(
          props.dependentEntities.filter(x => x.bankId === id)
        )
      }
    },
    [props.independentEntities, props.dependentEntities]
  )

  return (
    <React.Fragment>
      {showIndependentEntitySelector && (
        <div className="col-xs-2">
          <SubEntitySelector
            entities={props.independentEntities}
            onChange={e => {
              setSelectedIndependentEntity(e.target.value)
              if (e.target.value !== -1) {
                props.onChange(e.target.value)
              } else {
                props.onChange('')
              }
            }}
            selected={selectedIndependentEntity}
            noAll
            styles={props.styles}
          />
        </div>
      )}
      {showDependentEntitySelector && (
        <div className="col-xs-2">
          <SubEntitySelector
            entities={dependentEntities}
            onChange={e => {
              setSelectedDependentEntity(e.target.value)
              if (e.target.value !== -1) {
                props.onChange(e.target.value)
              } else {
                props.onChange(selectedIndependentEntity)
              }
            }}
            selected={selectedDependentEntity}
            styles={props.styles}
          />
        </div>
      )}
    </React.Fragment>
  )
}

EntitySelector.propTypes = {
  independentEntities: PropTypes.oneOfType([
    PropTypes.arrayOf(EntityPropTypes.bankPropType),
    PropTypes.arrayOf(EntityPropTypes.privateEquityPropType),
  ]),
  dependentEntities: PropTypes.oneOfType([
    PropTypes.arrayOf(EntityPropTypes.bankCustomerPropType),
    PropTypes.arrayOf(EntityPropTypes.counterpartyPropType),
  ]),
  onChange: PropTypes.func,
  noDependent: PropTypes.bool,
  selectedIndependentEntity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default EntitySelector
