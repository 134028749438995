import { createActions } from 'redux-actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const { import: importActions } = createActions({
  IMPORT: {
    PRODUCTPRICES: undefined,
    TRADES: undefined,
  },
})

importActions.productPricesCsv = file => ({ axios }) => dispatch => {
  const reader = new FileReader()
  reader.readAsText(file)
  reader.onload = async () => {
    try {
      dispatch(showLoading())
      await axios.post('/api/product-prices', {
        priceData: reader.result,
      })
      dispatch(importActions.productprices({ success: true }))
      dispatch(hideLoading())
    } catch (e) {
      dispatch(hideLoading())
      if (e.response && e.response.data) {
        dispatch(importActions.productprices(e.response.data))
      } else {
        dispatch(importActions.productprices({ error: 'Unknown Error!' }))
      }
    }
  }
}

importActions.tradesCsv = file => ({ axios }) => dispatch => {
  const reader = new FileReader()
  reader.readAsText(file)
  reader.onload = async () => {
    try {
      dispatch(showLoading())
      await axios.post('/api/trade/import', {
        priceData: reader.result,
      })
      dispatch(importActions.trades({ success: true }))
      dispatch(hideLoading())
    } catch (e) {
      dispatch(hideLoading())
      if (e.response && e.response.data) {
        dispatch(importActions.trades(e.response.data))
      } else {
        dispatch(importActions.trades({ error: 'Unknown Error!' }))
      }
    }
  }
}

importActions.resetProductPrices = () => () => dispatch => {
  dispatch(importActions.productprices({}))
}

importActions.resetTrades = () => () => dispatch => {
  dispatch(importActions.trades({}))
}

export default importActions
