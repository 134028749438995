import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
  return {
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: 256,
    },
  }
})

const Input = ({
  name,
  type,
  title,
  value,
  defaultValue,
  errorText,
  ...props
}) => {
  const classes = useStyles()
  return (
    <TextField
      className={classes.textField}
      error={Boolean(errorText)}
      name={name}
      type={type}
      key={value}
      helperText={errorText}
      value={defaultValue}
      label={title}
      // floatingLabelText={title}
      // floatingLabelStyle={inputLabelStyles}
      // floatingLabelFocusStyle={inputFocusLabelStyles}
      // underlineFocusStyle={inputUnderlineStyles}
      //  className="input"
      {...props}
      margin="normal"
    />
  )
}

Input.defaultProps = {
  name: undefined,
  value: undefined,
  type: '',
}

Input.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
}

export default Input
