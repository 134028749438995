import { handleActions } from 'redux-actions'

const defaultState = { MTMSummary: {} }

const reducerMap = {
  'MTMSUMMARY/SET': (state, { payload }) => {
    return {
      ...state,
      MTMSummary: payload,
    }
  },
  'MARKTOMARKET/SET': (state, { payload }) => {
    return {
      ...state,
      MarkToMarket: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
