import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton, RadioButtonGroup, RadioButton } from 'material-ui'
import { MenuItem } from '@material-ui/core'
import { DateTimePicker, DatePicker } from 'material-ui-pickers'
import moment from 'moment'
import _ from 'lodash'
import { addButtonStyle, addButtonLabelStyle } from '../../styles'
import { build, actions } from '../container_helpers'
import { validateTradeEntryForm } from '../../helpers/formsValidators'
import Input from '../UtilsComponents/Input/Input'
import './TradeEntryForm.styl'
import {
  bankPropType,
  privateEquityPropType,
  counterpartyPropType,
  clearingAgentPropType,
  bankCustomerPropType,
  userPropType,
  brokerPropType,
  tradeBlotterPropType,
  tradeEntryPropType,
  productPropType,
} from '../shared_prop_types'
import Dropdown from '../Dropdown/Dropdown'
import TradeAction from './TradeAction'
import ProductEntry from './ProductEntry'

import { createTradeSummaries } from '../../helpers/tradeEntry'
import { mergeEntities } from '../../helpers/tradeBlotterUtils'

const traderNames = ['Scott Lape', 'Miles Dunning']
class TradeEntryForm extends Component {
  state = {
    errors: {},
  }

  onBrokerChange = event => {
    const value = event.target.value
    const broker = this.props.brokers.find(b => b.id === value)
    if (broker) {
      this.props.updateTradeEntry({
        brokerId: value,
        brokerRate: broker.rate,
      })
    }
  }

  onRadioChange = (field, event, value) => {
    this.props.updateTradeEntry({
      [field]: value,
    })
  }

  onSelectChange = (field, event, index, typeValue) => {
    this.props.updateTradeEntry({
      [field]: typeValue,
    })
  }

  onFieldChange = (field, value) => {
    this.props.updateTradeEntry({
      [field]: value,
    })
  }

  onDateTimeChange = (dateOrTime, field) => {
    this.props.updateTradeEntry({
      [field]: dateOrTime.toDate(),
    })

    this.setState({
      [field]: dateOrTime.toDate(),
    })
  }

  cancel = () => {
    this.props.resetTradeEntry()
    this.props.onCancel()
  }

  validateForm = () => {
    const tradeEntry = _.cloneDeep(this.props.tradeEntry)
    const validationResult = validateTradeEntryForm(
      tradeEntry,
      this.state.errors
    )
    this.setState({ errors: validationResult.errors })

    const tradeSummaries = createTradeSummaries(
      this.props.tradeEntry,
      this.props.products,
      mergeEntities(
        this.props.banks,
        this.props.bankCustomers,
        this.props.privateEquities,
        this.props.counterparties
      )
    )
    this.setState({ tradeSummaries })

    this.props.updateTradeEntry({
      valid: validationResult.valid,
    })
    if (validationResult.valid) {
      this.props.onSuccess()
    }
  }

  tradeActionsComplete = () =>
    _.every(
      this.props.tradeEntry.tradeActions,
      x => x && x.firstEntityId && x.secondEntityId && x.tradePrice
    )

  renderBanksAndPrivateEquitiesList = () => {
    const banks = this.props.banks.map(bank => (
      <MenuItem
        key={bank.id}
        value={`Banks:${bank.id}`}
        primaryText={bank.displayName}
      />
    ))
    const pes = this.props.privateEquities.map(pe => (
      <MenuItem key={pe.id} value={`PrivateEquities:${pe.id}`}>
        {pe.displayName}
      </MenuItem>
    ))
    return banks.concat(pes)
  }

  renderBrokerItems = () =>
    this.props.brokers.map(broker => (
      <MenuItem key={broker.id} value={broker.id}>
        {broker.displayName}
      </MenuItem>
    ))

  renderTradeActions = () =>
    this.props.tradeEntry.tradeActions.map(item => (
      <TradeAction
        key={item.id}
        banks={this.props.banks}
        privateEquities={this.props.privateEquities}
        bankCustomers={this.props.bankCustomers}
        counterparties={this.props.counterparties}
        users={this.props.users}
        tradeAction={item}
      />
    ))

  renderTraderNames = () =>
    traderNames.map(item => (
      <RadioButton key={item} value={item} label={item} />
    ))

  render() {
    return (
      <div className="trade-blotter-form">
        <h2>Add New Group</h2>
        <div className="row">
          <div className="col-lg-6">
            <DateTimePicker
              name="tradeDateTime"
              label="Trade Date & Time"
              value={this.props.tradeEntry.tradeDateTime}
              showTodayButton
              onChange={date => this.onDateTimeChange(date, 'tradeDateTime')}
            />
          </div>
        </div>
        {this.props.tradeEntry.tradeDateTime && (
          <div>
            {this.renderTradeActions()}
            {this.tradeActionsComplete() && (
              <div>
                <div className="row">
                  <div className="col-xs-4">
                    <Dropdown
                      floatingLabelText="If a Broker was used, select here"
                      value={this.props.tradeEntry.brokerId}
                      onChange={this.onBrokerChange}
                      errorText={this.state.errors.brokerId}
                    >
                      {this.renderBrokerItems()}
                    </Dropdown>
                  </div>
                  {this.props.tradeEntry.brokerId && (
                    <div className="col-xs-2">
                      <Input
                        type="number"
                        name="brokerRate"
                        title="Broker Rate"
                        defaultValue={this.props.tradeEntry.brokerRate}
                        value={this.props.tradeEntry.brokerRate}
                        onBlur={e => {
                          this.onFieldChange('brokerRate', e.target.value)
                        }}
                        errorText={this.state.errors.brokerRate}
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div>Select Trader:</div>
                  <RadioButtonGroup
                    style={{ display: 'inline-flex' }}
                    name="traderName"
                    valueSelected={this.props.tradeEntry.traderName}
                    onChange={this.onRadioChange.bind(null, 'traderName')}
                  >
                    {this.renderTraderNames()}
                  </RadioButtonGroup>
                </div>
                <ProductEntry
                  productEntry={this.props.tradeEntry.productEntry}
                />
                <div className="row">
                  <div className="col-xs-4">
                    <DatePicker
                      name="startDate"
                      views={['year', 'month']}
                      label="Start Contract Month"
                      minDate={moment()
                        .subtract(1, 'year')
                        .toDate()}
                      value={this.props.tradeEntry.startDate}
                      onChange={date =>
                        this.onDateTimeChange(date, 'startDate')}
                    />
                  </div>
                  <div className="col-xs-4">
                    <DatePicker
                      name="endDate"
                      views={['year', 'month']}
                      label="End Contract Month"
                      minDate={this.state.startDate}
                      maxDate={moment()
                        .add(10, 'year')
                        .toDate()}
                      value={this.props.tradeEntry.endDate}
                      onChange={date => this.onDateTimeChange(date, 'endDate')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <Input
                      type="string"
                      name="usiNumber"
                      title="USI Number"
                      defaultValue={this.props.tradeEntry.usiNumber}
                      value={this.props.tradeEntry.usiNumber}
                      onBlur={e => {
                        this.onFieldChange('usiNumber', e.target.value)
                      }}
                      errorText={this.state.errors.usiNumber}
                    />
                  </div>
                  <div className="col-xs-6">
                    <Input
                      type="string"
                      name="cmeTradeId"
                      title="CME Trade ID"
                      defaultValue={this.props.tradeEntry.cmeTradeId}
                      value={this.props.tradeEntry.cmeTradeId}
                      onBlur={e => {
                        this.onFieldChange('cmeTradeId', e.target.value)
                      }}
                      errorText={this.state.errors.cmeTradeId}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            position: 'absolute',
            bottom: 0,
            marginBottom: '0.5em',
          }}
        >
          <RaisedButton
            primary
            label="Cancel"
            onClick={() => {
              this.cancel()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
          <RaisedButton
            primary
            label="Review Summary"
            onClick={() => {
              this.validateForm()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
        </div>
      </div>
    )
  }
}

TradeEntryForm.propTypes = {
  banks: PropTypes.arrayOf(bankPropType),
  brokers: PropTypes.arrayOf(brokerPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  clearingAgents: PropTypes.arrayOf(clearingAgentPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  users: PropTypes.arrayOf(userPropType),
  trades: PropTypes.arrayOf(tradeBlotterPropType),
  products: PropTypes.arrayOf(productPropType),
  tradeEntry: tradeEntryPropType.isRequired,
  updateTradeEntry: PropTypes.func,
  resetTradeEntry: PropTypes.func,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
}

const mapDispatchToProps = {
  updateTradeEntry: actions.tradeEntry.set,
  resetTradeEntry: actions.tradeEntry.reset,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {
  return {
    tradeEntry: state.tradeEntry,
    products: state.product.products,
  }
}

export default build({
  component: TradeEntryForm,
  mapDispatchToProps,
  mapStateToProps,
})
