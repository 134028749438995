import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { userOptions } from '../../constants'
import './UserOption.styl'

class UserOption extends Component {
  constructor() {
    super()
    this.state = { active: false }
  }

  logout = () => {
    this.props.deleteSession()
    this.props.history.push({ pathname: '/login' })
  }

  changePassword = () => {
    this.props.history.push({ pathname: '/changepassword' })
  }

  toggleOptions = () => {
    this.setState({ active: !this.state.active })
  }

  generateOptions(active) {
    return (
      active && (
        <ul className="options">
          {userOptions.map(option => (
            <li key={option.action} className="option-name">
              <a onClick={this[option.action]}>{option.text}</a>
            </li>
          ))}
        </ul>
      )
    )
  }

  render() {
    return (
      <div className="logout" onClick={this.toggleOptions}>
        <div className="dropdown">
          <button className="arrow">
            <span className="toggle" />
          </button>
          {this.state.active && this.generateOptions(this.state.active)}
        </div>
      </div>
    )
  }
}

UserOption.propTypes = {
  deleteSession: PropTypes.func.isRequired,
  active: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

export default withRouter(UserOption)
