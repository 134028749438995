import _ from 'lodash'

const reports = {
  'Mark to Market Summary': {
    entityTypes: ['Bank'],
    hasStartDate: false,
    hasEndDate: false,
    hasSettleDate: true,
    reportFileName: 'MTM Summary',
    baseUrl: 'mark-to-market-summary',
    scale: 1.0,
    pageLayout: 'Landscape',
  },
  'Mark to Market': {
    entityTypes: ['Bank', 'Bank Customer', 'Counterparty'],
    hasStartDate: false,
    hasEndDate: false,
    hasSettleDate: true,
    reportFileName: 'Mark to Market',
    baseUrl: 'mark-to-market-report',
    scale: 0.9,
    pageLayout: 'Landscape',
  },
  'Mark to Market Detailed': {
    entityTypes: ['Bank', 'Bank Customer', 'Counterparty'],
    hasStartDate: false,
    hasEndDate: false,
    hasSettleDate: true,
    reportFileName: 'MTM Detailed',
    baseUrl: 'mark-to-market-detail',
    scale: 0.9,
    pageLayout: 'Landscape',
  },
  'Settlement Summary': {
    entityTypes: ['Bank', 'Bank Customer', 'Counterparty'],
    hasStartDate: false,
    hasEndDate: false,
    hasSettleDate: true,
    hasSettleDateEnd: true,
    reportFileName: 'Settlement Summary',
    baseUrl: 'settlement-summary',
    scale: 0.9,
    pageLayout: 'Landscape',
  },
  'Option Premium Invoice': {
    entityTypes: ['Bank', 'Bank Customer', 'Counterparty'],
    hasStartDate: true,
    hasEndDate: true,
    hasSettleDate: false,
    reportFileName: 'Option Premium Invoice',
    baseUrl: 'option-premium',
    scale: 0.9,
    pageLayout: 'Landscape',
  },
  'Trade Confirmation': {
    entityTypes: ['Bank', 'Bank Customer'],
    hasStartDate: true,
    hasEndDate: true,
    hasSettleDate: false,
    reportFileName: 'Trade Confirmation',
    baseUrl: 'trade-confirmation',
    scale: 1.0,
    pageLayout: 'Portrait',
    margins: {
      top: '0.75in',
      bottom: '0.75in',
      left: '0.7in',
      right: '0.7in',
    },
  },
  'Transaction Summary': {
    entityTypes: ['Bank'],
    hasStartDate: true,
    hasEndDate: true,
    hasSettleDate: false,
    reportFileName: 'Transaction Summary',
    baseUrl: 'transaction-summary',
    scale: 0.9,
    pageLayout: 'Landscape',
  },
  'Threshold Summary': {
    entityTypes: ['Bank', 'Bank Customer'],
    hasStartDate: false,
    hasEndDate: false,
    hasSettleDate: true,
    reportFileName: 'Threshold Summary',
    baseUrl: 'threshold-summary-report',
    scale: 1.0,
    pageLayout: 'Landscape',
    margins: {
      top: '0.75in',
      bottom: '0.75in',
      left: '0.7in',
      right: '0.7in',
    },
  },
}

const getReportKeyByUrl = url => _.findKey(reports, x => x.baseUrl === url)

export { reports, getReportKeyByUrl }
