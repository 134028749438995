import { handleActions } from 'redux-actions'

const defaultState = { success: {}, error: {} }

const reducerMap = {
  'NOTIFICATION/SUCCESS': (state, { payload }) => {
    return {
      ...state,
      success: payload,
    }
  },
  'NOTIFICATION/ERROR': (state, { payload }) => {
    return {
      ...state,
      error: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
