import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { DatePicker } from 'material-ui-pickers'
import { build } from '../../container_helpers'
import * as EntityPropType from '../../shared_prop_types'
import EditTable from '../../EditTable/EditTable'

const HedgeRequirements = props => {
  const columns = [
    {
      field: 'type',
      title: 'Type',
      lookup: props.hedgeRequirementTypes.reduce((accum, val) => {
        accum[val.id] = val.displayName
        return accum
      }, {}),
    },
    { field: 'min', title: 'Min %', type: 'numeric' },
    { field: 'max', title: 'Max %', type: 'numeric' },
    { field: 'minTenor', title: 'Min Tenor', type: 'string' },
    { field: 'maxTenor', title: 'Max Tenor', type: 'string' },
  ]

  return (
    <EditTable
      title="Hedge Requirements"
      columns={columns}
      values={props.hedgeRequirements}
      onChange={props.onChange}
      editable={props.editable}
      options={{
        padding: 'dense',
        search: false,
        paging: false,
        // paging: addresses && addresses.length && addresses.length > 5,
      }}
    />
  )
}

HedgeRequirements.propTypes = {
  hedgeRequirements: PropTypes.arrayOf(EntityPropType.hedgeRequirementType),
  hedgeRequirementTypes: PropTypes.arrayOf(EntityPropType.enumPropType),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

HedgeRequirements.defaultProps = {
  editable: true,
}

const mapStateToProps = state => {
  return {
    hedgeRequirementTypes: state.enum.hedgeRequirementTypes,
  }
}

export default build({
  component: HedgeRequirements,
  mapStateToProps,
})
