import React from 'react'
import PropTypes from 'prop-types'
import { RaisedButton } from 'material-ui'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import './../SidebarForm.styl'
import { addButtonStyle, addButtonLabelStyle } from '../../../styles'
import { brokerPropType } from '../../shared_prop_types'
import { NUMBER_REGEX, FLOATING_REGEX_KEY_UP } from '../../../constants'
import { validateBrokerForm } from '../../../helpers/formsValidators'
import Addresses from '../Addresses/Addresses'
import PhoneNumbers from '../PhoneNumbers/PhoneNumbers'
import Emails from '../Emails/Emails'
import EntityFormWrapper from '../Entity/EntityFormWrapper'
import { useEntityForm } from '../Entity/entityHooks'

const onlyNumbers = event => {
  const pattern = new RegExp(NUMBER_REGEX)
  if (!pattern.test(event.key)) {
    event.preventDefault()
  }
}

const onlyFloating = event => {
  const floatingPattern = new RegExp(FLOATING_REGEX_KEY_UP)
  if (!floatingPattern.test(event.key)) {
    event.preventDefault()
  }
}

const BrokerForm = props => {
  const defaults = {
    name: '',
    displayName: '',
    rate: '',
    addresses: [],
    phoneNumbers: [],
    emails: [],
    permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
    bankId: props.bankId || '',
  }

  const {
    selected,
    getBroker: getEntity,
    updateBroker: updateEntity,
    createBroker: createEntity,
  } = props

  const [newEntity, setEntity, errors, setErrors, submit] = useEntityForm({
    selected,
    defaults,
    getEntity,
    updateEntity,
    createEntity,
  })

  const validateForm = () => {
    const validationResult = validateBrokerForm(newEntity, errors)
    setErrors(validationResult.errors)

    if (validationResult.valid) {
      submit()
    }
  }

  const renderDetails = () => (
    <div className="create">
      <div className="sidebar-title">
        {!_.isEmpty(props.selected) ? 'Edit Broker' : 'Add Broker'}
      </div>
      <div className="create-form">
        <div className="entity-details">
          <div className="create-title">Broker Details</div>
          <div className="row">
            <Input
              name="name"
              title="Broker Name"
              defaultValue={newEntity.name}
              className="col-xs-3"
              onChange={e => {
                setEntity({ name: e.target.value })
              }}
              errorText={errors.name}
            />

            <Input
              name="displayName"
              title="Display Name"
              defaultValue={newEntity.displayName}
              className="col-xs-3"
              onChange={e => {
                setEntity({ displayName: e.target.value })
              }}
              errorText={errors.displayName}
            />

            <Input
              name="rate"
              title="Rate"
              onKeyPress={onlyFloating}
              errorText={errors.rate}
              defaultValue={newEntity.rate}
              className="col-xs-3"
              onChange={e => {
                setEntity({ rate: e.target.value })
              }}
            />
          </div>

          <br />
          <PhoneNumbers
            phoneNumbers={newEntity.phoneNumbers}
            onChange={phoneNumbers => {
              setEntity({ phoneNumbers })
            }}
          />
          <br />
          <Emails
            emails={newEntity.emails}
            onChange={emails => {
              setEntity({ emails })
            }}
          />
          <br />
          <Addresses
            addresses={newEntity.addresses}
            onChange={addresses => {
              setEntity({ addresses })
            }}
          />
          <br />
        </div>
        <div className="add-button-bg">
          <RaisedButton
            primary
            label={!_.isEmpty(props.selected) ? 'Save' : 'Add'}
            onClick={() => {
              validateForm()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
        </div>
      </div>
    </div>
  )

  return (
    <EntityFormWrapper
      newEntity={newEntity}
      setEntity={setEntity}
      render={renderDetails}
    />
  )
}

BrokerForm.propTypes = {
  createBroker: PropTypes.func.isRequired,
  updateBroker: PropTypes.func.isRequired,
  getBroker: PropTypes.func.isRequired,
  selected: brokerPropType,
  bankId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const mapDispatchToProps = {
  createBroker: actions.broker.create,
  getBroker: actions.broker.get,
  updateBroker: actions.broker.update,
}

export default build({
  component: BrokerForm,
  mapDispatchToProps,
})
