/* eslint filenames/match-exported: 0 */
import _ from 'lodash'
import moment from 'moment'
import { formatCurrency } from '../../helpers/formatCurrency'

const formatDate = value => moment(value).format('MM-DD-YYYY')
const formatDateTime = value => moment(value).format('MM-DD-YY hh:mm A')

const blotterColumns = [
  [
    'tradeDateTime',
    { formatters: [value => formatDateTime(value)], label: 'Trade Date/Time' },
  ],
  ['gid', { label: 'GID', width: 40 }],
  ['tid', { label: 'TID', width: 40 }],
  ['referenceId', { width: 140, label: 'Reference ID' }],
  ['usiNumber', { width: 140, label: 'USI Value' }],
  ['traderName'],
  ['buyerName'],
  ['buyerUserName', { label: 'Buyer User' }],
  ['sellerName'],
  ['sellerUserName', { label: 'Seller User' }],
  ['brokerName'],
  ['brokerUserName', { label: 'Broker User' }],
  ['brokerRate', { formatters: [value => formatCurrency(value)] }],
  ['monthlyVolume'],
  ['productType'],
  ['product'],
  ['indexName', { label: 'index', width: 60 }],
  ['strikePrice', { formatters: [value => formatCurrency(value)] }],
  ['instrumentType'],
  [
    'tradePremiumPrice',
    {
      label: 'Trade Price',
      formatters: [value => formatCurrency(value)],
    },
  ],
  [
    'startDate',
    {
      label: 'Contract Month',
      formatters: [value => moment(value).format('MMM-YY')],
    },
  ],
  ['settlementType', { formatters: [value => value.replace('_', ' ')] }],
  [
    'expirationDate',
    { label: 'Expiration Date', formatters: [value => formatDate(value)] },
  ],
  ['paymentDays', { label: 'Payment Days' }],
  ['paymentDate', { formatters: [value => formatDate(value)] }],
  [
    'tradeMarkToMarket',
    {
      label: 'MTM',
      width: 70,
      nonSortable: true,
      formatters: [value => formatCurrency(value)],
    },
  ],
  [
    'netOptionPremium',
    { nonSortable: true, formatters: [value => formatCurrency(value)] },
  ],
  ['net', { nonSortable: true, formatters: [value => formatCurrency(value)] }],
  ['productCode', { label: 'pCode', width: 60 }],
  [
    'closePrice',
    {
      nonSortable: true,
      label: 'Closing Price',
      formatters: [value => formatCurrency(value)],
    },
  ],
  [
    'livePrice',
    { nonSortable: true, formatters: [value => formatCurrency(value)] },
  ],
  ['active', { nonSortable: true }],
  ['isExpired', { nonSortable: true }],
  ['isExpirationDateOverriden', { nonSortable: true }],
  ['isPaymentDateOverriden', { nonSortable: true }],
]

blotterColumns.forEach(column => {
  if (!column[1]) {
    column[1] = {}
  }
})

export const getTradeBlotterColumnNames = () => blotterColumns.map(x => x[0])

export default blotterColumns
