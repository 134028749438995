import moment from 'moment'

export const convertArrayOfObjectsToCSV = args => {
  const data = args.data || null
  if (data == null || !data.length) {
    return null
  }
  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'

  const keys = Object.keys(data[0])

  let result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    let ctr = 0
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter

      result += `"${item[key]}"`
      ctr += 1
    })
    result += lineDelimiter
  })
  return result
}

export const downloadCSV = (data, outputFilename) => {
  let csv = convertArrayOfObjectsToCSV({
    data,
  })
  if (csv == null) return

  const filename =
    outputFilename || `Trade Blotter - ${moment().format('MM-DD-YYYY')}.csv`

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
  const result = encodeURI(csv)

  const link = document.createElement('a')
  link.setAttribute('href', result)
  link.setAttribute('download', filename)
  link.click()
}

export const exportTableToCSV = (filename, tableSelector) => {
  const csv = []
  const selector = tableSelector || 'table'
  const rows = document.querySelectorAll(`${selector} tr`)

  for (let i = 0; i < rows.length; i++) {
    const row = []
    const cols = rows[i].querySelectorAll('td, th')

    for (let j = 0; j < cols.length; j++) {
      row.push(`"${cols[j].innerText}"`)
      const colSpan = cols[j].getAttribute('colSpan')
      if (colSpan) {
        for (let k = 0; k < colSpan - 1; k++) {
          row.push('')
        }
      }
    }

    csv.push(row.join(','))
  }
  let data = csv.join('\n')

  if (!data.match(/^data:text\/csv/i)) {
    data = `data:text/csv;charset=utf-8,${data}`
  }
  const result = encodeURI(data)

  const link = document.createElement('a')
  link.setAttribute('href', result)
  link.setAttribute('download', filename)
  link.click()
}
