import { createActions } from 'redux-actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { parseError } from '../../helpers/errorUtils'
import {
  tryCatchAction,
  updateAccountsState,
} from '../storeHelpers/reduxActionHelpers'

const { user: userActions } = createActions({
  USER: {
    SET: undefined,
    ADD: undefined,
    PUT: undefined,
    LOGGED: undefined,
    ERROR: undefined,
    REMOVE: undefined,
  },
})

userActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('api/user/list')
  dispatch(userActions.set(response.data))
}

userActions.get = ({ id }) => ({ axios }) => async dispatch => {
  const response = await axios.get(`api/user/${id}`)

  dispatch(userActions.logged(response.data))
  updateAccountsState(dispatch, response.data)
}

userActions.create = data => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      const response = await axios.post('api/user', data)
      dispatch(userActions.add(response.data))
    },
    dispatch,
    'User Created Successfully.'
  )

userActions.update = data => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      const response = await axios.put(`api/user/${data.id}`, data)
      dispatch(userActions.put(response.data))
      updateAccountsState(dispatch, response.data)
    },
    dispatch,
    'User Updated Successfully.'
  )

userActions.delete = id => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      const response = await axios.delete(`api/user/${id}`)
      dispatch(userActions.remove(response.data))
    },
    dispatch,
    'User Deleted Successfully.'
  )

userActions.resetTwoFactor = data => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      await axios.put(`api/user/${data}/twofactor/reset`)
    },
    dispatch,
    'Two Factor Reset.',
    null,
    error => dispatch(userActions.error(error))
  )

userActions.sendWelcomeEmail = data => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      await axios.put(`api/user/${data}/welcome`)
    },
    dispatch,
    'Welcome Email Sent.',
    null,
    error => dispatch(userActions.error(error))
  )

userActions.resetPassword = email => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      await axios.put(`api/user/${email}/password/reset`)
    },
    dispatch,
    'Password Reset.',
    null,
    error => dispatch(userActions.error(error))
  )

userActions.changePassword = (id, data) => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      await axios.put(`api/user/${id}/password/change`, data)
    },
    dispatch,
    'Password Changed.',
    null,
    error => dispatch(userActions.error(error))
  )

userActions.overrideEntityId = (id, entityId) => ({
  axios,
}) => async dispatch => {
  try {
    dispatch(showLoading())
    await axios.put(`api/user/${id}/businessEntity/${entityId}`)
    // omg hax
    if (window.location.pathname.startsWith('/report-generator')) {
      const split = window.location.pathname.split('/')
      if (split.length >= 5) {
        split[4] = entityId
        window.location.pathname = split.join('/')
      } else {
        window.location.pathname = '/report-generator'
      }
    } else {
      window.location.reload()
    }
  } catch (e) {
    const error = parseError(e)
    dispatch(userActions.error(error))
  } finally {
    dispatch(hideLoading())
  }
}

export default userActions
