import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { actions, build } from './../container_helpers'
import './Belt.styl'
import AddButton from './../UtilsComponents/AddButton/AddButton'
import { formatCommodityPrice } from '../../helpers/formatCurrency'

class Belt extends Component {
  componentWillMount() {
    this.props.getCommodityPrices()
  }

  priceChange = (change, digits) => {
    if (change >= 0) {
      return (
        <span className="gain">+{formatCommodityPrice(change, digits)}</span>
      )
    }
    return <span className="loss">{formatCommodityPrice(change, digits)}</span>
  }

  percentChange = change => {
    if (change >= 0) {
      return <span className="gain">(+{change}%)</span>
    }
    return <span className="loss">({change}%)</span>
  }

  render() {
    return (
      <div className="black-belt">
        <div className="ticker-container">
          {this.props.commodityPrices.map(commodityPrice => (
            <div className="ticker" key={commodityPrice.symbol}>
              <div>{commodityPrice.name}</div>
              <div className="prices">
                <div className="price">
                  {formatCommodityPrice(
                    commodityPrice.price,
                    commodityPrice.decimalPlaces
                  )}
                </div>
                {this.priceChange(
                  commodityPrice.netChange,
                  commodityPrice.decimalPlaces
                )}
                {this.percentChange(commodityPrice.percentChange)}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Belt.propTypes = {
  commodityPrices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      symbol: PropTypes.string,
      price: PropTypes.number,
      netChange: PropTypes.number,
      percentChange: PropTypes.number,
    })
  ),
  getCommodityPrices: PropTypes.func,
}

const mapDispatchToProps = {
  getCommodityPrices: actions.commodityPrice.getAll,
}

const mapStateToProps = state => {
  return {
    commodityPrices: state.commodityPrice,
  }
}

export default build({
  component: Belt,
  mapDispatchToProps,
  mapStateToProps,
})
