import { createActions } from 'redux-actions'

const { tradesummary: tradeSummaryActions } = createActions({
  TRADESUMMARY: {
    SET: undefined,
  },
})

tradeSummaryActions.clear = () => () => dispatch => {
  dispatch(tradeSummaryActions.set([]))
}

tradeSummaryActions.getAllTransactionSummariesForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { tradeDate, tradeEndDate, optionsOnly } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/summaries/transaction?tradeDate=${tradeDate ||
      ''}&tradeEndDate=${tradeEndDate || ''}&optionsOnly=${optionsOnly || ''}`
  )
  dispatch(tradeSummaryActions.set(response.data))
}

tradeSummaryActions.getAllThresholdSummariesForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { settleDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/summaries/threshold?settleDate=${settleDate ||
      ''}`
  )
  dispatch(tradeSummaryActions.set(response.data))
}

tradeSummaryActions.getAllConfirmationsForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { tradeDate, tradeEndDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/confirmations?tradeDate=${tradeDate ||
      ''}&tradeEndDate=${tradeEndDate || ''}`
  )
  dispatch(tradeSummaryActions.set(response.data))
}

tradeSummaryActions.getAllSettlementSummariesForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { startDate, endDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/summaries/settlement?startDate=${startDate ||
      ''}&endDate=${endDate || ''}`
  )
  dispatch(tradeSummaryActions.set(response.data))
}

tradeSummaryActions.getAllForEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { tradeDate, tradeEndDate, optionsOnly } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades/summaries?tradeDate=${tradeDate ||
      ''}&tradeEndDate=${tradeEndDate || ''}&optionsOnly=${optionsOnly || ''}`
  )
  dispatch(tradeSummaryActions.set(response.data))
}

export default tradeSummaryActions
