import React from "react"
import PropTypes from "prop-types"
import { DateTimePicker } from "material-ui-pickers"

const datetime = ({ props } = {}) => {
  const DateTime = ({ value, onValue }) => (
    <DateTimePicker
      {...props}
      value={value}
      onChange={onValue}
      showTodayButton
    />
  )
  DateTime.propTypes = {
    value: PropTypes.any,
    onValue: PropTypes.func,
  }

  return DateTime
}

export default datetime
