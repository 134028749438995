import React from 'react'
import PropTypes from 'prop-types'
import './TappianInput.styl'

class TappianInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
    }
    this.onFocusInput = this.onFocusInput.bind(this)
    this.onBlurInput = this.onBlurInput.bind(this)
  }

  get inputClasses() {
    const base = 'tappian-input'
    let styleString = base
    styleString += ' '
    if (this.props.type === 'textarea') {
      styleString += 'tappian-textarea'
      styleString += ' '
    }
    styleString += this.props.rounded ? 'rounded-input' : 'squared-input'
    if (this.isFocusedOrHasValue()) {
      styleString += ' '
      styleString += base
      styleString += '--focused-or-has-value'
    }
    return styleString
  }

  isFocused() {
    return this.state.focused
  }

  hasValue() {
    return this.props.value && this.props.value.length > 0
  }

  isFocusedOrHasValue() {
    return this.isFocused() || this.hasValue()
  }

  onFocusInput() {
    this.setState({ focused: true })
  }

  onBlurInput() {
    this.setState({ focused: false })
  }

  render() {
    return (
      <div>
        <div className={this.inputClasses}>
          <label htmlFor={this.props.name}>{this.props.label}</label>
          {this.props.type === 'textarea' ? (
            <textarea
              name={this.props.name}
              value={this.props.value}
              rows={this.props.rows}
              onFocus={this.onFocusInput}
              onBlur={this.onBlurInput}
              onChange={this.props.onChange}
            >
              {this.state.value}
            </textarea>
          ) : (
            <input
              name={this.props.name}
              value={this.props.value}
              type={this.props.type}
              onFocus={this.onFocusInput}
              onBlur={this.onBlurInput}
              onChange={this.props.onChange}
            />
          )}
        </div>
      </div>
    )
  }
}

TappianInput.defaultProps = {
  name: undefined,
  label: undefined,
  type: 'text',
  rounded: true,
  value: undefined,
  rows: 4,
}

TappianInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  rounded: PropTypes.bool,
  value: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
}

export default TappianInput
