import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import actions from '../../store/actions'
import { validateEmail } from '../../helpers/formsValidators'
import '../../application.styl'
import './Landing.styl'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'
import TappianInput from '../UtilsComponents/TappianInput/TappianInput'

class LandingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
      formErrors: [],
    }
  }

  updateField = (field, event) => {
    const stateChange = { [field]: event.target.value, errors: [] }
    const newState = Object.assign({}, this.state, stateChange)
    this.setState(newState)
  }

  validateFields() {
    const errors = []

    if (!this.state.name) {
      errors.push('You must provide your name.')
    }
    if (!this.state.message) {
      errors.push('You must provide a message.')
    }
    if (!validateEmail(this.state.email)) {
      errors.push('You must provide a valid email address.')
    }

    return errors
  }

  submit = () => {
    const errors = this.validateFields()
    if (errors.length) {
      this.setState({ formErrors: errors })
      return
    }
    const text = `
      Name: ${this.state.name}
      Email: ${this.state.email}
      Phone: ${this.state.phone}
      Company: ${this.state.company}
      Message: ${this.state.message}
    `
    this.props.sendEmail({
      subject: `Contact Form: ${this.state.email}`,
      text,
    })
    this.setState({ emailSent: true })
  }

  render() {
    const showErrors = Boolean(
      this.state.formErrors.length && !this.state.emailSent
    )

    return (
      <div className="">
        {showErrors && (
          <div className="row">
            {this.state.formErrors.map(error => (
              <div className="error-message" key={error}>
                {error}
              </div>
            ))}
          </div>
        )}
        {!this.props.emailSuccess && (
          <div>
            <div className="row">
              <div className="col-sm-6">
                <TappianInput
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={e => {
                    this.updateField('name', e)
                  }}
                  label="Name"
                  rounded={false}
                />
                <TappianInput
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={e => {
                    this.updateField('email', e)
                  }}
                  label="Email"
                  rounded={false}
                />
              </div>
              <div className="col-sm-6">
                <TappianInput
                  name="phone"
                  type="tel"
                  value={this.state.phone}
                  onChange={e => {
                    this.updateField('phone', e)
                  }}
                  label="Phone"
                  rounded={false}
                />
                <TappianInput
                  name="company"
                  type="text"
                  value={this.state.company}
                  onChange={e => {
                    this.updateField('company', e)
                  }}
                  label="Company"
                  rounded={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <TappianInput
                  name="message"
                  type="textarea"
                  rows={4}
                  value={this.state.message}
                  onChange={e => {
                    this.updateField('message', e)
                  }}
                  label="Message"
                  rounded={false}
                />
              </div>
            </div>
          </div>
        )}
        {!this.state.emailSent && (
          <RoundedButton buttonText="Submit" onClick={this.submit} />
        )}
        {this.state.emailSent && <p>Submitted. Thank you!</p>}
        {this.props.emailSuccess && (
          <div className="success-message">
            Thank you for your interest. A member of the Tappian team will
            contact you shortly.
          </div>
        )}
      </div>
    )
  }
}

LandingForm.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  emailSuccess: PropTypes.bool,
}

const mapDispatchToProps = {
  sendEmail: actions.email.send,
}

const mapStateToProps = state => {
  return {
    emailSuccess: state.email.success,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingForm)
