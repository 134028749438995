import { createActions } from 'redux-actions'
import { entityActions } from '../storeHelpers/businessEntityHelpers'

const type = 'IndependentEntity'
const { independententity: independentEntityActions } = createActions({
  INDEPENDENTENTITY: {
    SET: undefined,
    PUT: undefined,
    REMOVE: undefined,
  },
})

independentEntityActions.getAll = (checkActive = true) => ({
  axios,
}) => async dispatch =>
  entityActions.getAll(
    axios,
    dispatch,
    independentEntityActions.set,
    type,
    checkActive
  )

independentEntityActions.get = data => ({ axios }) => async dispatch =>
  entityActions.get(data, axios, dispatch, independentEntityActions.put, type)

independentEntityActions.delete = id => ({ axios }) => async dispatch =>
  entityActions.delete(
    id,
    axios,
    dispatch,
    independentEntityActions.remove,
    type
  )

export default independentEntityActions
