import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Modal from 'react-modal'
import { RaisedButton, Checkbox } from 'material-ui'
import { cloneDeep, filter, findIndex } from 'lodash'
import classnames from 'classnames'
import { tradeBlotterPropType } from '../shared_prop_types'
import styles from './TradeModal.styl'

export default class TradeModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmDeletion: false,
      selectedGroup: [],
      deleteChecked: false,
    }
  }

  componentWillReceiveProps({ selectedRow, trades }) {
    if (selectedRow && trades && !this.state.selectedGroup.length) {
      const selectedGroup = filter(trades, {
        tradeGroupId: selectedRow.tradeGroupId,
      })
      this.setState({ selectedGroup })
    }
  }

  deleteTrade = () => {
    this.setState({ confirmDeletion: true })
  }

  cancelDeletion = () => {
    this.setState({ confirmDeletion: false })
  }

  confirmDeleteTrade = () => {
    this.setState({ confirmDeletion: false, selectedGroup: [] })
    this.props.confirmDeleteTrade(this.state.selectedGroup)
  }

  confirmDeleteCheckbox = id => {
    this.setState({ deleteChecked: !this.state.deleteChecked })
  }

  onRequestClose = () => {
    this.setState({ confirmDeletion: false, selectedGroup: [] })
    this.props.onRequestClose()
  }

  render() {
    const modalTitle = this.props.selectedRow
      ? `Group ID: ${this.props.selectedRow.tradeGroupId}`
      : ''
    const modalSubtitle = this.props.selectedRow
      ? `${this.state.selectedGroup.length} trades in this group`
      : ''
    const tradeGroupId =  this.props.selectedRow ? this.props.selectedRow.tradeGroupId : -1
    const tradeIdsText = this.state.selectedGroup.map(trade => trade.id).join(',')
    return (
      <Modal
        isOpen={!!this.props.selectedRow}
        onRequestClose={this.onRequestClose}
        className={classnames(styles.modal, {
          'show-deletion': this.state.confirmDeletion,
        })}
        ariaHideApp={false}
        overlayClassName={styles['modal-overlay']}
      >
        {this.props.showDeletedTrades ? (
          <div>
            <h4>Restore Trades For {modalTitle}</h4>
            <RaisedButton label="RESTORE TRADES" />
          </div>
        ) : (
          <div>
            <div className="base-options">
              <h4>{modalTitle}</h4>
              <h5>{modalSubtitle}</h5>
              <div className="button-row">
                <RaisedButton
                  className="button"
                  label="EDIT"
                  onClick={this.props.editTrade}
                />
                <RaisedButton
                  className="button"
                  label="DELETE"
                  onClick={this.deleteTrade}
                />
              </div>
            </div>
            <div className="confirm-deletion">
              <h4>Confirm deletion of {modalTitle}</h4>
              <h5>The {modalSubtitle} will be deleted</h5>    
                <div className="delete-option">
                  <Checkbox
                    label={`Confirm Deletion of Trade Group: ${tradeGroupId}`}
                    checked={this.state.deleteChecked}
                    onCheck={() => {
                      this.confirmDeleteCheckbox()
                    }}
                  />
                </div>
              <div className="button-row">
                <RaisedButton
                  className="button"
                  label="CANCEL"
                  onClick={this.cancelDeletion}
                />
                <RaisedButton
                  className="button"
                  secondary
                  label="CONFIRM DELETION"
                  onClick={this.confirmDeleteTrade}
                  disabled={!this.state.deleteChecked}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

TradeModal.propTypes = {
  confirmDeleteTrade: PropTypes.func,
  selectedRow: tradeBlotterPropType,
  onRequestClose: PropTypes.func,
  showDeletedTrades: PropTypes.bool,
  trades: PropTypes.arrayOf(tradeBlotterPropType),
  editTrade: PropTypes.func,
}
