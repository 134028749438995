import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton, MenuItem } from 'material-ui'
import { Button } from '@material-ui/core'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import _ from 'lodash'

import { build, actions } from '../../container_helpers'
import '../SidebarForm.styl'

import { NUMBER_REGEX, entitiesRaw } from '../../../constants'
import { validateForm } from '../../../helpers/formsValidators'
import { userPropType } from '../../shared_prop_types'

import PermissionsTab from '../Permissions/PermissionsTab'

const EntityFormWrapper = props => (
  <div>
    <Tabs>
      <TabList>
        <Tab>Details</Tab>
        <Tab>Permissions</Tab>
      </TabList>

      <TabPanel>{props.render()}</TabPanel>
      <TabPanel>
        <PermissionsTab
          permissions={props.newEntity.permissions}
          onChanged={permissions =>
            props.setEntity({ ...props.newEntity, permissions })}
        />
      </TabPanel>
    </Tabs>
    <div>{props.renderSubmit && props.renderSubmit()}</div>
  </div>
)

EntityFormWrapper.propTypes = {
  newEntity: PropTypes.object,
  setEntity: PropTypes.func,
  render: PropTypes.func,
  renderSubmit: PropTypes.func,
}

export default EntityFormWrapper
