exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._10K3n00U7fekwUujyuBxTw .header .title {\n  font-weight: bold;\n  color: #000;\n}\n._10K3n00U7fekwUujyuBxTw .container {\n  padding-top: 1em;\n  padding-bottom: 1em;\n  height: auto;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n._10K3n00U7fekwUujyuBxTw .item {\n  width: 20%;\n}\n._10K3n00U7fekwUujyuBxTw .label {\n  font-weight: normal !important;\n  color: #000;\n  font-size: 13px;\n  line-height: 15px;\n}\n._10K3n00U7fekwUujyuBxTw .footer {\n  border-top: 1px solid #d3d3d3;\n  height: 10%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n._10K3n00U7fekwUujyuBxTw .cancel-btn {\n  margin: 2em 0;\n  padding: 0.75em 2em;\n  font-size: 0.8rem;\n  line-height: 1;\n  background-color: transparent;\n  border: none;\n  color: #8a8d8f;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n}\n._10K3n00U7fekwUujyuBxTw .accept-btn {\n  font-size: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"columnVisibility": "_10K3n00U7fekwUujyuBxTw"
};