import { handleActions } from 'redux-actions'

const defaultState = {
  first: {},
  second: {},
  recoveryCodes: [],
  user: {},
  error: '',
}

const reducerMap = {
  'LOGIN/SET/FIRST': (state, { payload }) => {
    return {
      ...state,
      first: payload,
      error: '',
    }
  },
  'LOGIN/SET/SECOND': (state, { payload }) => {
    return {
      ...state,
      second: payload,
      user: payload,
      error: '',
    }
  },
  'LOGIN/SET/RECOVERYCODES': (state, { payload }) => {
    return {
      ...state,
      recoveryCodes: payload,
    }
  },
  'LOGIN/SET/USER': (state, { payload }) => {
    return {
      ...state,
      user: payload,
    }
  },
  'LOGIN/ERROR': (state, { payload }) => {
    return {
      ...state,
      error: payload,
    }
  },
  'LOGIN/RESET': () => defaultState,
}

export default handleActions(reducerMap, defaultState)
