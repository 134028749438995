import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { build, actions } from '../container_helpers'
import './LinkMenu.styl'

const LinkMenu = props => (
  <li>
    <NavLink to={`/${props.to}`} onClick={props.closeSidebar}>
      {props.itemName}
    </NavLink>
  </li>
)

LinkMenu.propTypes = {
  to: PropTypes.string,
  itemName: PropTypes.string.isRequired,
  closeSidebar: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  closeSidebar: actions.sidebar.close,
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
  }
}

export default build({
  component: LinkMenu,
  mapDispatchToProps,
  mapStateToProps,
})
