import { handleActions } from 'redux-actions'

const defaultState = {
  success: false,
  error: '',
}

const reducerMap = {
  'PASSWORD/SUCCESS': state => {
    return {
      ...state,
      success: true,
      error: '',
    }
  },
  'PASSWORD/ERROR': (state, { payload }) => {
    return {
      ...state,
      success: false,
      error: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
