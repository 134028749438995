import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { MenuItem } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { actions, build } from '../container_helpers'
import {
  bankPropType,
  bankCustomerPropType,
  counterpartyPropType,
} from '../shared_prop_types'
import styles from './ReportGenerator.styl'
import Dropdown from '../Dropdown/Dropdown'
import PickHistoricalDate from '../PickHistoricalDate/PickHistoricalDate'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'

import DailyTransactionSummary from '../Reports/DailyTransactionSummary/DailyTransactionSummary'
import DailySettlementSummary from '../Reports/DailySettlementSummary/DailySettlementSummary'
import MarkToMarketReport from '../Reports/MarkToMarket/MarkToMarket'
import MarkToMarketDetail from '../Reports/MarkToMarketDetail/MarkToMarketDetail'
import MarkToMarketSummaryReport from './../Reports/MarkToMarketSummary/MarkToMarketSummaryReport'
import OptionPremiumReport from '../Reports/OptionPremiumReport/OptionPremiumReport'
import TradeConfirmation from '../Reports/TradeConfirmation/TradeConfirmation'
import ThresholdSummaryReport from '../Reports/ThresholdSummaryReport/ThresholdSummaryReport'

import { reports, getReportKeyByUrl } from '../../helpers/reportDefinitions'
import { getRenderData } from '../../helpers/reportUtils'
import { exportTableToCSV } from '../../helpers/csvExporter'

const entityTypeToCollection = {
  Bank: 'banks',
  'Bank Customer': 'bankCustomers',
  Counterparty: 'counterparties',
}

class ReportGenerator extends Component {
  constructor(props) {
    super(props)
    const { user } = props
    if (['TappianAdmin', 'IEAdmin'].includes(user.role)) {
      reports['Transaction Summary'].entityTypes = ['Bank', 'Bank Customer']
    }
    this.state = {
      hideBanks: false,
      selectedType: '',
      selectedEntityId: '',
      selectedReport: '',
      selectedDate: null,
      selectedEndDate: null,
    }
  }

  async componentWillMount() {
    await Promise.all([
      this.props.getAllBanks(),
      this.props.getAllBankCustomers(),
      this.props.getAllCounterparties(),
    ])

    this.shouldHideBanks()
    let selectedEntityId

    const { user } = this.props

    // if we have user has one entity currently selected or associated
    // then auto-select it
    if (
      user.overrideBusinessEntityId ||
      user.associatedEntityIds.length === 1
    ) {
      selectedEntityId = user.associatedEntityId
    } else {
      selectedEntityId = null // user.associatedEntityId
    }

    if (this.props.match) {
      const { id, reportName, startDate, endDate } = this.props.match.params

      const selectedDate = moment(startDate, 'YYYY-MM-DD')
      const lastDate = endDate ? moment(endDate, 'YYYY-MM-DD') : null

      const parsedSelectedEntityId = parseInt(id)
      selectedEntityId = !isNaN(parsedSelectedEntityId)
        ? parsedSelectedEntityId
        : selectedEntityId

      this.setState({
        selectedEntityId,
        selectedReport: getReportKeyByUrl(reportName),
        selectedDate: selectedDate.isValid() ? selectedDate : null,
        selectedEndDate: lastDate && lastDate.isValid() ? selectedDate : null,
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const { banks, bankCustomers, counterparties } = newProps
    if (
      banks !== this.props.banks ||
      bankCustomers !== this.props.bankCustomers ||
      counterparties !== this.props.counterparties
    ) {
      this.shouldHideBanks()
    }
  }

  shouldHideBanks = () => {
    const { user } = this.props
    let hideBanks = false
    this.setState({ hideBanks })
    if (!user.isAdmin) {
      const entities = user.associatedEntityIds.map(x => this.getEntity(x))
      hideBanks = !_.some(entities, x => x && x.entityType === 'Bank')
      this.setState({ hideBanks })
    }
  }

  getDisplayName = entityId => {
    const entity = this.getEntity(entityId)
    return entity ? entity.displayName : ''
  }

  getEntity = entityId => {
    const { banks, bankCustomers, counterparties } = this.props
    const entity = [...banks, ...bankCustomers, ...counterparties].find(
      x => String(x.id) === String(entityId)
    )
    return entity
  }

  getSelectableEntities = selectedReport => {
    const report = reports[selectedReport]
    const { user } = this.props
    if (!report) return []

    let entities = _.orderBy(
      _.flatten(
        report.entityTypes.map(x => this.props[entityTypeToCollection[x]])
      ),
      [entity => entity.displayName.toLowerCase()]
    )

    let reorders = []
    if (user.overrideBusinessEntityId) {
      reorders = [
        entities.find(
          x => String(x.id) === String(user.overrideBusinessEntityId)
        ),
      ]
    } else {
      reorders = entities.filter(x =>
        _.includes(user.associatedEntityIds, x.id)
      )
    }

    entities = [...reorders, ...entities.filter(x => !_.includes(reorders, x))]
    return entities
  }

  onEntityChange = async e => {
    await this.setState({
      selectedEntityId: e.target.value,
    })
    this.previewReport()
  }

  onReportChange = async event => {
    const selectedReport = event.target.value
    await this.setState({ selectedReport })

    const collection = this.getSelectableEntities(selectedReport)
    if (collection.length === 1) {
      await this.setState({ selectedEntityId: _.first(collection).id })
    }
    const report = reports[selectedReport]
    if (!report.hasEndDate) {
      await this.setState({ selectedEndDate: null })
    }
    this.previewReport()
  }

  onDateChange = async date => {
    await this.setState({ selectedDate: date })
    this.previewReport()
  }

  onEndDateChange = async date => {
    await this.setState({ selectedEndDate: date })
    this.previewReport()
  }

  getRenderData = () => {
    const { selectedReport } = this.state
    const report = reports[selectedReport]
    const displayName = this.getDisplayName(this.state.selectedEntityId)
    const {
      selectedEntityId: entityId,
      selectedDate: startDate,
      selectedEndDate: endDate,
    } = this.state
    return getRenderData(report, entityId, startDate, endDate, displayName)
  }

  previewReport = () => {
    const { selectedEntityId } = this.state

    if (selectedEntityId) {
      const data = this.getRenderData()
      this.props.history.push(`/report-generator/preview${data.url}`)
    }
  }

  downloadReport = () => {
    const data = this.getRenderData()

    this.props.generateReport(
      data.url,
      data.fileName,
      data.scale,
      data.pageLayout,
      data.margins
    )
  }

  csvRenderReport = () => {
    const data = this.getRenderData()
    this.props.history.push(`/report-generator/csv${data.url}`)
  }

  doCsvExport = () => {
    const data = this.getRenderData()
    exportTableToCSV(data.fileName.replace('.pdf', '.csv'), 'table.csv-table')
  }

  activeButton = () => {
    const { selectedDate } = this.state

    if (this.showDatePickers()) {
      return Boolean(selectedDate)
    }

    return false
  }

  showDatePickers = () => {
    const { selectedEntityId, selectedReport } = this.state
    return selectedReport && selectedEntityId
  }
  showFirstDatePicker = () => {
    if (!this.showDatePickers()) return false
    const { selectedReport } = this.state

    const report = reports[selectedReport]
    return report.hasStartDate
  }

  showSecondDatePicker = () => {
    if (!this.showDatePickers()) return false
    const { selectedReport } = this.state

    const report = reports[selectedReport]
    return report.hasEndDate
  }

  showSettleDatePicker = () => {
    if (!this.showDatePickers()) return false
    const { selectedReport } = this.state

    const report = reports[selectedReport]
    return report.hasSettleDate
  }

  showEndSettleDatePicker = () => {
    if (!this.showDatePickers()) return false
    const { selectedReport } = this.state

    const report = reports[selectedReport]
    return report.hasSettleDateEnd
  }

  // whether or not to show the SelectEntity dropdown
  showSelectEntity = () => {
    const { selectedReport, selectedEntityId } = this.state

    return (
      // if there's a selectedReport and there are more than one selectable entities
      selectedReport &&
      this.getSelectableEntities(selectedReport).filter(
        x => x.id !== selectedEntityId
      ).length >= 1
    )
  }

  showCsvButton = () => {
    const { selectedReport } = this.state
    return selectedReport !== 'Trade Confirmation'
  }

  renderEntityItems = () => {
    const { selectedReport } = this.state
    const { user } = this.props

    const selectableEntities = this.getSelectableEntities(selectedReport)

    let items = selectableEntities.map(el => (
      <MenuItem key={el.id} value={el.id}>
        {el.displayName}
      </MenuItem>
    ))

    if (
      user.overrideBusinessEntityId ||
      user.associatedEntityIds.length === 1
    ) {
      items = [
        <MenuItem key={user.associatedEntityId} value={user.associatedEntityId}>
          All
        </MenuItem>,
        ...items.filter(x => String(x.key) !== String(user.associatedEntityId)),
      ]
    }
    return items
  }

  renderReportItems = () => {
    const { hideBanks } = this.state
    let reportKeys = !hideBanks
      ? Object.keys(reports)
      : Object.keys(reports).filter(
          x =>
            reports[x].entityTypes.length !== 1 ||
            reports[x].entityTypes[0] !== 'Bank'
        )
    reportKeys = _.intersection(this.props.user.allowedReports, reportKeys)
    return reportKeys.map(el => (
      <MenuItem key={el} value={el}>
        {el}
      </MenuItem>
    ))
  }

  renderReportItems
  render() {
    return (
      <div className={styles.reportGenerator}>
        <div className="row titleRow">
          <div className="markTitle page-title col-xs-3">Reports</div>
          <div className="selectField col-xs-2">
            <Dropdown
              floatingLabelText="Select Report"
              value={this.state.selectedReport}
              onChange={this.onReportChange}
              hintText="Select"
            >
              {this.renderReportItems()}
            </Dropdown>
          </div>

          {this.showSelectEntity() && (
            <div className="col-xs-2">
              <Dropdown
                floatingLabelText={`Select Entity`}
                value={this.state.selectedEntityId}
                onChange={this.onEntityChange}
                hintText="Select"
              >
                {this.renderEntityItems()}
              </Dropdown>
            </div>
          )}

          {this.showFirstDatePicker() && (
            <div className={classnames('date-picker', 'col-xs-2')}>
              <DatePicker
                autoOk
                style={{ width: '256px' }}
                label="Select a date"
                value={this.state.selectedDate}
                onChange={this.onDateChange}
                format="MM/DD/YYYY"
                clearable
                showTodayButton
              />
            </div>
          )}

          {this.showSecondDatePicker() && (
            <div className={classnames('date-picker', 'col-xs-2')}>
              <DatePicker
                autoOk
                style={{ width: '256px' }}
                label="Select an end date"
                value={this.state.selectedEndDate}
                onChange={this.onEndDateChange}
                format="MM/DD/YYYY"
                minDate={
                  this.state.selectedDate ? (
                    this.state.selectedDate
                  ) : (
                    new Date(-8640000000000000)
                  )
                }
                clearable
                showTodayButton
              />
            </div>
          )}

          {this.showSettleDatePicker() && (
            <div className={classnames('date-picker', 'col-xs-2')}>
              <PickHistoricalDate
                onChange={date => this.onDateChange(date)}
                alternateValue={this.state.selectedDate}
                mode="reset"
                noupdate
                clearable
              />
            </div>
          )}

          {this.showEndSettleDatePicker() && (
            <div className={classnames('date-picker', 'col-xs-2')}>
              <PickHistoricalDate
                label="Select an end date"
                onChange={date => this.onEndDateChange(date)}
                alternateValue={this.state.selectedEndDate}
                minDate={this.state.selectedDate}
                mode="reset"
                noupdate
                clearable
                initializeDate={false}
              />
            </div>
          )}

          <div className="col-xs-2">
            <RoundedButton
              disabled={!this.activeButton()}
              onClick={this.downloadReport}
              buttonText={'PDF'}
            />
            {this.showCsvButton() && (
              <RoundedButton
                disabled={!this.activeButton()}
                onClick={this.csvRenderReport}
                buttonText={'CSV'}
              />
            )}
          </div>
        </div>

        <div className="row">
          <Switch>
            <Route
              name="transaction-summary"
              path="*/preview/transaction-summary/:id/:startDate/:endDate?"
              component={DailyTransactionSummary}
            />
            <Route
              name="transaction-summary-csv"
              path="*/csv/transaction-summary/:id/:startDate/:endDate?"
              render={props => (
                <DailyTransactionSummary
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="mark-to-market-summary"
              path="*/preview/mark-to-market-summary/:id/:settleDate"
              component={MarkToMarketSummaryReport}
            />
            <Route
              name="mark-to-market-summary-csv"
              path="*/csv/mark-to-market-summary/:id/:settleDate"
              render={props => (
                <MarkToMarketSummaryReport
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="mark-to-market-report"
              path="*/preview/mark-to-market-report/:id/:settleDate"
              component={MarkToMarketReport}
            />
            <Route
              name="mark-to-market-report-csv"
              path="*/csv/mark-to-market-report/:id/:settleDate"
              render={props => (
                <MarkToMarketReport
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="mark-to-market-detail"
              path="*/preview/mark-to-market-detail/:id/:settleDate"
              component={MarkToMarketDetail}
            />
            <Route
              name="mark-to-market-report-csv"
              path="*/csv/mark-to-market-detail/:id/:settleDate"
              render={props => (
                <MarkToMarketDetail
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="settlement-summary"
              path="*/preview/settlement-summary/:id/:startDate/:endDate?"
              component={DailySettlementSummary}
            />
            <Route
              name="settlement-summary-csv"
              path="*/csv/settlement-summary/:id/:startDate/:endDate?"
              render={props => (
                <DailySettlementSummary
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="option-premium"
              path="*/preview/option-premium/:id/:startDate/:endDate?"
              component={OptionPremiumReport}
            />
            <Route
              name="option-premium-csv"
              path="*/csv/option-premium/:id/:startDate/:endDate?"
              component={props => (
                <OptionPremiumReport
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="threshold-summary-report"
              path="*/preview/threshold-summary-report/:id/:settleDate"
              component={ThresholdSummaryReport}
            />
            <Route
              name="threshold-summary-report-csv"
              path="*/csv/threshold-summary-report/:id/:settleDate"
              component={props => (
                <ThresholdSummaryReport
                  {...props}
                  onMounted={this.doCsvExport}
                  csvData
                />
              )}
            />
            <Route
              name="trade-confirmation"
              path="*/preview/trade-confirmation/:id/:startDate/:endDate?"
              component={TradeConfirmation}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

ReportGenerator.propTypes = {
  getAllBanks: PropTypes.func,
  getAllBankCustomers: PropTypes.func,
  getAllCounterparties: PropTypes.func,
  generateReport: PropTypes.func,
  banks: PropTypes.arrayOf(bankPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  user: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      reportName: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
}

const mapDispatchToProps = {
  getAllBanks: actions.bank.getAll,
  getAllBankCustomers: actions.bankCustomer.getAll,
  getAllCounterparties: actions.counterparty.getAll,
  generateReport: actions.pdf.generate,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    bankCustomers: state.bankCustomer,
    counterparties: state.counterparty,
    user: state.login.user,
  }
}

export default withRouter(
  build({
    component: ReportGenerator,
    mapDispatchToProps,
    mapStateToProps,
  })
)
