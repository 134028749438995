import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import _ from 'lodash'
import { actions, build } from '../container_helpers'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'
import style from './ColumnVisibilityModal.styl'

const styles = theme => {
  return {
    paper: {
      position: 'relative',
      width: '60%',
      height: '360px',
      marginTop: '0.5em',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: 'none',
      // overflow: 'scroll',
      display: 'block',
    },
  }
}

class _ColumnVisibilityModal extends Component {
  constructor(props) {
    super(props)
    const { columns } = props
    this.state = {
      columns: _.cloneDeep(columns),
    }
  }

  cancel = () => this.props.close()

  apply = () => {
    this.props.fillColumns(_.cloneDeep(this.state.columns))
    this.props.close()
  }

  onToggleColumn = columnIndex => {
    const { columns } = this.state
    const newCols = _.cloneDeep(columns)
    newCols[columnIndex].visible = !newCols[columnIndex].visible
    this.setState({ columns: newCols })
  }

  render() {
    const { isOpen, close, classes } = this.props
    const { columns } = this.state

    return (
      <Modal className={style.columnVisibility} open={isOpen} onClose={close}>
        <div className={classes.paper}>
          <div className="header">
            <div className="title">TABLE COLUMNS</div>
          </div>
          <div className="container" style={{ width: '100%' }}>
            {columns.map((x, index) => (
              <div className="item" key={x.property}>
                <label style={{ padding: 0 }}>
                  <Checkbox
                    style={{ padding: 0 }}
                    checked={x.visible}
                    onChange={() => this.onToggleColumn(index)}
                    value={x.property}
                    color="primary"
                  />
                  {x.header.label}
                </label>
              </div>
            ))}
          </div>
          <div className="footer">
            <button className="cancel-btn" onClick={this.cancel}>
              Cancel
            </button>

            <RoundedButton
              buttonText="Apply"
              onClick={this.apply}
              style={{ fontSize: '0.8rem' }}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

_ColumnVisibilityModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  fillColumns: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = {
  close: actions.tradeBlotter.columnvisibility.close,
  fillColumns: actions.tradeBlotter.fillColumns,
}

const mapStateToProps = state => {
  return {
    columns: state.tradeBlotter.columns,
    isOpen: state.tradeBlotter.columnVisibility.isOpen,
  }
}

const ColumnVisibilityModal = withStyles(styles)(_ColumnVisibilityModal)

export default build({
  component: ColumnVisibilityModal,
  mapDispatchToProps,
  mapStateToProps,
})
