import { clone, isNil } from 'lodash'
import { ERRORS, FLOATING_REGEX_SUBMIT } from '../constants'

/**
 * Validating existence of `@` and `.` after that.
 */
export const validateEmail = email => {
  if (!email || email.indexOf('@') === -1) {
    return false
  }
  const split = email.split('@')
  if (!split[1] || (split[1] && split[1].indexOf('.') === -1)) {
    return false
  }
  return true
}

/**
 * User form validator
 */

export const validateUserForm = (newUserState, newErrorsState, users) => {
  const upUser = clone(newErrorsState)
  let valid = true
  const { email, userName, role } = newUserState

  if (!role) {
    valid = false
    upUser.role = ERRORS.INPUT.empty
  }

  if (!newUserState.firstName) {
    valid = false
    upUser.firstName = ERRORS.INPUT.empty
  }

  if (!newUserState.lastName) {
    valid = false
    upUser.lastName = ERRORS.INPUT.empty
  }

  if (!userName) {
    valid = false
    upUser.userName = ERRORS.INPUT.empty
  }

  users.filter(x => x.id !== newUserState.id).forEach(i => {
    if (i.userName === userName) {
      valid = false
      upUser.userName = ERRORS.INPUT.duplicatedUserName
    }
  })

  if (!email) {
    valid = false
    upUser.email = ERRORS.INPUT.empty
  }

  if (!validateEmail(email)) {
    valid = false
    upUser.email = ERRORS.INPUT.invalidEmail
  }

  users.filter(x => x.id !== newUserState.id).forEach(i => {
    if (String(i.email).toLowerCase() === String(email).toLowerCase()) {
      valid = false
      upUser.email = ERRORS.INPUT.duplicatedEmail
    }
  })

  if (newUserState.password && newUserState.password.length < 6) {
    valid = false
    upUser.password = ERRORS.INPUT.invalidPassword
  }

  return { valid, errors: upUser }
}

/**
 * Bank form validator
 */

export const validateBankForm = (newBankState, newErrorsState) => {
  const upBank = clone(newErrorsState)
  let valid = true

  if (!newBankState.name) {
    valid = false
    upBank.name = ERRORS.INPUT.empty
  }
  if (!newBankState.abbreviation) {
    valid = false
    upBank.abbreviation = ERRORS.INPUT.empty
  }
  return { valid, errors: upBank }
}

/**
 * Bank Customer form validator
 */

export const validateBankCustomerForm = (
  newBankCustomerState,
  newErrorsState
) => {
  const upBankCustomer = clone(newErrorsState)
  let valid = true

  if (!newBankCustomerState.name) {
    valid = false
    upBankCustomer.name = ERRORS.INPUT.empty
  }

  if (!newBankCustomerState.leiNumber) {
    valid = false
    upBankCustomer.leiNumber = ERRORS.INPUT.empty
  }
  /*
  if (!newBankCustomerState.companyType) {
    valid = false
    upBankCustomer.type = ERRORS.INPUT.empty
  }
*/
  if (!newBankCustomerState.bankId) {
    valid = false
    upBankCustomer.bank = ERRORS.INPUT.empty
  }

  return { valid, errors: upBankCustomer }
}

/**
 * Counterparty form validator
 */
export const validateCounterpartyForm = (
  newCounterpartyState,
  newErrorsState
) => {
  const upCounterparty = clone(newErrorsState)
  let valid = true

  if (!newCounterpartyState.name) {
    valid = false
    upCounterparty.name = ERRORS.INPUT.empty
  }

  return { valid, errors: upCounterparty }
}

/**
 * Clearing Agent form validator
 */
export const validateClearingAgentForm = (newClearingAgent, newErrorsState) => {
  const upClearingAgent = clone(newErrorsState)
  let valid = true

  if (!newClearingAgent.name) {
    valid = false
    upClearingAgent.name = ERRORS.INPUT.empty
  }

  if (!newClearingAgent.accountNumber) {
    valid = false
    upClearingAgent.accountNumber = ERRORS.INPUT.empty
  }

  return { valid, errors: upClearingAgent }
}

/**
 * Broker form validator
 */

export const validateBrokerForm = (newBrokerState, newErrorsState) => {
  const upBroker = clone(newErrorsState)
  const floatingPattern = new RegExp(FLOATING_REGEX_SUBMIT)
  let valid = true

  if (!newBrokerState.name) {
    valid = false
    upBroker.name = ERRORS.INPUT.empty
  }

  if (!newBrokerState.rate) {
    valid = false
    upBroker.rate = ERRORS.INPUT.empty
  }

  if (newBrokerState.rate && !floatingPattern.test(newBrokerState.rate)) {
    valid = false
    upBroker.rate = ERRORS.INPUT.noFloating
  }

  return { valid, errors: upBroker }
}

/**
 * Private Equity form validator
 */

export const validatePrivateEquityForm = (
  newPrivateEquityState,
  newErrorsState
) => {
  const upPrivateEquity = clone(newErrorsState)
  let valid = true

  if (!newPrivateEquityState.name) {
    valid = false
    upPrivateEquity.name = ERRORS.INPUT.empty
  }

  if (!newPrivateEquityState.leiNumber) {
    valid = false
    upPrivateEquity.leiNumber = ERRORS.INPUT.empty
  }

  if (!newPrivateEquityState.monthlyFee) {
    valid = false
    upPrivateEquity.monthlyFee = ERRORS.INPUT.empty
  }

  return { valid, errors: upPrivateEquity }
}

export const validateTradeBlotterForm = (newTradeState, newErrorsState) => {
  const upTrade = clone(newErrorsState)
  let valid = true

  const fields = [
    'tradeGroupId',
    'buyerId',
    'sellerId',
    'traderName',
    'index',
    'productType',
    'product',
    'instrumentType',
    'monthlyVolume',
    'startDate',
    'endDate',
    'strikePrice',
    'tradePremiumPrice',
    'tradeDateTime',
    'settlementType',
    'paymentDays',
  ]

  fields.forEach(field => {
    if (isNil(newTradeState[field])) {
      valid = false
      upTrade[field] = ERRORS.INPUT.empty
    }
  })

  return { valid, errors: upTrade }
}

export const validateTradeEntryForm = (newTradeState, errorState) => {
  const newErrors = clone(errorState)
  let valid = true

  const fields = ['traderName', 'tradeDateTime', 'startDate', 'endDate']

  fields.forEach(field => {
    if (isNil(newTradeState[field])) {
      valid = false
      newErrors[field] = ERRORS.INPUT.empty
    }
  })

  return { valid, errors: newErrors }
}
