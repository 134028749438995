import TradeBlotter from './TradeBlotter'
import { build, actions } from '../container_helpers'

const mapDispatchToProps = {
  getProductPrices: actions.productPrice.getAll,
  getAllBanks: actions.bank.getAll,
  getAllBankCustomers: actions.bankCustomer.getAll,
  getAllUsers: actions.user.getAll,
  getAllBrokers: actions.broker.getAll,
  getAllPrivateEquities: actions.privateEquity.getAll,
  getAllClearingAgents: actions.clearingAgent.getAll,
  getAllCounterparty: actions.counterparty.getAll,
  getAllProducts: actions.product.getAll,
  page: actions.tradeBlotter.log,
  perPage: actions.tradeBlotter.perp,
  sorting: actions.tradeBlotter.sorting,
  fillColumns: actions.tradeBlotter.initializeColumns,
  getAllTrades: actions.tradeBlotter.getAll,
  getDeletedTrades: actions.tradeBlotter.getDeletedTrades,
  tradeBlotter: actions.tradeBlotter,
  toggleSidebar: actions.sidebar.toggle,
  deleteTrade: actions.tradeBlotter.delete,
  createTrade: actions.tradeBlotter.create,
  updateTrade: actions.tradeBlotter.update,
  openEditModal: actions.tradeEdit.select,
  updateTradeFilters: actions.tradeBlotter.updateTradeFilters,
  generateReport: actions.pdf.generate,
  query: actions.tradeBlotter.querySet,
  goToPage: actions.tradeBlotter.goToPage,
  changePageSize: actions.tradeBlotter.changePageSize,
  fetchQuery: actions.tradeBlotter.fetchQuery,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {
  return {
    banks: state.bank,
    bankCustomers: state.bankCustomer,
    brokers: state.broker,
    counterparties: state.counterparty,
    clearingAgents: state.clearingAgent,
    paginationState: state.tradeBlotter.pagination,
    sortState: state.tradeBlotter.sorting,
    columns: state.tradeBlotter.columns,
    trades: state.tradeBlotter.trades,
    productPrices: state.productPrice,
    privateEquities: state.privateEquity,
    deletedTrades: state.tradeBlotter.deletedTrades,
    sidebar: state.sidebar,
    logged: state.logged,
    selectedDate: state.tradeBlotter.selected.date,
    user: state.login.user,
    queryState: state.tradeBlotter.query,
  }
}

export default build({
  component: TradeBlotter,
  mapDispatchToProps,
  mapStateToProps,
})
