import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import _ from 'lodash'
import Dropdown from '../Dropdown/Dropdown'
import Input from '../UtilsComponents/Input/Input'
import { build, actions } from '../container_helpers'
import {
  bankPropType,
  privateEquityPropType,
  counterpartyPropType,
  bankCustomerPropType,
  tradeActionPropType,
  userPropType,
} from '../shared_prop_types'

class TradeAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
    }
  }

  onActionChanged = e => {
    this.props.updateTradeAction({
      ...this.props.tradeAction,
      action: e.target.value,
    })
  }

  onFirstEntityChanged = e => {
    const isBank = _.some(this.props.banks, bank => bank.id === e.target.value)

    this.props.updateTradeAction({
      ...this.props.tradeAction,
      firstEntityId: e.target.value,
      firstEntityType: isBank ? 'bank' : null,
    })
  }

  onSecondEntityChanged = e => {
    this.props.updateTradeAction({
      ...this.props.tradeAction,
      secondEntityId: e.target.value,
    })
  }

  onTradePriceChanged = e => {
    this.props.updateTradeAction({
      ...this.props.tradeAction,
      tradePrice: parseFloat(e.target.value),
    })
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.blur()
    }
  }

  showBankCustomers = () => {
    if (this.props.tradeAction.secondEntityType) {
      return this.props.tradeAction.secondEntityType === 'bankCustomer'
    }
    return this.props.tradeAction.firstEntityType === 'bank'
  }

  renderIndependentEntityItems = () => {
    const banks = this.props.banks.map(bank => (
      <MenuItem key={`bank:${bank.id}`} value={bank.id}>
        {bank.displayName}
      </MenuItem>
    ))
    const pes = this.props.privateEquities.map(pe => (
      <MenuItem key={`privateEquity:${pe.id}`} value={pe.id}>
        {pe.displayName}
      </MenuItem>
    ))
    return banks.concat(pes)
  }

  renderBankCustomerItems = bankId =>
    this.props.bankCustomers
      .filter(item => item.bankId === bankId)
      .map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.displayName}
        </MenuItem>
      ))

  renderCounterPartyItems = bankId =>
    this.props.counterparties
      .filter(item => item.bankId === bankId)
      .map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.displayName}
        </MenuItem>
      ))

  renderUserItems = entityId =>
    this.props.users.filter(x => x.associatedEntityId === entityId).map(x => (
      <MenuItem key={x.id} value={x.id}>
        {x.fullName}
      </MenuItem>
    ))

  render() {
    return (
      <div className="row">
        <div className="col-xs-2">
          <Dropdown
            floatingLabelText="Independent Entity"
            value={this.props.tradeAction.firstEntityId}
            onChange={this.onFirstEntityChanged}
            errorText={this.state.errors.firstEntityId}
          >
            {this.renderIndependentEntityItems()}
          </Dropdown>
        </div>
        <div className="col-xs-2">
          <Dropdown
            floatingLabelText="Buy/Sell"
            value={this.props.tradeAction.action}
            onChange={this.onActionChanged}
            errorText={this.state.errors.action}
            disabled={this.props.tradeAction.actionDisabled}
          >
            <MenuItem key="Buys" value="Buys">
              Buys
            </MenuItem>
            <MenuItem key="Sells" value="Sells">
              Sells
            </MenuItem>
          </Dropdown>
        </div>
        <div className="col-xs-1">
          {this.props.tradeAction.action === 'Buys' ? 'From' : 'To'}
        </div>
        <div className="col-xs-2">
          <Dropdown
            floatingLabelText={
              this.showBankCustomers() ? 'Bank Customer' : 'Counter Party'
            }
            value={this.props.tradeAction.secondEntityId}
            onChange={this.onSecondEntityChanged}
            errorText={this.state.errors.secondEntityId}
          >
            {this.showBankCustomers() ? (
              this.renderBankCustomerItems(this.props.tradeAction.firstEntityId)
            ) : (
              this.renderCounterPartyItems(this.props.tradeAction.firstEntityId)
            )}
          </Dropdown>
        </div>

        <div className="col-xs-1">For</div>
        <div className="col-xs-2">
          <Input
            type="number"
            name="tradePrice"
            title="Trade Price"
            defaultValue={this.props.tradeAction.tradePrice}
            value={this.props.tradeAction.tradePrice}
            onBlur={this.onTradePriceChanged}
            errorText={this.state.errors.tradePrice}
            onKeyDown={this.handleKeyDown}
          />
        </div>
      </div>
    )
  }
}

TradeAction.propTypes = {
  banks: PropTypes.arrayOf(bankPropType).isRequired,
  privateEquities: PropTypes.arrayOf(privateEquityPropType).isRequired,
  counterparties: PropTypes.arrayOf(counterpartyPropType).isRequired,
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType).isRequired,
  users: PropTypes.arrayOf(userPropType).isRequired,
  tradeAction: tradeActionPropType.isRequired,
  updateTradeAction: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  updateTradeAction: actions.tradeEntry.updateTradeActions,
}

export default build({
  component: TradeAction,
  mapDispatchToProps,
})
