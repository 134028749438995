import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { actions, build } from '../container_helpers'
import {
  bankPropType,
  privateEquityPropType,
  counterpartyPropType,
  clearingAgentPropType,
  bankCustomerPropType,
  userPropType,
  brokerPropType,
  tradeEntryPropType,
  productPropType,
  enumPropType,
  tradeBlotterPropType,
} from '../shared_prop_types'
import EditableTable from '../EditableTable/EditableTable'
import style from './TradeEdit.styl'
import { mergeEntities } from '../../helpers/tradeBlotterUtils'
import { createTradeGroup } from '../../helpers/tradeEntry'
import Input from '../UtilsComponents/Input/Input'
import ToastController from '../ToastController/ToastController'

const styles = theme => {
  return {
    paper: {
      position: 'relative',
      width: '85%',
      height: '95%',
      marginTop: '1em',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: 'none',
      overflow: 'scroll',
      display: 'block',
    },
  }
}

class _TradeEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entities: [],
      rows: [],
      usiNumber: '',
    }
  }
  componentWillMount() {
    this.props.getEnums('instrumenttypes')
    this.props.getAllProducts()
    this.props.getAllUsers()
  }

  componentWillReceiveProps(props) {
    const {
      banks,
      privateEquities,
      counterparties,
      bankCustomers,
      selectedTradeGroupId,
      trades,
    } = props

    if (
      banks !== this.props.banks ||
      privateEquities !== this.props.privateEquities ||
      counterparties !== this.props.counterparties ||
      bankCustomers !== this.props.bankCustomers
    ) {
      const entities = _.orderBy(
        mergeEntities(banks, privateEquities, counterparties, bankCustomers),
        x => x.name
      )
      this.setState({ entities })
    }

    if (
      trades !== this.props.trades ||
      selectedTradeGroupId !== this.props.selectedTradeGroupId
    ) {
      const rows = trades.filter(x => x.tradeGroupId === selectedTradeGroupId)
      let usiNumber = ''
      let gid = 0
      if (rows && rows.length) {
        const first = _.first(rows)
        usiNumber = first.usiNumber
        gid = first.gid
      }
      this.setState({ rows, usiNumber, gid })
    }
  }

  saveTrades = () => {
    const tradeGroup = createTradeGroup(this.state.rows)
    tradeGroup.id = this.props.selectedTradeGroupId
    tradeGroup.trades = _.cloneDeep(this.state.rows)
    tradeGroup.usiNumber = this.state.usiNumber
    tradeGroup.gid = this.state.gid
    this.props.submit(tradeGroup)
  }

  render() {
    const { classes, selectedTradeGroupId } = this.props
    const first = _.first(this.state.rows)
    if (!this.props.user.isAdmin || !selectedTradeGroupId || !first) return null
    return (
      <Modal
        className={style.modal}
        open={!!selectedTradeGroupId}
        onClose={this.props.close}
      >
        <div className={classes.paper}>
          <ToastController />
          <div className="row">
            <div className="col-xs-3">
              <label>Trade Group: </label>
              <span>{selectedTradeGroupId}</span>
            </div>
            <div className="col-xs-3">
              <Input
                name="usiNumber"
                title="USI #"
                defaultValue={first.usiNumber}
                onChange={e => {
                  this.setState({ usiNumber: e.target.value })
                }}
              />
            </div>
            <div className="col-xs-3">
              <Input
                name="gid"
                title="GID"
                defaultValue={first.gid}
                onChange={e => {
                  this.setState({ gid: e.target.value })
                }}
              />
            </div>
            <div className="col-xs-3">
              <label>Reference ID:</label>
              <span>{first.referenceId}</span>
            </div>
          </div>
          <EditableTable
            entities={this.state.entities}
            brokers={this.props.brokers}
            instrumentTypes={this.props.instrumentTypes}
            products={this.props.products}
            users={this.props.users}
            updateTrades={payload => this.setState(payload)}
            rows={this.state.rows}
          />
          <button onClick={this.saveTrades}>Save</button>
        </div>
      </Modal>
    )
  }
}

_TradeEdit.propTypes = {
  banks: PropTypes.arrayOf(bankPropType),
  brokers: PropTypes.arrayOf(brokerPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  clearingAgents: PropTypes.arrayOf(clearingAgentPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  users: PropTypes.arrayOf(userPropType),
  classes: PropTypes.object,
  products: PropTypes.arrayOf(productPropType),
  tradeEntry: tradeEntryPropType,
  getEnums: PropTypes.func,
  instrumentTypes: PropTypes.arrayOf(enumPropType),
  trades: PropTypes.arrayOf(tradeBlotterPropType),
  selectedTradeGroupId: PropTypes.string,
  close: PropTypes.func,
  submit: PropTypes.func,
  getAllProducts: PropTypes.func,
  getAllUsers: PropTypes.func,
  user: PropTypes.object,
}

const mapDispatchToProps = {
  getEnums: actions.enum.getAll,
  close: actions.tradeEdit.close,
  submit: actions.tradeEdit.submit,
  getAllProducts: actions.product.getAll,
  getAllUsers: actions.user.getAll,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {
  return {
    tradeEntry: state.tradeEntry,
    products: state.product.products,
    instrumentTypes: state.enum.instrumentTypes,
    selectedTradeGroupId: state.tradeEdit.selected,
    users: state.user,
    user: state.login.user,
  }
}

const TradeEdit = withStyles(styles)(_TradeEdit)
export default build({
  component: TradeEdit,
  mapDispatchToProps,
  mapStateToProps,
})
