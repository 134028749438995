import { polyfill as es6PromisePolyfill } from 'es6-promise'

import 'core-js/features/array'
import 'core-js/features/object'
import 'core-js/features/string'
// import 'es6-object-assign'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles'
import MuiThemeProviderOld from 'material-ui/styles/MuiThemeProvider'
import { MuiPickersUtilsProvider as MuiPickersUtilsProviderOld } from 'material-ui-pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications'
import MomentUtils from '@date-io/moment'
import Root from './components/Root'
import './application.styl'

// Requirement of axios for IE 10, 11
es6PromisePolyfill()
let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#00AEEF',
    },
  },
  formControl: {
    minWidth: 256,
  },
})

const ToastContainer = props => (
  <DefaultToastContainer
    className="toast-container"
    // css={{ outline: '4px dashed green' }}
    style={{ zIndex: 1000 }}
    {...props}
  />
)

theme = responsiveFontSizes(theme)
const App = () => (
  <MuiThemeProvider theme={theme}>
    <MuiThemeProviderOld>
      <MuiPickersUtilsProviderOld utils={MomentUtils}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ToastProvider components={{ ToastContainer }}>
            <Root />
          </ToastProvider>
        </MuiPickersUtilsProvider>
      </MuiPickersUtilsProviderOld>
    </MuiThemeProviderOld>
  </MuiThemeProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))
