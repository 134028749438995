import { createActions } from 'redux-actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const { product: productActions } = createActions({
  PRODUCT: {
    SET: undefined,
    IMPORT: undefined,
  },
})

productActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/api/products')
  dispatch(productActions.set(response.data))
}

productActions.get = id => ({ axios }) => async dispatch => {
  const response = await axios.get(`/api/products/${id}`)
  dispatch(productActions.set(response.data))
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.readAsText(file)
  })
}

productActions.importCsv = file => ({ axios }) => async dispatch => {
  const data = await readFile(file)
  try {
    dispatch(showLoading())
    await axios.post('/api/products/import', {
      data,
    })
    dispatch(productActions.import({ success: true }))
    dispatch(hideLoading())
  } catch (e) {
    dispatch(hideLoading())
    if (e.response && e.response.data) {
      dispatch(productActions.import(e.response.data))
    } else {
      dispatch(productActions.import({ error: 'Unknown Error!' }))
    }
  }
}

export default productActions
