import { createActions } from 'redux-actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const { holiday: holidayActions } = createActions({
  HOLIDAY: {
    SET: undefined,
    IMPORT: undefined,
  },
})

holidayActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/api/holidays')
  dispatch(holidayActions.set(response.data))
}

holidayActions.clearAll = () => ({ axios }) => async dispatch => {
  await axios.delete('/api/holidays')
  dispatch(holidayActions.set([]))
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.readAsText(file)
  })
}

holidayActions.importCsv = file => ({ axios }) => async dispatch => {
  //   const reader = new FileReader()
  //   reader.readAsText(file)
  //   reader.onload = async () => {

  //   }
  const data = await readFile(file)
  try {
    dispatch(showLoading())
    await axios.post('/api/holidays/import', {
      data,
    })
    dispatch(holidayActions.import({ success: true }))
    dispatch(hideLoading())
  } catch (e) {
    dispatch(hideLoading())
    if (e.response && e.response.data) {
      dispatch(holidayActions.import(e.response.data))
    } else {
      dispatch(holidayActions.import({ error: 'Unknown Error!' }))
    }
  }
}

export default holidayActions
