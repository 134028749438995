import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileReaderInput from 'react-file-reader-input'
import { connect } from 'react-redux'
import actions from '../../../store/actions'
import './ProductImport.styl'
import { productPropType } from '../../shared_prop_types'

class ProductImport extends Component {
  constructor() {
    super()
    this.state = {
      files: [],
      loading: false,
    }
  }
  componentWillMount() {
    this.props.getProducts()
  }

  componentWillReceiveProps(nextProps) {
    const haveResult = nextProps.importSuccess || nextProps.importError
    if (this.state.loading && haveResult) {
      this.setState({ loading: false })
    }
  }

  handleFile = (e, results) => {
    this.setState({ files: results.map(x => x[1]) })
  }

  handleSubmit = async () => {
    // e.preventDefault()
    this.setState({ loading: true })
    await Promise.all(this.state.files.map(x => this.props.importProducts(x)))
    this.setState({ files: [] })
    await this.props.getProducts()
  }

  resetForm = () => {
    // this.props.resetProductPrices()
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="text-center">
          <p>
            Importing will 'Upsert' (update existing, or insert if new) any rows
            in the csv. The 'ID' is the 'Product Code' (i.e. 'CS', 'AO', etc).
            So if you change the values in a row, but keep 'Product Code' the
            same, it will update the Product with that 'Product Code' in the
            database. If you add a row that contains a new 'Product Code', it
            will add that Product to the database.
          </p>
          {this.state.loading && (
            <div className="entry">
              Importing...<CircularProgress />
            </div>
          )}
          {this.props.importSuccess && (
            <div className="entry">Successfully imported!</div>
          )}
          {this.props.importError && (
            <div className="entry">Error: {this.props.importError}</div>
          )}

          {!this.state.loading && (
            <div className="entry">
              <FileReaderInput
                as="text"
                id="my-file-input"
                name="Product CSV"
                accept=".csv,.CSV"
                placeholder="Product CSV"
                className="inputClass"
                onChange={this.handleFile}
                multiple
              >
                <button type="button">Select a file!</button>
              </FileReaderInput>
              {Boolean(this.state.files.length) && (
                <button
                  type="button"
                  className="import-button"
                  onClick={this.handleSubmit}
                >
                  Import!
                </button>
              )}
            </div>
          )}
          <table>
            {this.props.products.map(item => (
              <tr key={item.id}>
                {Object.values(item).map(val => <td>{val}</td>)}
              </tr>
            ))}
          </table>
        </div>
      </div>
    )
  }
}

ProductImport.propTypes = {
  getProducts: PropTypes.func.isRequired,
  importProducts: PropTypes.func.isRequired,
  importSuccess: PropTypes.bool,
  importError: PropTypes.number,
  products: PropTypes.arrayOf(productPropType),
}

const mapDispatchToProps = {
  getProducts: actions.product.getAll,
  importProducts: actions.product.importCsv,
}

const mapStateToProps = state => {
  return {
    importSuccess: state.product.import.success,
    importError: state.product.import.error,
    products: state.product.products,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductImport)
