import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { build, actions } from '../../container_helpers'
import TappianInput from '../../UtilsComponents/TappianInput/TappianInput'
import RoundedButton from '../../UtilsComponents/RoundedButton/RoundedButton'
import ResetPasswordForm from '../ResetPassword/ResetPasswordForm'
import './ChangePasswordForm.styl'
import password_reducer from '../../../store/reducer/password_reducer'

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginUser: {
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      errors: '',
      openResetPassword: false,
      validationState: {
        longEnough: true,
        uniqueChars: true,
        oneDigit: true,
        lowerCaseChar: true,
        upperCaseChar: true,
        match: true,
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    const { success, error } = nextProps
    if (success === true) {
      this.props.history.push.push('/dashboard')
    }

    if (error && error.length) {
      this.setState({ errors: error })
    }
  }

  numDigits = str => str.replace(/[^0-9]/g, '').length

  validatePassword = () => {
    const { newPassword, newPasswordConfirm } = this.state.loginUser
    console.log(this.state.loginUser)
    const newValidationState = {
      longEnough: newPassword.length >= 8,
      uniqueChars: new Set(newPassword).size >= 4,
      oneDigit: this.numDigits(newPassword) >= 1,
      lowerCaseChar: (newPassword.match(/[a-z]/g) || []).length >= 1,
      upperCaseChar: (newPassword.match(/[A-Z]/g) || []).length >= 1,
      match: newPassword === newPasswordConfirm,
    }
    this.setState({
      validationState: newValidationState,
    })

    if (Object.values(newValidationState).some(v => v === false)) {
      this.setState({ errors: 'Your new password must follow these rules: ' })
    } else {
      this.setState({ errors: '' })
    }
  }

  saveLoginUser = userObj => {
    const { loginUser } = this.state
    this.setState({ loginUser: { ...loginUser, ...userObj } }, () =>
      this.validatePassword()
    )
  }

  submit = e => {
    e.preventDefault()
    this.validatePassword()
    if (!this.state.errors.length) {
      this.props.changePassword(this.state.loginUser)
    }
  }

  renderChangePassword = () => (
    <form onSubmit={this.validatePasswordsMatch}>
      <h1 className="login-form__heading">Change Password</h1>
      <div>
        <TappianInput
          name="password"
          label="Current Password"
          type="password"
          value={this.state.loginUser.password}
          onChange={e => {
            this.saveLoginUser({ password: e.target.value })
          }}
        />
      </div>
      <div>
        <TappianInput
          name="new password1"
          label="New Password"
          type="password"
          value={this.state.loginUser.newPassword}
          onChange={e => {
            this.saveLoginUser({ newPassword: e.target.value })
          }}
        />
      </div>
      <div>
        <TappianInput
          name="new password2"
          label="Confirm New Password"
          type="password"
          value={this.state.loginUser.newPasswordConfirm}
          onChange={e => {
            this.saveLoginUser({ newPasswordConfirm: e.target.value })
          }}
        />
      </div>
      {this.state.errors.length ? (
        <div>
          <div className="error error-message">{`${this.state.errors}`}</div>
          {!this.state.validationState.match ? (
            <div className="error error-message">Passwords must match.</div>
          ) : (
            ''
          )}
          <div className="error error-message">
            <ul className="list-group">
              {!this.state.validationState.longEnough ? (
                <li className="list-group-item">At least 8 characters long.</li>
              ) : (
                ''
              )}
              {!this.state.validationState.uniqueChars ? (
                <li className="list-group-item">
                  At least 4 unique characters.
                </li>
              ) : (
                ''
              )}
              {!this.state.validationState.oneDigit ? (
                <li className="list-group-item">At least 1 digit.</li>
              ) : (
                ''
              )}
              {!this.state.validationState.lowerCaseChar ? (
                <li className="list-group-item">
                  At least one lowercase character.
                </li>
              ) : (
                ''
              )}
              {!this.state.validationState.upperCaseChar ? (
                <li className="list-group-item">
                  At least one uppercase character.
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}

      <RoundedButton
        buttonText={'Go'}
        onClick={this.submit}
        disabled={this.state.errors.length}
      />

      <div className="forgot-password-link" style={{ paddingTop: '40px' }}>
        <a onClick={() => this.setState({ openResetPassword: true })}>
          Forgot your password?
        </a>
      </div>
      <ResetPasswordForm
        open={this.state.openResetPassword}
        onClose={() => this.setState({ openResetPassword: false })}
      />
    </form>
  )

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <div className="logo-container">
          <div className="image-logo">
            <img src="/img/tappian-logo-color.svg" alt="Tappian Logo" />
          </div>
        </div>
        <div className="login-form">{this.renderChangePassword()}</div>
        <Link className="back-link" to={`/`}>
          &#8592; Back
        </Link>
      </div>
    )
  }
}
ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}
const mapDispatchToProps = {
  changePassword: actions.password.change,
}
const mapStateToProps = state => {
  return {
    error: state.password.error,
    success: state.password.success,
  }
}
export default withRouter(
  build({
    component: ChangePasswordForm,
    mapStateToProps,
    mapDispatchToProps,
  })
)
