import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import _, { debounce } from 'lodash'
import moment from 'moment'
import { MenuItem } from 'material-ui'
import * as Search from 'searchtabular'
import * as sort from 'sortabular'
import * as resolve from 'table-resolver'
import classnames from 'classnames'
import tradeBlotterColumns from './trade_blotter_columns'
import TradeBlotterTable from './TradeBlotterTable'
import paginate from './paginate'
import Paginator from './Paginator'
import PrimaryControls from './PrimaryControls'
import TradeModal from './TradeModal'
import styles from './stylesheet.styl'
import { mergeEntities } from '../../helpers/tradeBlotterUtils'
import { downloadCSV } from '../../helpers/csvExporter'
import {
  tradeBlotterPropType,
  bankPropType,
  bankCustomerPropType,
  privateEquityPropType,
  counterpartyPropType,
  clearingAgentPropType,
  userPropType,
  brokerPropType,
} from '../shared_prop_types'

import TradeEntryWizard from '../TradeEntry/TradeEntryWizard'
import TradeEdit from '../TradeEdit/TradeEdit'
import PickHistoricalDate from '../PickHistoricalDate/PickHistoricalDate'
import PerPage from './PerPage'
import EntitySelector from '../EntitySelector/EntitySelector'
import { getRenderData, getFileName } from '../../helpers/reportUtils'
import { reports } from '../../helpers/reportDefinitions'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'
import { formatCurrencyForReport } from '../../helpers/formatCurrency'
// import Input from '../UtilsComponents/Input/Input'

const transformCsvData = data =>
  data.map(x => {
    return {
      'Trade Date': moment(x.tradeDateTime).format('MM/DD/YYYY'),
      'Reference ID': x.referenceId,
      'Trade ID': x.tid,
      Buyer: x.buyerName,
      Seller: x.sellerName,
      'Monthly Volume': x.monthlyVolume.toLocaleString('en-US'),
      'Product Type': x.productType,
      Product: x.product,
      Instrument: x.instrumentType,
      'Strike Price': formatCurrencyForReport(x.strikePrice),
      'Trade Price': formatCurrencyForReport(x.tradePremiumPrice, 4),
      'Contract Month': moment(x.startDate).format('MMM-YY'),
      'Settlement Type': x.settlementType.replace('_', ' '),
      'Payment Days': x.paymentDays,
      'Close Price': formatCurrencyForReport(x.closePrice, 4),
      MTM: formatCurrencyForReport(x.tradeMarkToMarket),
      'Option Premium': formatCurrencyForReport(x.netOptionPremium),
      Net: formatCurrencyForReport(x.net),
    }
  })
class TradeBlotter extends Component {
  constructor(props) {
    super(props)

    this.fetchQueryDebounced = debounce(props.fetchQuery, 500)

    this.state = {
      currentDate: null,
      selectedEntityId: '',
      filteredElements: null,
      selectedRow: null,
      showDeletedTrades: false,
      tableType: 'current',
      elements: [],
      selectedDate: '',
    }
  }

  componentWillMount() {
    const { user } = this.props
    this.props.getAllBanks()
    this.props.getAllPrivateEquities()
    this.props.getAllBankCustomers()
    this.props.getAllCounterparty()
    this.props.getAllTrades()
    this.props.getDeletedTrades()
    this.props.getAllBrokers()
    this.props.fillColumns(tradeBlotterColumns(this.props.sorting, user))
  }

  componentWillReceiveProps(newProps) {
    if (this.state.selectedEntityId) {
      if (
        newProps.trades !== this.props.trades ||
        newProps.deletedTrades !== this.props.deletedTrades
      ) {
        const trades = this.state.showDeletedTrades
          ? newProps.deletedTrades
          : newProps.trades
        const filteredElements = trades.filter(
          el =>
            el.buyerId == this.state.selectedEntityId ||
            el.sellerId == this.state.selectedEntityId
        )
        this.setState({ filteredElements })
      }
    }
  }

  toggleForm = () => {
    this.props.toggleSidebar()
  }

  onCloseTradeModal = () => {
    this.setState({ selectedRow: null })
  }

  handleEntityChange = selectedEntityId => {
    this.setState({ selectedEntityId })
    const trades = this.getSelectedTrades()
    const filteredElements = selectedEntityId
      ? trades.filter(
          el =>
            el.buyerId == selectedEntityId || el.sellerId == selectedEntityId
        )
      : trades
    this.setState({ filteredElements })
    this.props.updateTradeFilters({ entityId: selectedEntityId })
  }

  onRow = row => {
    return {
      onClick: () => this.onRowSelected(row),
    }
  }

  onRowSelected = row => {
    this.setState({ selectedRow: row })
  }

  onSearch = query => {
    this.props.query(query)
    this.fetchQueryDebounced()
  }

  onSelect = page => {
    this.props.goToPage({ page })
  }

  onToggleColumn = ({ columnIndex }) => {
    const columns = _.cloneDeep(this.state.columns)
    const column = columns[columnIndex]

    column.visible = !column.visible

    const query = _.cloneDeep(this.props.queryState)
    delete query[column.property]

    this.setState({ columns })
  }

  handleDateChange = (event, currentDate) => {
    this.setState({ currentDate })
  }

  confirmDeleteTrade = tradeGroup => {
    tradeGroup.forEach(async ({ id }) => {
      await this.props.deleteTrade(id)
    })
    this.setState({ selectedRow: null })
  }

  editTrade = () => {
    const { id } = this.state.selectedRow
    const editingTrade = _.find(this.props.trades, { id })
    this.props.openEditModal(editingTrade.tradeGroupId)
    this.onCloseTradeModal()
  }

  setTable = tableType => {
    const showDeletedTrades = tableType === 'deleted'
    this.setState({ tableType, showDeletedTrades })
  }

  getSelectedTrades = () =>
    this.state.showDeletedTrades ? this.props.deletedTrades : this.props.trades

  getDisplayName = entityId => {
    let entity = this.getEntity(entityId)
    if (!entity) {
      entity = this.getEntity(this.props.user.overrideBusinessEntityId)
    }
    return entity ? entity.displayName : 'All'
  }

  getEntity = entityId => {
    const { banks, privateEquities } = this.props
    const entity = [...banks, ...privateEquities].find(x => x.id === entityId)
    return entity
  }

  downloadCsv = () => {
    const { selectedEntityId } = this.state
    const { selectedDate } = this.props
    const displayName = this.getDisplayName(selectedEntityId)
    const report = reports['Mark to Market Detailed']
    const fileName = getFileName(
      displayName,
      report.reportFileName,
      selectedDate
    ).replace('.pdf', '.csv')

    if (this.props.user.isAdmin) {
      downloadCSV(this.state.filteredElements || this.props.trades, fileName)
    } else {
      const data = transformCsvData(
        this.state.filteredElements || this.props.trades
      )
      downloadCSV(data, fileName)
    }
  }

  buttonsDisabled = () => {
    const { selectedEntityId } = this.state
    const { selectedDate } = this.props

    return !selectedEntityId || !selectedDate
  }

  downloadPdf = () => {
    const { selectedEntityId } = this.state
    const { selectedDate } = this.props
    const displayName = this.getDisplayName(selectedEntityId)
    const report = reports['Mark to Market Detailed']
    const data = getRenderData(
      report,
      selectedEntityId,
      selectedDate,
      null,
      displayName
    )

    this.props.generateReport(
      data.url,
      data.fileName,
      data.scale,
      data.pageLayout,
      data.margins
    )
  }

  render() {
    if (!this.props.trades) return null
    let newElements
    const trades = this.getSelectedTrades()
    if (this.state.filteredElements) {
      newElements = this.state.filteredElements
    } else {
      newElements = trades
    }

    const columns = this.props.columns
    const cols = columns.filter(column => column.visible)
    const paginated = compose(paginate(this.props.paginationState))(newElements)
    return (
      <div>
        {this.props.user.isAdmin && (
          <div>
            <TradeEntryWizard
              banks={this.props.banks}
              privateEquities={this.props.privateEquities}
              bankCustomers={this.props.bankCustomers}
              counterparties={this.props.counterparties}
              clearingAgents={this.props.clearingAgents}
              brokers={this.props.brokers}
            />
            <TradeEdit
              banks={this.props.banks}
              privateEquities={this.props.privateEquities}
              bankCustomers={this.props.bankCustomers}
              counterparties={this.props.counterparties}
              clearingAgents={this.props.clearingAgents}
              brokers={this.props.brokers}
              trades={trades}
            />
            <TradeModal
              onRequestClose={this.onCloseTradeModal}
              selectedRow={this.state.selectedRow}
              showDeletedTrades={this.state.showDeletedTrades}
              confirmDeleteTrade={this.confirmDeleteTrade}
              trades={trades}
              editTrade={this.editTrade}
            />
          </div>
        )}
        <div className={styles.tradeBlotter}>
          <div className="row titleRow">
            <div className="markTitle page-title col-xs-3">Trade Blotter</div>
            <div className="selectField col-xs-2">
              <PickHistoricalDate />
            </div>
            <EntitySelector
              independentEntities={mergeEntities(
                this.props.banks,
                this.props.privateEquities
              )}
              dependentEntities={mergeEntities(
                this.props.bankCustomers,
                this.props.counterparties
              )}
              onChange={this.handleEntityChange}
            />

            <div className="col-xs-2">
              <PrimaryControls
                className="controls"
                perPage={this.props.paginationState.perPage}
                query={this.props.queryState}
                columns={cols}
                rows={newElements}
                onSearch={this.onSearch}
              />
            </div>
            <div className="col-xs-2">
              <RoundedButton
                disabled={this.buttonsDisabled()}
                buttonText={'PDF'}
                onClick={this.downloadPdf}
              />
              <RoundedButton buttonText={'CSV'} onClick={this.downloadCsv} />
            </div>
          </div>
          <div className="table-container">
            <div className={classnames('table-options', this.state.tableType)}>
              {this.props.user.isAdmin && (
                <div>
                  <div
                    className="current-button"
                    onClick={this.setTable.bind(null, 'current')}
                  >
                    Current
                  </div>
                  <div
                    className="deleted-button"
                    onClick={this.setTable.bind(null, 'deleted')}
                  >
                    Deleted
                  </div>
                </div>
              )}
            </div>
            <TradeBlotterTable
              cols={cols}
              query={this.props.queryState}
              onRow={this.onRow}
              paginatedRows={paginated.rows}
            />
          </div>
          <div className="controls">
            <Paginator
              pagination={this.props.paginationState}
              pages={paginated.amount}
              onSelect={this.onSelect}
            />
            <div className="per-page-container">
              <PerPage
                value={this.props.paginationState.perPage}
                onChange={this.props.changePageSize}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
//
TradeBlotter.propTypes = {
  paginationState: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
  }),
  query: PropTypes.func,
  queryState: PropTypes.object,
  sortState: PropTypes.shape({}),
  page: PropTypes.func,
  perPage: PropTypes.func,
  sorting: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
  trades: PropTypes.arrayOf(tradeBlotterPropType),
  deletedTrades: PropTypes.arrayOf(tradeBlotterPropType),
  fillColumns: PropTypes.func,
  getAllTrades: PropTypes.func,
  getAllBanks: PropTypes.func,
  getAllBankCustomers: PropTypes.func,
  getAllUsers: PropTypes.func,
  getAllBrokers: PropTypes.func,
  getAllPrivateEquities: PropTypes.func,
  getAllClearingAgents: PropTypes.func,
  getAllCounterparty: PropTypes.func,
  getProductPrices: PropTypes.func.isRequired,
  productPrices: PropTypes.arrayOf(PropTypes.object),
  banks: PropTypes.arrayOf(bankPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  clearingAgents: PropTypes.arrayOf(clearingAgentPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  sidebar: PropTypes.shape({
    sidebarOpen: PropTypes.bool,
  }).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  brokers: PropTypes.arrayOf(brokerPropType),
  deleteTrade: PropTypes.func,
  createTrade: PropTypes.func,
  updateTrade: PropTypes.func,
  getDeletedTrades: PropTypes.func,
  openEditModal: PropTypes.func,
  updateTradeFilters: PropTypes.func,
  selectedDate: PropTypes.string,
  generateReport: PropTypes.func,
  user: PropTypes.object,
  goToPage: PropTypes.func,
  changePageSize: PropTypes.func,
  fetchQuery: PropTypes.func,
}

export default TradeBlotter
