import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import styles from './DailySettlementSummary.styl'
import { build, actions } from '../../container_helpers'
import { formatCurrency, currencyDiv } from '../../../helpers/formatCurrency'
import * as EntityPropType from '../../shared_prop_types'

const getSettlementText = (trades, entity, totalSum) => {
  const paymentDate = trades.length
    ? moment(trades[0].paymentDate).format('MM/DD/YYYY')
    : null

  let workingSum = totalSum
  if (entity.entityType !== 'Bank' && entity.entityType !== 'PrivateEquity') {
    workingSum = -workingSum
  }

  if (workingSum === 0) return 'No Settlement Due'
  else if (workingSum > 0)
    return `Total Settlement to be REC'D by ${paymentDate}`
  return `Total Settlement to be PAID by ${paymentDate}`
}

const SettlementSummary = props => {
  const { trades, entity } = props

  if (!trades || !trades.length) return null

  const startDate = trades[0].expirationDate
  const totalSum = _.sumBy(trades, 'tradeMarkToMarket')
  const settlementText = getSettlementText(trades, entity, totalSum)
  const logo = `${process.env.API_URL}/${entity.logo}`

  const renderReport = csvData => (
    <div>
      <table
        className={classnames(
          'summary-table',
          styles.pageBreak,
          'report-table',
          csvData && 'csv-table'
        )}
      >
        <thead>
          <tr className="summary-header-1">
            <th align="center" colSpan="20">
              <div>
                <img
                  className="tappian-report-logo"
                  src="/img/tappian-logo-color.svg"
                  alt="tappian logo"
                />
                <h4>{entity.name}</h4>
                <img className="report-logo" src={logo} alt="bank logo" />
              </div>
            </th>
          </tr>
          <tr className="summary-header-2">
            <th colSpan="2">
              <h4>Expiration Date</h4>
            </th>
            <th colSpan="2">
              <h4>{moment(startDate).format('MM/DD/YYYY')}</h4>
            </th>
            <th colSpan="7">
              <h4>SETTLEMENT SUMMARY</h4>
            </th>
            <th colSpan="9">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 style={{ marginLeft: '1em' }}>{settlementText}</h4>
                <h4>{formatCurrency(Math.abs(totalSum))}</h4>
              </div>
            </th>
          </tr>
          <tr>
            <th>Trade Date</th>
            <th>Reference ID</th>
            <th>
              Trade<br />Group ID
            </th>
            <th>Trade ID</th>
            <th>Buyer</th>
            <th>Seller</th>
            <th>
              Contract<br />Month
            </th>
            <th>
              Monthly<br />Volume
            </th>
            <th>
              Product<br />Type
            </th>
            <th>Product</th>
            <th>Instrument</th>
            <th>Strike Price</th>
            <th>
              Settlement<br />Type
            </th>
            <th>
              Payment<br />Days
            </th>
            <th>
              Payment<br />Date
            </th>
            <th>
              Expiration<br />Date
            </th>
            <th>
              Trade<br />Price
            </th>
            <th>
              Settle<br />Price
            </th>
            <th>Spread</th>
            <th style={{ minWidth: '130px' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {_.sortBy(trades, 'tid').map(x => (
            <tr key={x.id}>
              <td>{moment(x.tradeDateTime).format('MM/DD/YYYY')}</td>
              <td>{x.referenceId}</td>
              <td>{x.tradeGroupReferenceId}</td>
              <td>{x.tid}</td>
              <td>{x.buyerName}</td>
              <td>{x.sellerName}</td>
              <td>{moment(x.startDate).format('MMM-YY')}</td>
              <td>{x.monthlyVolume.toLocaleString('en-US')}</td>
              <td>{x.productType}</td>
              <td>{x.product}</td>
              <td>{x.instrumentType}</td>
              <td>{csvData ? x.strikePrice : currencyDiv(x.strikePrice)}</td>
              <td>{x.settlementType.replace('_', ' ')}</td>
              <td>{x.paymentDays}</td>
              <td>{moment(x.paymentDate).format('MM/DD/YYYY')}</td>
              <td>{moment(x.expirationDate).format('MM/DD/YYYY')}</td>
              <td>
                {csvData ? (
                  x.tradePremiumPrice
                ) : (
                  currencyDiv(x.tradePremiumPrice, 4)
                )}
              </td>
              <td>{csvData ? x.closePrice : currencyDiv(x.closePrice, 4)}</td>
              <td>{csvData ? x.spread : currencyDiv(x.spread, 4)}</td>
              <td>
                {csvData ? (
                  x.tradeMarkToMarket
                ) : (
                  currencyDiv(x.tradeMarkToMarket)
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
  try {
    return (
      <div>
        {renderReport()}
        {props.csvData && renderReport(true)}
      </div>
    )
  } catch (e) {
    return null
  }
}
class DailySettlementSummary extends Component {
  constructor() {
    super()
    this.state = {
      bank: null,
      trades: [],
      firstUpdate: false,
    }
  }

  componentWillMount() {
    const { id, startDate, endDate } = this.props.match.params
    this.props.getSettlementSummaries(id, {
      startDate,
      endDate,
    })
    this.props.getEntity(id)
  }

  componentWillReceiveProps(props) {
    if (
      this.props.match.params.id !== props.match.params.id ||
      this.props.match.params.startDate !== props.match.params.startDate ||
      this.props.match.params.endDate !== props.match.params.endDate
    ) {
      const { id, startDate, endDate } = props.match.params
      this.props.getSettlementSummaries(id, {
        startDate,
        endDate,
      })
      this.props.getEntity(id)
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { trades, entity } = this.props
    return !_.isEmpty(entity) && !_.isEmpty(trades)
  }

  render() {
    try {
      const { trades, entity, csvData } = this.props
      const { startDate } = this.props.match.params
      if (!trades) return null

      return (
        <div className={styles.dailySettlementSummary} align="center">
          {trades.map(x => (
            <SettlementSummary
              entity={entity}
              trades={x}
              startDate={startDate}
              csvData={csvData}
            />
          ))}
        </div>
      )
    } catch (e) {
      return null
    }
  }
}

DailySettlementSummary.propTypes = {
  trades: PropTypes.arrayOf(
    PropTypes.arrayOf(EntityPropType.tradeBlotterPropType)
  ),
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
  getSettlementSummaries: PropTypes.func,
  getEntity: PropTypes.func,
  match: EntityPropType.startEndDateRouteType,
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
}

const mapDispatchToProps = {
  getSettlementSummaries:
    actions.tradeSummary.getAllSettlementSummariesForEntityId,
  getEntity: actions.entity.get,
}

const mapStateToProps = state => {
  return {
    trades: _.orderBy(
      _.map(_.groupBy(state.tradeSummaries, 'expirationDate'), x => x),
      x => _.first(x).expirationDate,
      'desc'
    ),
    entity: state.entity,
  }
}

export default build({
  component: DailySettlementSummary,
  mapDispatchToProps,
  mapStateToProps,
})
