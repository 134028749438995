import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { actions, build } from '../container_helpers'
import styles from './styles.styl'
import {
  bankPropType,
  bankCustomerPropType,
  privateEquityPropType,
} from '../shared_prop_types'
import PickHistoricalDate from '../PickHistoricalDate/PickHistoricalDate'
import EntitySelector from '../EntitySelector/EntitySelector'
import { mergeEntities } from '../../helpers/tradeBlotterUtils'
import MarkToMarketSummary from './../Reports/MarkToMarketSummary/MarkToMarketSummary'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'
import { getRenderData, getFileName } from '../../helpers/reportUtils'
import { reports } from '../../helpers/reportDefinitions'
import { exportTableToCSV } from '../../helpers/csvExporter'

const transformMTMDataForCSV = data => {
  if (_.isEmpty(data)) return []
  return [
    {
      Company: 'CUSTOMERS',
      'Mark to Market': '',
      'Net Option Premium': '',
      Net: '',
    },
    ...data.bankCustomers.map(x => {
      return {
        Company: x.name,
        'Mark to Market': x.markToMarket,
        'Net Option Premium': x.netOptionPremium,
        Net: x.net,
      }
    }),
    {
      Company: 'Customer Total',
      'Mark to Market': data.bankCustomerTotal.markToMarket,
      'Net Option Premium': data.bankCustomerTotal.netOptionPremium,
      Net: data.bankCustomerTotal.net,
    },
    {
      Company: 'COUNTERPARTIES',
      'Mark to Market': '',
      'Net Option Premium': '',
      Net: '',
    },
    ...data.counterparties.map(x => {
      return {
        Company: x.name,
        'Mark to Market': x.markToMarket,
        'Net Option Premium': x.netOptionPremium,
        Net: x.net,
      }
    }),
    {
      Company: 'Counterparty Total',
      'Mark to Market': data.counterpartyTotal.markToMarket,
      'Net Option Premium': data.counterpartyTotal.netOptionPremium,
      Net: data.counterpartyTotal.net,
    },
    {
      Company: 'TOTALS',
      'Mark to Market': '',
      'Net Option Premium': '',
      Net: '',
    },
    {
      Company: 'Total M2M',
      'Mark to Market': data.grandTotal.markToMarket,
      'Net Option Premium': '',
      Net: '',
    },
    {
      Company: 'Total Net Option Premium',
      'Mark to Market': '',
      'Net Option Premium': data.grandTotal.netOptionPremium,
      Net: '',
    },
    {
      Company: 'Total NET M2M',
      'Mark to Market': '',
      'Net Option Premium': '',
      Net: data.grandTotal.net,
    },
  ]
}
class markToMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedEntity: '',
      markToMarketData: {},
      csvData: false,
    }
  }

  async componentWillMount() {
    this.props.getAllBanks()
    this.props.getAllBankCustomers()
    this.props.getAllPrivateEquities()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.banks != newProps.banks) {
      if (newProps.banks.length === 1) {
        this.setState({ selectedEntity: newProps.banks[0].id })
      }
    }
  }

  handleEntityChange = selectedEntity => {
    this.setState({ selectedEntity })
  }

  getDisplayName = entityId => {
    const entity = this.getEntity(entityId)
    return entity ? entity.displayName : ''
  }

  getEntity = entityId => {
    const { banks, bankCustomers, privateEquities } = this.props
    const entity = [...banks, ...bankCustomers, ...privateEquities].find(
      x => x.id === entityId
    )
    return entity
  }

  buttonsDisabled = () => {
    const { selectedEntity } = this.state
    const { selectedDate } = this.props

    return !selectedEntity || !selectedDate
  }

  downloadCsv = async () => {
    const { selectedEntity } = this.state
    const { selectedDate } = this.props
    const displayName = this.getDisplayName(selectedEntity)
    const report = reports['Mark to Market Summary']
    const fileName = getFileName(
      displayName,
      report.reportFileName,
      selectedDate
    ).replace('.pdf', '.csv')
    // await this.props.getMTMData(selectedEntity, { settleDate: selectedDate })
    // downloadCSV(this.props.mtmSummaryCsvData, fileName)
    await this.setState({ csvData: true })
    exportTableToCSV(fileName, 'table.csv-table')
  }

  downloadPdf = () => {
    const { selectedEntity } = this.state
    const { selectedDate } = this.props
    const displayName = this.getDisplayName(selectedEntity)
    const report = reports['Mark to Market Summary']
    const data = getRenderData(
      report,
      selectedEntity,
      selectedDate,
      null,
      displayName
    )

    this.props.generateReport(
      data.url,
      data.fileName,
      data.scale,
      data.pageLayout,
      data.margins
    )
  }

  renderMarkToMarketSummary = () => {
    if (this.props.selectedDate && this.state.selectedEntity) {
      return (
        <MarkToMarketSummary
          id={this.state.selectedEntity}
          settleDate={this.props.selectedDate}
          csvData={this.state.csvData}
        />
      )
    }
    return null
  }

  render() {
    return (
      <div className={styles.markToMarket}>
        <div className="row titleRow">
          <div className="markTitle page-title col-xs-3">Mark to Market</div>
          <div className="selectField col-xs-2">
            <PickHistoricalDate noupdate />
          </div>
          <EntitySelector
            independentEntities={mergeEntities(
              this.props.banks,
              this.props.privateEquities
            )}
            dependentEntities={this.props.bankCustomers}
            onChange={this.handleEntityChange}
            noDependent
          />
          <div className="col-xs-2">
            <RoundedButton
              disabled={this.buttonsDisabled()}
              buttonText={'PDF'}
              onClick={this.downloadPdf}
            />
            <RoundedButton
              disabled={this.buttonsDisabled()}
              buttonText={'CSV'}
              onClick={this.downloadCsv}
            />
          </div>
          <div className="col-xs-2" />
        </div>
        {this.renderMarkToMarketSummary()}
      </div>
    )
  }
}

markToMarket.propTypes = {
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  generateReport: PropTypes.func,
  banks: PropTypes.arrayOf(bankPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  getAllBanks: PropTypes.func,
  getAllBankCustomers: PropTypes.func,
  getAllPrivateEquities: PropTypes.func,
  getAllTrades: PropTypes.func,
  getAllClearingAgents: PropTypes.func,
  getAllCounterparties: PropTypes.func,
  selectedDate: PropTypes.string,
  getMTMData: PropTypes.func,
  mtmSummaryCsvData: PropTypes.array,
}

const mapDispatchToProps = {
  getAllBanks: actions.bank.getAll,
  getAllBankCustomers: actions.bankCustomer.getAll,
  getAllPrivateEquities: actions.privateEquity.getAll,
  getAllTrades: actions.tradeBlotter.getAll,
  getAllClearingAgents: actions.clearingAgent.getAll,
  getAllCounterparties: actions.counterparty.getAll,
  generateReport: actions.pdf.generate,
  getMTMData: actions.report.getMTMSummaryForEntityId,
}

const mapStateToProps = state => {
  return {
    bankCustomers: state.bankCustomer,
    banks: state.bank,
    privateEquities: state.privateEquity,
    selectedDate: state.tradeBlotter.selected.date,
    mtmSummaryCsvData: transformMTMDataForCSV(state.report.MTMSummary),
  }
}

export default build({
  component: markToMarket,
  mapDispatchToProps,
  mapStateToProps,
})
