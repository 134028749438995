import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { actions, build } from './../container_helpers'
import './Belt.styl'
import AddButton from './../UtilsComponents/AddButton/AddButton'
import { formatCurrency } from '../../helpers/formatCurrency'

class MTMSummaryBelt extends Component {
  render() {
    const { mtmSummary } = this.props
    if (_.isEmpty(mtmSummary)) return null
    return (
      <div className="black-belt">
        <div
          className="ticker-container"
          style={{ justifyContent: 'space-around' }}
        >
          <div className="summary-item">
            <div className="summary-title">As Of</div>
            <div className="summary-price">
              {moment(mtmSummary.date).format('MM/DD/YYYY')}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-title">Total m2m gain</div>
            <div className="summary-price">
              {formatCurrency(mtmSummary.markToMarketTotal, 2)}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-title">Total net option premium</div>
            <div className="summary-price">
              {formatCurrency(mtmSummary.netOptionTotal, 2)}
            </div>
          </div>
          <div className="summary-item">
            <div className="summary-title">Total net m2m</div>
            <div className="summary-price">
              {formatCurrency(mtmSummary.netTotal, 2)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MTMSummaryBelt.propTypes = {
  mtmSummary: PropTypes.shape({
    date: PropTypes.string,
    markToMarketTotal: PropTypes.number,
    netOptionTotal: PropTypes.number,
    netTotal: PropTypes.number,
  }),
  getMTMSummary: PropTypes.func,
}

const mapDispatchToProps = {
  getMTMSummary: actions.tradeBlotter.getMTMSummary,
}

const mapStateToProps = state => {
  return {
    mtmSummary: state.tradeBlotter.mtmSummary,
  }
}

export default build({
  component: MTMSummaryBelt,
  mapDispatchToProps,
  mapStateToProps,
})
