import { createActions } from 'redux-actions'

const { notification: notificationActions } = createActions({
  NOTIFICATION: {
    SUCCESS: undefined,
    ERROR: undefined,
  },
})

export default notificationActions
