import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RadioButton, RadioButtonGroup } from 'material-ui'
import { MenuItem } from '@material-ui/core'
import Select from 'react-select'
import _ from 'lodash'
import { build, actions } from '../container_helpers'
import Input from '../UtilsComponents/Input/Input'
import { productEntryPropType, enumPropType } from '../shared_prop_types'
import Dropdown from '../Dropdown/Dropdown'

class ProductEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ui: {
        hasEditedQuantity: false,
        selectedProduct: null,
      },
      errors: {},
    }
  }

  componentWillMount() {
    this.props.getAllProducts()
    this.props.getEnums('quantitytypes')
    this.props.getEnums('instrumenttypes')
    this.props.getTradeGroups()
  }

  onDateTimeChange = (dateOrTime, field) => {
    this.props.updateProductEntry({
      [field]: dateOrTime.toDate(),
    })

    this.setState({
      ui: {
        ...this.state.ui,
        [field]: dateOrTime.toDate(),
      },
    })
  }

  onAssociatedGroupIdChanged = e => {
    this.props.updateProductEntry({
      associatedGroupId: e.target.value,
    })
  }

  onInputChange = (field, event) => {
    this.props.updateProductEntry({
      [field]: event.target.value,
    })
  }
  onSelectChange = (field, event, index, value) => {
    this.props.updateProductEntry({
      [field]: value,
    })
  }

  onRadioChange = (field, event, value) => {
    this.props.updateProductEntry({
      [field]: value,
    })
  }

  onInstrumentTypeChange = (event, value) => {
    const { strikePrice } = this.props.productEntry

    this.props.updateProductEntry({
      instrumentType: value,
      strikePrice: value === 'Swap' ? 0 : strikePrice,
    })
  }

  onQuantityChanged = e => {
    this.props.updateProductEntry({
      quantity: parseInt(e.target.value, 10),
    })

    this.setState({
      ui: {
        ...this.state.ui,
        hasEditedQuantity: true,
      },
      errors: {
        ...this.state.errors,
        hasEditedQuantity: '',
      },
    })
  }

  onProductSelected = selection => {
    const product = selection.value
    const { productType } = product
    let { quantity, instrumentType } = this.props.productEntry

    if (!this.state.ui.hasEditedQuantity) quantity = product.unitsPerContract

    if (!product.isOption) instrumentType = 'Swap'
    else if (product.isOption && instrumentType === 'Swap')
      instrumentType = null

    this.props.updateProductEntry({
      ...this.props.productEntry,
      id: product.id,
      quantity,
      instrumentType,
      productType,
    })

    this.selectProduct(product)

    // this.props.getPricesFromProductId(value)
  }

  getProductOptions = () =>
    _.sortBy(this.props.products, x => x.productType).map(item => {
      return {
        value: item,
        label: `${item.id} - ${item.name}`,
      }
    })

  selectProduct = product => {
    this.setState({
      ui: {
        ...this.state.ui,
        selectedProduct: product,
      },
      errors: {
        ...this.state.errors,
        selectedProduct: '',
      },
    })
  }

  renderTradeGroups = () =>
    [
      <MenuItem key="0" value={0}>
        None
      </MenuItem>,
    ].concat(
      this.props.tradeGroups.map(item => (
        <MenuItem
          key={item.id}
          value={item.id}
        >{`${item.id} ([${item.productIds}] ${item.referenceId})`}</MenuItem>
      ))
    )

  renderInstrumentTypeItems = () => {
    const isOption =
      this.state.ui.selectedProduct && this.state.ui.selectedProduct.isOption
    return this.props.instrumentTypes.map(item => (
      <RadioButton
        key={item.id}
        value={item.id}
        label={item.displayName}
        disabled={
          (isOption && item.id === 'Swap') || (!isOption && item.id !== 'Swap')
        }
      />
    ))
  }

  renderQuantityTypeItems = () =>
    this.props.quantityTypes.map(item => {
      const displayName = this.state.ui.selectedProduct
        ? item.displayName.replace(
            'Units',
            this.state.ui.selectedProduct.unitType
          )
        : item.displayName
      return <RadioButton key={item.id} value={item.id} label={displayName} />
    })

  render() {
    const isOption = this.props.productEntry.instrumentType !== 'Swap'
    return (
      <div>
        <div className="row">
          <div className="col-xs-4">
            <Select
              options={this.getProductOptions()}
              onChange={this.onProductSelected}
            />
          </div>
        </div>
        {(this.props.productEntry.productType ||
          this.props.productEntry.id) && (
          <div>
            <div className="row">
              <div className="col-xs-4">
                <Input
                  type="number"
                  name="quantity"
                  title="Quantity"
                  defaultValue={this.props.productEntry.quantity}
                  value={this.props.productEntry.quantity}
                  onBlur={this.onQuantityChanged}
                  errorText={this.state.errors.quantity}
                />
              </div>
              <div className="col-xs-4">
                <RadioButtonGroup
                  name="quantityType"
                  valueSelected={this.props.productEntry.quantityType}
                  onChange={this.onRadioChange.bind(null, 'quantityType')}
                >
                  {this.renderQuantityTypeItems()}
                </RadioButtonGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4">
                <RadioButtonGroup
                  style={{ display: 'flex' }}
                  name="instrumentType"
                  valueSelected={this.props.productEntry.instrumentType}
                  onChange={this.onInstrumentTypeChange}
                >
                  {this.renderInstrumentTypeItems()}
                </RadioButtonGroup>
              </div>
              {isOption && (
                <div className="col-xs-4">
                  <Dropdown
                    floatingLabelText="Link To GID (optional)"
                    value={this.props.productEntry.associatedGroupId}
                    onChange={this.onAssociatedGroupIdChanged}
                    errorText={this.state.errors.associatedGroupId}
                  >
                    {this.renderTradeGroups()}
                  </Dropdown>
                </div>
              )}
            </div>
            {isOption && (
              <div className="row">
                <div className="col-xs-4">
                  <Input
                    type="number"
                    name="strikePrice"
                    title="Strike Price"
                    defaultValue={this.props.productEntry.strikePrice}
                    value={this.props.productEntry.strikePrice}
                    onBlur={this.onInputChange.bind(null, 'strikePrice')}
                    errorText={this.state.errors.strikePrice}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

ProductEntry.propTypes = {
  getAllProducts: PropTypes.func,
  getPricesFromProductId: PropTypes.func,
  getEnums: PropTypes.func,
  getTradeGroups: PropTypes.func,
  products: PropTypes.arrayOf(Object),
  productPrices: PropTypes.arrayOf(Object),
  quantityTypes: PropTypes.arrayOf(enumPropType),
  instrumentTypes: PropTypes.arrayOf(enumPropType),
  updateProductEntry: PropTypes.func,
  productEntry: productEntryPropType,
  tradeGroups: PropTypes.arrayOf(Object),
}

const mapDispatchToProps = {
  getAllProducts: actions.product.getAll,
  getEnums: actions.enum.getAll,
  getTradeGroups: actions.tradeGroup.getUnassociatedOptions,
  getPricesFromProductId: actions.productPrice.getAllByProductId,
  updateProductEntry: actions.tradeEntry.productentry.update,
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    productPrices: state.productPrice,
    quantityTypes: state.enum.quantityTypes,
    instrumentTypes: state.enum.instrumentTypes,
    tradeGroups: _.orderBy(state.tradeGroup, 'id', 'desc'),
  }
}

export default build({
  component: ProductEntry,
  mapDispatchToProps,
  mapStateToProps,
})
