import React from 'react'
import PropTypes from 'prop-types'
import * as search from 'searchtabular'

const PrimaryControls = ({
  perPage,
  columns,
  rows,
  query,
  onSearch,
  ...props
}) => (
  <div {...props}>
    <div className="search-container">
      <div>
        <search.Field
          query={query}
          columns={columns}
          rows={rows}
          onChange={onSearch}
        />
      </div>
    </div>
  </div>
)
PrimaryControls.propTypes = {
  perPage: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.objectOf(PropTypes.string),
  onSearch: PropTypes.func,
}

export default PrimaryControls
