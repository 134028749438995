import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.API_URL,
  withCredentials: true,
})

instance.interceptors.response.use(
  response => response,
  error => {
    const status = error.response.status
    if (
      window.location.pathname !== '/login' &&
      !window.location.pathname.startsWith('/change-password-with-token') &&
      !window.location.pathname.startsWith('/changepassword') &&
      (status === 401 || status === 403)
    ) {
      window.location = '/login'
    }

    throw error
  }
)

export default {
  axios: instance,
}
