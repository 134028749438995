import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import UserOption from '../UserOption/UserOption'
import { build, actions } from '../container_helpers'
import './UserInfo.styl'
import EntitySelector from '../EntitySelector/EntitySelector'

const roleMap = {
  IEAdmin: 'Admin',
  IEUser: 'User',
  TappianAdmin: 'TappianAdmin',
  TappianUser: 'TappianUser',
}
const mapRole = role => {
  const mappedRole = roleMap[role]
  return mappedRole || ''
}
const useStyles = makeStyles(theme => {
  return {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      zIndex: 10,
    },
  }
})

const showEntityName = user =>
  user && (user.role === 'TappianAdmin' || user.role === 'TappianUser')

const UserInfo = props => (
  <div className="user-info row">
    <div className="user-info-item">
      {props.user.independentEntities.length > 1 && (
        <EntitySelector
          independentEntities={[
            { displayName: 'All', id: -1 },
            ...props.user.independentEntities,
          ]}
          dependentEntities={[]}
          noDependent
          onChange={selectedEntityId => {
            props.overrideEntityId(props.user.id, selectedEntityId)
          }}
          selectedIndependentEntity={
            props.user.overrideBusinessEntityId ? (
              props.user.overrideBusinessEntityId
            ) : (
              -1
            )
          }
          styles={useStyles()}
        />
      )}
    </div>
    <div className="name user-info-item">
      <div>
        {props.user.firstName} {props.user.lastName}
      </div>
      <div>{mapRole(props.user.role)}</div>
      {showEntityName(props.user) && (
        <div>{props.user.associatedEntityName}</div>
      )}
      <UserOption deleteSession={props.deleteSession} />
    </div>
  </div>
)

UserInfo.propTypes = {
  deleteSession: PropTypes.func.isRequired,
  user: PropTypes.object,
  overrideEntityId: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  overrideEntityId: actions.user.overrideEntityId,
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
  }
}

export default build({
  component: UserInfo,
  mapDispatchToProps,
  mapStateToProps,
})
