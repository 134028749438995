import { createActions } from 'redux-actions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import _, { debounce } from 'lodash'
import store from '../../store'
import { parseError } from '../../helpers/errorUtils'

const { tradeblotter: tradeBlotterActions } = createActions({
  TRADEBLOTTER: {
    PAGINATION: {
      PAGE: undefined,
      PERPAGE: undefined,
      PAGES: undefined,
    },
    QUERY: {
      SET: undefined,
    },
    SORTED: undefined,
    COLUMNS: {
      SET: undefined,
      INITIALIZE: undefined,
    },
    TRADES: {
      SET: undefined,
      ADD: undefined,
      PUT: undefined,
      PUTMANY: undefined,
      DELETE: undefined,
      DELETEDTRADES: undefined,
      ERROR: undefined,
    },
    SELECTED: {
      SET: undefined,
    },
    MTMSUMMARY: {
      SET: undefined,
      ERROR: undefined,
    },
    COLUMNVISIBILITY: {
      OPEN: undefined,
      CLOSE: undefined,
    },
  },
})

const getEntities = () => {
  const storeElements = store.getState()
  const entities = _.unionBy(
    {},
    storeElements.bank,
    storeElements.bankCustomer,
    storeElements.clearingAgent,
    storeElements.privateEquity,
    storeElements.counterparty,
    'id'
  )
  return entities
}

tradeBlotterActions.log = (page, pages) => () => async dispatch => {
  dispatch(
    tradeBlotterActions.pagination.page({
      page: Math.min(Math.max(page, 1), pages),
    })
  )
}

tradeBlotterActions.perp = value => () => async dispatch => {
  dispatch(
    tradeBlotterActions.pagination.perpage({
      perPage: parseInt(value, 10),
    })
  )
}

tradeBlotterActions.sorting = sortingColumns => () => dispatch => {
  dispatch(tradeBlotterActions.sorted(sortingColumns))
  dispatch(tradeBlotterActions.getAll())
}

tradeBlotterActions.goToPage = data => () => dispatch => {
  dispatch(tradeBlotterActions.pagination.page(data))
  dispatch(tradeBlotterActions.getAll())
}

tradeBlotterActions.changePageSize = data => () => dispatch => {
  dispatch(tradeBlotterActions.pagination.perpage({ perPage: Number(data) }))
  dispatch(tradeBlotterActions.getAll())
}

tradeBlotterActions.querySet = query => () => dispatch => {
  dispatch(tradeBlotterActions.query.set(query))
  dispatch(tradeBlotterActions.pagination.page({ page: 1 }))
}

tradeBlotterActions.fetchQuery = () => () => dispatch => {
  dispatch(tradeBlotterActions.getAll())
}

tradeBlotterActions.initializeColumns = columnsArray => () => dispatch => {
  dispatch(tradeBlotterActions.columns.initialize(columnsArray))
}

tradeBlotterActions.fillColumns = columnsArray => () => dispatch => {
  dispatch(tradeBlotterActions.columns.set(columnsArray))
}

tradeBlotterActions.getAllByEntityId = (id, filter) => ({
  axios,
}) => async dispatch => {
  const { settleDate, tradeDate, tradeEndDate, expirationDate } = filter || {}
  const response = await axios.get(
    `api/businessEntity/${id}/trades?settleDate=${settleDate ||
      ''}&tradeDate=${tradeDate || ''}&tradeEndDate=${tradeEndDate ||
      ''}&expirationDate=${expirationDate || ''}`
  )
  dispatch(tradeBlotterActions.trades.set(response.data))
}

tradeBlotterActions.getAll = filter => ({ axios }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(showLoading())
    const tbState = filter || getState().tradeBlotter

    const queryParams = {
      asOfDate: tbState.selected.date,
      page: tbState.pagination.page,
      numPerPage: tbState.pagination.perPage,
      search: tbState.query.all || '',
    }

    // tbState.sorting is structured like...
    // {
    //   colIndex: {
    //     direction: "asc"
    //   }
    // }

    const sortingEntries = Object.entries(tbState.sorting)

    if (sortingEntries.length) {
      const sortArgs = sortingEntries[0]
      const [indx, { direction }] = sortArgs
      if (indx < tbState.columns.length) {
        const colName = tbState.columns[indx].property
        if (colName && direction) {
          queryParams.orderBy = colName
          queryParams.orderDir = direction
        }
      }
    }

    const queryParamString = Object.entries(queryParams)
      .filter(([, v]) => !!v)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')

    const response = await axios.get(`api/trade?${queryParamString}`)
    dispatch(tradeBlotterActions.trades.set(response.data.trades))

    const totalPages = response.data.total / tbState.pagination.perPage
    dispatch(
      tradeBlotterActions.pagination.pages({ pages: Math.ceil(totalPages) })
    )
  } catch (e) {
    const error = parseError(e)
    dispatch(tradeBlotterActions.trades.error(error))
  } finally {
    dispatch(hideLoading())
  }
}

tradeBlotterActions.create = data => ({ axios }) => async dispatch => {
  try {
    dispatch(showLoading())
    const response = await axios.post('api/trade', data)
    dispatch(tradeBlotterActions.trades.add(response.data))
  } catch (e) {
    const error = parseError(e)
    dispatch(tradeBlotterActions.trades.error(error))
  } finally {
    dispatch(hideLoading())
  }
}

tradeBlotterActions.update = data => ({ axios }) => async dispatch => {
  const response = await axios.put(`/trade-blotter/${data.id}`, data)
  dispatch(tradeBlotterActions.trades.put(response.data))
}

tradeBlotterActions.delete = id => ({ axios }) => async dispatch => {
  const response = await axios.delete(`api/trade/${id}`)
  dispatch(tradeBlotterActions.trades.delete(response.data))
}

tradeBlotterActions.getDeletedTrades = () => ({ axios }) => async dispatch => {
  const response = await axios.get('api/trade/deleted')
  dispatch(tradeBlotterActions.trades.deletedtrades(response.data))
}

tradeBlotterActions.updateTradeFilters = (
  data,
  getData = true
) => () => async dispatch => {
  await dispatch(tradeBlotterActions.selected.set(data))
  if (getData) {
    dispatch(tradeBlotterActions.getAll())
  }
  dispatch(tradeBlotterActions.getMTMSummary())
}

tradeBlotterActions.getMTMSummary = filter => ({ axios }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(showLoading())
    const { date, entityId } = filter || getState().tradeBlotter.selected
    const response = await axios.get(
      `api/trade/summary?asOfDate=${date || ''}&entityId=${entityId || ''}`
    )
    dispatch(tradeBlotterActions.mtmsummary.set(response.data))
  } catch (e) {
    const error = parseError(e)
    dispatch(tradeBlotterActions.mtmsummary.error(error))
  } finally {
    dispatch(hideLoading())
  }
}

export default tradeBlotterActions
