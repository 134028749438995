import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import Dropdown from '../../Dropdown/Dropdown'
import * as EntityPropType from '../../shared_prop_types'
import EditTable from '../../EditTable/EditTable'

const EntityAssociations = props => {
  const menuItems = [
    ...props.entities.map(el => (
      <MenuItem key={el.id} value={el.id}>
        {el.displayName}
      </MenuItem>
    )),
  ]

  const columns = [
    {
      field: 'id',
      title: 'Name',
      render: rowData => <span>{rowData.name}</span>,
      editComponent: p => (
        <Dropdown
          floatingLabelText="Select Entity"
          value={p.value}
          onChange={e => {
            p.rowData.name = props.entities.find(
              x => x.id === e.target.value
            ).displayName
            p.onChange(e.target.value)
          }}
          hintText="Select Entity"
        >
          {menuItems}
        </Dropdown>
      ),
    },
  ]

  return (
    <EditTable
      title="Business Entities"
      columns={columns}
      values={props.associated}
      onChange={props.onChange}
      editable={props.editable}
      options={{
        padding: 'dense',
        search: false,
        paging: false,
      }}
    />
  )
}

EntityAssociations.propTypes = {
  associated: PropTypes.arrayOf(PropTypes.object),
  entities: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

EntityAssociations.defaultProps = {
  editable: true,
}

export default EntityAssociations
