import { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { build } from '../container_helpers'

const ToastController = props => {
  const { addToast } = useToasts()

  useEffect(
    () => {
      if (!_.isEmpty(props.success))
        addToast(props.success.message, {
          appearance: 'success',
          autoDismiss: true,
        })
    },
    [props.success]
  )

  useEffect(
    () => {
      if (!_.isEmpty(props.error))
        addToast(props.error.message, {
          appearance: 'error',
          autoDismiss: true,
        })
    },
    [props.error]
  )

  return null
}

ToastController.propTypes = {
  success: PropTypes.object,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    success: state.notification.success,
    error: state.notification.error,
  }
}

export default build({
  component: ToastController,
  mapStateToProps,
})
