import { handleActions } from 'redux-actions'

const defaultState = []

const reducerMap = {
  'BANK/SET': (state, { payload }) => payload,
  'BANK/ADD': (state, { payload }) => state.concat([payload]),
  'BANK/PUT': (state, { payload }) =>
    state.map(x => (x.id === payload.id ? payload : x)),
  'BANK/REMOVE': (state, { payload }) => state.filter(x => x.id !== payload),
}

export default handleActions(reducerMap, defaultState)
