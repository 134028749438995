export const parseError = error => {
  if (!error.response || !error.response.data) {
    return 'An Unexpected Error Occurred!'
  }
  const { data } = error.response
  if (data.error) {
    return data.error
  }
  if (data.Error) {
    return data.Error
  }
  if (data.errors) {
    return Object.keys(data.errors)
      .map(key => data.errors[key])
      .join('<br/>')
  }
}
