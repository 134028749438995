import { showLoading, hideLoading } from 'react-redux-loading-bar'
import notificationActions from '../actions/notification_actions'
import { parseError } from '../../helpers/errorUtils'
import store from '../../store'
import accountsActions from '../actions/accounts_actions'

export const updateAccountsState = (dispatch, entity) => {
  const state = store.getState()
  if (state.accounts.entity && state.accounts.entity.id == entity.id) {
    dispatch(accountsActions.entity.set(entity))
  }
}

export const tryCatchAction = async (
  asyncActionFunc,
  dispatch,
  successMsg,
  errorMsg,
  errorHandlerFunc
) => {
  try {
    dispatch(showLoading())
    const result = await asyncActionFunc()
    dispatch(notificationActions.success({ message: successMsg || 'Success!' }))
    return result
  } catch (e) {
    const error = parseError(e)
    dispatch(notificationActions.error({ message: errorMsg || error }))
    if (errorHandlerFunc) errorHandlerFunc(error)
  } finally {
    dispatch(hideLoading())
  }

  return null
}

export const tryLogAction = async (
  asyncActionFunc,
  dispatch,
  successMsg,
  errorMsg,
  errorHandlerFunc
) => {
  try {
    dispatch(showLoading())
    const result = await asyncActionFunc()
    console.log(successMsg || 'Success!')
    return result
  } catch (e) {
    const error = parseError(e)
    console.error(errorMsg || 'Error!')
    if (errorHandlerFunc) errorHandlerFunc(error)
  } finally {
    dispatch(hideLoading())
  }

  return null
}
