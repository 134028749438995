import { createActions } from 'redux-actions'
import { saveAs } from 'file-saver'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const { pdf: pdfGenerationActions } = createActions({
  PDF: {
    SET: undefined,
  },
})

pdfGenerationActions.generate = (
  content,
  name,
  scale,
  pageLayout,
  margins
) => ({ axios }) => async dispatch => {
  /*
  const margins = {
    top: 80,
    botto: 60,
    left: 40,
    width: 522,
  }
  console.log(content)
  const pdf = jsPDF();
  pdf.fromHTML(
    content,
    margins.left,
    margins.top, {
      'width': margins.width
    },
    (dispose) => {
      pdf.save(name);
      dispatch(pdfGenerationActions.set({ success: true }))
    },
    margins
  ) */

  dispatch(showLoading())
  const response = await axios.post('api/pdf/generate', {
    url: window.location.origin + content,
    domain: window.location.hostname,
    scale,
    pageLayout,
    margins,
  })
  if (response.data) {
    const raw = atob(response.data)
    // have to do it this way for IE 11 support
    const pdfBuffer = new Uint8Array(new ArrayBuffer(raw.length))
    for (let i = 0; i < raw.length; i++) {
      pdfBuffer[i] = raw.charCodeAt(i)
    }
    const blob = new Blob([pdfBuffer], { type: 'application/pdf' })
    saveAs(blob, name)
    dispatch(pdfGenerationActions.set({ success: true }))
  } else {
    dispatch(pdfGenerationActions.set({ error: response }))
  }
  dispatch(hideLoading())
}

pdfGenerationActions.reset = () => () => dispatch => {
  dispatch(pdfGenerationActions.set({}))
}

export default pdfGenerationActions
