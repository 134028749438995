import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { build, actions } from '../../container_helpers'
import Confirmation from './Confirmation'
import { startEndDateRouteType } from '../../shared_prop_types'

class TradeConfirmation extends Component {
  componentWillMount() {
    const { id, startDate, endDate } = this.props.match.params
    this.props.getConfirmations(id, {
      tradeDate: startDate,
      tradeEndDate: endDate,
    })
  }

  componentWillReceiveProps(props) {
    const { id, startDate, endDate } = props.match.params
    if (
      this.props.match.params.id !== id ||
      this.props.match.params.startDate !== startDate ||
      this.props.match.params.endDate !== endDate
    ) {
      this.props.getConfirmations(id, {
        tradeDate: startDate,
        tradeEndDate: endDate,
      })
    }
  }

  render() {
    const { confirmations } = this.props
    return confirmations.map(x => (
      <Confirmation key={x.tradeGroupId} confirmation={x} />
    ))
  }
}

TradeConfirmation.propTypes = {
  confirmations: PropTypes.arrayOf(PropTypes.object),
  getConfirmations: PropTypes.func,
  match: startEndDateRouteType.isRequired,
  generateReport: PropTypes.func,
}

const mapDispatchToProps = {
  getConfirmations: actions.tradeSummary.getAllConfirmationsForEntityId,
  generateReport: actions.pdf.generate,
}

const mapStateToProps = state => {
  return {
    confirmations: state.tradeSummaries,
  }
}

export default build({
  component: TradeConfirmation,
  mapDispatchToProps,
  mapStateToProps,
})
