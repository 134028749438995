import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import LandingForm from './LandingForm'
import '../../application.styl'
import styles from './Landing.styl'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'

smoothscroll.polyfill()

class Landing extends Component {
  goToForm = e => {
    e.preventDefault()
    if (this.form) {
      this.form.scrollIntoView({ behavior: 'smooth' })
    }
  }

  render() {
    return (
      <div className={styles.landing}>
        <div className="container header">
          <div className="logo">
            <img src="/img/tappian-logo-color.svg" alt="TAPPIAN logo" />
          </div>
          <div className="links">
            <Link to="/login">
              <RoundedButton
                buttonText="SIGN IN"
                className="sign-in rounded-button"
              />
            </Link>
          </div>
        </div>
        <div className="container-fluid main-hero">
          <div className="container hero-content">
            <div className="title">
              Tappian equalizes competitive advantage in volatile markets.
            </div>
          </div>
        </div>
        <div className="spanner clearfix">
          <div className="container">
            <div className="row quick-points">
              <div className="col-sm-5">
                <div className="quick-points__point clearfix">
                  <div className="quick-points__point__img">
                    <img src="/img/landing/pump-jack.svg" alt="pump jack" />
                  </div>
                  <div className="quick-points__point__content">
                    <div className="title">Industry Veterans</div>
                    <div className="description">
                      Extensive experience working directly with producers, end
                      users, private equity firms and Banks.
                    </div>
                  </div>
                </div>
                <div className="quick-points__point clearfix">
                  <div className="quick-points__point__img">
                    <img src="/img/landing/molecule.svg" alt="molecule" />
                  </div>
                  <div className="quick-points__point__content">
                    <div className="title">Analytics & Expertise</div>
                    <div className="description">
                      Guarantee revenue and cash flows while maximizing profit
                      AND minimizing exposure.
                    </div>
                  </div>
                </div>
                <div className="quick-points__point clearfix">
                  <div className="quick-points__point__img">
                    <img
                      src="/img/landing/oil-price.svg"
                      alt="oil price chart"
                    />
                  </div>
                  <div className="quick-points__point__content">
                    <div className="title">Minimize Risk</div>
                    <div className="description">
                      Protect assets, minimize risk and generate fee income.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-offset-2 col-sm-5">
                <div className="quick-points__quick-sell">
                  <p>
                    Tappian was formed to directly benefit producers, end users,
                    private equity sponsors, and small to mid sized banks.
                    Utilizing a bespoke approach for each customer, Tappian aims
                    to protect assets and minimize risk.
                  </p>
                  <p>
                    Tappian provides analytics and expertise to stabilize
                    revenue and cash flows while maximizing profit and reducing
                    exposure to market fluctuation.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spanner spanner--grey">
          <div className="sub-hero container">
            <div className="sub-hero__img col-sm-6">
              <img
                src="/img/landing/mark-to-market-preview.png"
                alt="mark to market preview"
              />
            </div>
            <div className="sub-hero__description col-sm-offset-1 col-sm-5">
              <p>
                With extensive experience in the financial and Energy Industry
                and a proven track record, Tappian removes the advantage held by
                upstream counterparties with larger balance sheets, experienced
                traders, and sophisticated systems needed to compete in volatile
                markets.
              </p>
              <p>
                With its strategic partnerships and low overhead, Tappian is
                able to maximize much needed value, while acting in the best
                interest of its customers.
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="col-sm-12">
              <img src="/img/tappian-logo-mono-white-with-mark.svg" alt="" />
              <p>© {new Date().getFullYear()} Tappian. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
