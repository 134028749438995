import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Edit from 'material-ui/svg-icons/editor/mode-edit'
import IconButton from 'material-ui/IconButton'
import { build, actions } from '../container_helpers'
import * as EntityPropType from '../shared_prop_types'
import './EntityShows.styl'
import Addresses from '../Forms/Addresses/Addresses'
import PhoneNumbers from '../Forms/PhoneNumbers/PhoneNumbers'
import HedgeLines from '../Forms/HedgeLines/HedgeLines'
import Emails from '../Forms/Emails/Emails'
import HedgeRequirements from '../Forms/HedgeRequirements/HedgeRequirements'

class EntityShows extends Component {
  combineAttributes = (attr, index) => {
    let name = null
    const selected = this.props.selectedEntity

    if (attr[index] === 'lastName') {
      name = `${selected[attr[index - 1]]} ${selected[attr[index]]}`
    }

    return name
  }

  render() {
    return (
      <div className="selected-element">
        <div className="row">
          <div className="col-xs-10">
            <div className="detail">
              {this.props.entityType.title}{' '}
              <IconButton onClick={this.props.toggleSidebar}>
                <Edit />
              </IconButton>
            </div>
            {this.props.entityType.fields.map(iterator => (
              <div key={iterator.title}>
                <div className="sub-title-detail">
                  {iterator.title}{' '}
                  {iterator.title === 'Associated to' &&
                  this.props.associatedEntity ? (
                    this.props.entityType.associatedEntityType
                  ) : null}
                </div>
                {iterator.attr.map((attribute, index) => {
                  let value
                  if (
                    iterator.title === 'Name' &&
                    this.props.entityType.title === 'Users'
                  ) {
                    value = this.combineAttributes(iterator.attr, index)
                  } else if (
                    iterator.title === 'Associated to' &&
                    this.props.associatedEntity
                  ) {
                    value =
                      this.props.associatedEntity.name ||
                      this.props.associatedEntity.name
                  } else {
                    value = this.props.selectedEntity[attribute]
                  }

                  if (_.isBoolean(value)) {
                    value = value ? 'Yes' : 'No'
                  } else if (moment(value, moment.ISO_8601, true).isValid()) {
                    value = moment(value).format('MM/DD/YYYY')
                  }

                  if (iterator.type) {
                    if (iterator.type === 'img') {
                      let className = ''
                      if (iterator.title === 'Logo') {
                        value = `${process.env.API_URL}/${value}`
                        className = 'logo-inner'
                      }

                      return (
                        <img
                          key={iterator.title + attribute}
                          src={value}
                          alt={attribute}
                          className={className}
                        />
                      )
                    } else if (iterator.type === 'Addresses') {
                      return (
                        <Addresses
                          addresses={value}
                          editable={false}
                          key={iterator.title + attribute}
                        />
                      )
                    } else if (iterator.type === 'PhoneNumbers') {
                      return (
                        <PhoneNumbers
                          phoneNumbers={value}
                          editable={false}
                          key={iterator.title + attribute}
                        />
                      )
                    } else if (iterator.type === 'Hedgelines') {
                      return (
                        <HedgeLines
                          hedgeLines={value}
                          editable={false}
                          key={iterator.title + attribute}
                        />
                      )
                    } else if (iterator.type === 'Emails') {
                      return (
                        <Emails
                          emails={value}
                          editable={false}
                          key={iterator.title + attribute}
                        />
                      )
                    } else if (iterator.type === 'HedgeRequirements') {
                      return (
                        <HedgeRequirements
                          hedgeRequirements={value}
                          editable={false}
                          key={iterator.title + attribute}
                        />
                      )
                    }
                  }

                  return (
                    value && <p key={iterator.title + attribute}>{value}</p>
                  )
                })}
              </div>
            ))}
          </div>
          {/* <div className="col-xs-2 col-offset-xs-7">
            
          </div> */}
        </div>
      </div>
    )
  }
}

EntityShows.propTypes = {
  toggleSidebar: PropTypes.func,
  associatedEntity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
  entityType: EntityPropType.entityTypePropType,
  selectedEntity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
}

const mapDispatchToProps = {
  toggleSidebar: actions.sidebar.toggle,
}

export default build({
  component: EntityShows,
  mapDispatchToProps,
})
