import React from 'react'
import PropTypes from 'prop-types'
import { RaisedButton } from 'material-ui'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import '../SidebarForm.styl'
import { addButtonStyle, addButtonLabelStyle } from '../../../styles'
import { bankPropType, counterpartyPropType } from '../../shared_prop_types'
import { NUMBER_REGEX } from '../../../constants'
import { validateCounterpartyForm } from '../../../helpers/formsValidators'
import HedgeLines from '../HedgeLines/HedgeLines'
import Addresses from '../Addresses/Addresses'
import PhoneNumbers from '../PhoneNumbers/PhoneNumbers'
import Emails from '../Emails/Emails'
import EntityFormWrapper from '../Entity/EntityFormWrapper'
import { useEntityForm } from '../Entity/entityHooks'

const onlyNumbers = event => {
  const pattern = new RegExp(NUMBER_REGEX)
  const testResult = pattern.test(event.key)
  if (!testResult) {
    event.preventDefault()
  }
}

const CounterpartyForm = props => {
  const defaults = {
    name: '',
    displayName: '',
    bankId: props.bankId || '',
    isActive: false,
    addresses: [],
    phoneNumbers: [],
    emails: [],
    hedgeLines: [],
    permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
  }

  const {
    selected,
    getCounterparty: getEntity,
    updateCounterparty: updateEntity,
    createCounterparty: createEntity,
  } = props

  const [newEntity, setEntity, errors, setErrors, submit] = useEntityForm({
    selected,
    defaults,
    getEntity,
    updateEntity,
    createEntity,
  })

  const validateForm = () => {
    const validationResult = validateCounterpartyForm(newEntity, errors)
    setErrors(validationResult.errors)

    if (validationResult.valid) {
      submit()
    }
  }

  const renderDetails = () => (
    <div className="create">
      <div className="sidebar-title">
        {!_.isEmpty(selected) ? 'Edit Counterparty' : 'Add Counterparty'}
      </div>
      <div className="create-form">
        <div className="entity-details">
          {!_.isEmpty(props.selected) && (
            <div className="row">
              <div className="col-xs-2">
                <Button
                  onClick={() => {
                    if (
                      confirm(
                        `Are you sure you want to DELETE all of this entity's trades?`
                      )
                    )
                      props.clearTrades(newEntity.id)
                  }}
                >
                  Clear Trades
                </Button>
              </div>
            </div>
          )}
          <div className="create-title">Counterparty Details</div>
          <div className="row">
            <Input
              className="col-xs-3"
              name="name"
              title="Counterparty Name"
              defaultValue={newEntity.name}
              onChange={e => {
                setEntity({ name: e.target.value })
              }}
              errorText={errors.name}
            />
            <Input
              className="col-xs-3"
              name="displayName"
              title="Display Name"
              defaultValue={newEntity.displayName}
              onChange={e => {
                setEntity({ displayName: e.target.value })
              }}
              errorText={errors.displayName}
            />
          </div>

          <br />
          <PhoneNumbers
            phoneNumbers={newEntity.phoneNumbers}
            onChange={phoneNumbers => {
              setEntity({ phoneNumbers })
            }}
          />
          <br />
          <Emails
            emails={newEntity.emails}
            onChange={emails => {
              setEntity({ emails })
            }}
          />
          <br />
          <Addresses
            addresses={newEntity.addresses}
            onChange={addresses => {
              setEntity({ addresses })
            }}
          />
          <br />
          <HedgeLines
            hedgeLines={newEntity.hedgeLines}
            onChange={hedgeLines => {
              setEntity({ hedgeLines })
            }}
          />
          <br />
        </div>
        <div className="add-button-bg">
          <RaisedButton
            primary
            label={!_.isEmpty(selected) ? 'Save' : 'Add'}
            onClick={() => {
              validateForm()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
        </div>
      </div>
    </div>
  )

  return (
    <EntityFormWrapper
      newEntity={newEntity}
      setEntity={setEntity}
      render={renderDetails}
    />
  )
}

CounterpartyForm.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  createCounterparty: PropTypes.func.isRequired,
  updateCounterparty: PropTypes.func.isRequired,
  getCounterparty: PropTypes.func.isRequired,
  clearTrades: PropTypes.func.isRequired,
  selected: counterpartyPropType,
  bankId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const mapDispatchToProps = {
  createCounterparty: actions.counterparty.create,
  updateCounterparty: actions.counterparty.update,
  getCounterparty: actions.counterparty.get,
  toggleSidebar: actions.sidebar.toggle,
  clearTrades: actions.entity.clearTrades,
}

export default build({
  component: CounterpartyForm,
  mapDispatchToProps,
})
