import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { build } from '../../container_helpers'
import * as EntityPropType from '../../shared_prop_types'
import EditTable from '../../EditTable/EditTable'

const PhoneNumbers = props => {
  const columns = [
    {
      field: 'type',
      title: 'Type',
      lookup: props.phoneNumberTypes.reduce((accum, val) => {
        accum[val.id] = val.displayName
        return accum
      }, {}),
    },
    { field: 'number', title: 'Number' },
    { field: 'note', title: 'Note' },
  ]

  return (
    <EditTable
      title="Contact Numbers"
      columns={columns}
      values={props.phoneNumbers}
      onChange={props.onChange}
      editable={props.editable}
      options={{
        padding: 'dense',
        search: false,
        paging: false,
        // paging: addresses && addresses.length && addresses.length > 5,
      }}
    />
  )
}

PhoneNumbers.propTypes = {
  phoneNumbers: PropTypes.arrayOf(EntityPropType.addressType),
  phoneNumberTypes: PropTypes.arrayOf(EntityPropType.enumPropType),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    phoneNumberTypes: state.enum.phoneNumberTypes,
  }
}

PhoneNumbers.defaultProps = {
  editable: true,
}

export default build({
  component: PhoneNumbers,
  mapStateToProps,
})
