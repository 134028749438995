import { createActions } from 'redux-actions'

const { commodityprice: commodityPriceActions } = createActions({
  COMMODITYPRICE: {
    SET: undefined,
  },
})

commodityPriceActions.getAll = data => ({ axios }) => async dispatch => {
  const response = await axios.get('api/commodity-price', data)
  dispatch(commodityPriceActions.set(response.data))
}

export default commodityPriceActions
