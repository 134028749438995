import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { actions, build } from '../../container_helpers'
import SubTable from './SubTable'
import styles from './MarkToMarketSummary.styl'
import { bankPropType } from '../../shared_prop_types'
import { currencyDiv } from '../../../helpers/formatCurrency'

class MarkToMarketSummary extends Component {
  constructor() {
    super()
    this.state = {
      firstUpdate: false,
    }
  }
  componentWillMount() {
    const { id, settleDate } = this.props
    this.props.getAllBanks()
    this.props.getMTMData(id, { settleDate })
  }

  componentWillReceiveProps(props) {
    if (
      this.props.id !== props.id ||
      this.props.settleDate !== props.settleDate
    ) {
      const { id, settleDate } = props
      this.props.getMTMData(id, { settleDate })
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { id, reportData } = this.props
    const entity = this.props.banks.find(x => x.id == id)

    return !(!entity || _.isEmpty(reportData))
  }

  renderReport(csvData) {
    const { id, reportData, settleDate } = this.props
    const entity = this.props.banks.find(x => x.id == id)

    if (!entity || _.isEmpty(reportData)) return null

    const logo = `${process.env.API_URL}/${entity.logo}`

    const { grandTotal, bankCustomers, counterparties } = reportData
    return (
      <div className={styles.markToMarketSummary} align="center">
        <table className={`summary-table ${csvData && 'csv-table'}`}>
          <thead>
            <tr className="summary-header-1">
              <th colSpan="6">
                <div>
                  <img
                    className="tappian-report-logo"
                    src="/img/tappian-logo-color.svg"
                    alt="tappian logo"
                  />
                  <h4>MARK TO MARKET SUMMARY</h4>
                  <img className="report-logo" src={logo} alt="bank logo" />
                </div>
              </th>
            </tr>
            <tr className="summary-header-2">
              <th colSpan="3" align="center">
                <h4>{moment(settleDate).format('MM/DD/YYYY')}</h4>
              </th>
              <th>
                <h4>Mark to Market</h4>
              </th>
              <th>
                <h4>Net Option Premium</h4>
              </th>
              <th>
                <h4>Net</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            {Boolean(bankCustomers.length) && (
              <SubTable
                bank={entity.displayName}
                tableTitle={'Customers'}
                items={bankCustomers}
                totals={reportData.bankCustomerTotal}
                csvData={csvData}
              />
            )}

            {Boolean(counterparties.length) && (
              <SubTable
                bank={entity.displayName}
                tableTitle={'Counterparties'}
                items={counterparties}
                totals={reportData.counterpartyTotal}
                csvData={csvData}
              />
            )}
          </tbody>
          <tfoot>
            <tr className="sub-header">
              <th colSpan="3">
                <h4>TOTALS</h4>
              </th>
              <th colSpan="3" />
            </tr>
            <tr>
              <th colSpan="3" className="total-title">
                Total M2M
              </th>
              <td>
                {csvData ? (
                  grandTotal.markToMarket
                ) : (
                  currencyDiv(grandTotal.markToMarket)
                )}
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <th colSpan="3" className="total-title">
                Total Net Option Premium
              </th>
              <td />
              <td>
                {csvData ? (
                  grandTotal.netOptionPremium
                ) : (
                  currencyDiv(grandTotal.netOptionPremium)
                )}
              </td>
              <td />
            </tr>
            <tr>
              <th colSpan="3" className="total-title">
                Total NET M2M
              </th>
              <td />
              <td />
              <td>{csvData ? grandTotal.net : currencyDiv(grandTotal.net)}</td>
            </tr>
            <tr>
              <th colSpan="6" className="footer-info">
                The above mark to market numbers reflect the value of{' '}
                {entity.displayName} positions if they were liquidated at the
                settlement price on the date listed above.
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderReport()}
        {this.props.csvData && this.renderReport(true)}
      </div>
    )
  }
}

MarkToMarketSummary.propTypes = {
  getAllBanks: PropTypes.func,
  banks: PropTypes.arrayOf(bankPropType),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  settleDate: PropTypes.string.isRequired,
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
  getMTMData: PropTypes.func,
  reportData: PropTypes.object,
}

const mapDispatchToProps = {
  getAllBanks: actions.bank.getAll,
  getMTMData: actions.report.getMTMSummaryForEntityId,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    reportData: state.report.MTMSummary,
  }
}

export default build({
  component: MarkToMarketSummary,
  mapDispatchToProps,
  mapStateToProps,
})
