import { createActions } from 'redux-actions'

const { tradegroup: tradeGroupActions } = createActions({
  TRADEGROUP: {
    SET: undefined,
  },
})

tradeGroupActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/api/tradegroup')
  dispatch(tradeGroupActions.set(response.data))
}

tradeGroupActions.getUnassociatedOptions = entityId => ({ axios }) => async dispatch => {
  const response = await axios.get(`/api/tradegroup/unassociated?entityId=${entityId || ''}`)
  dispatch(tradeGroupActions.set(response.data))
}

export default tradeGroupActions
