import { handleActions } from 'redux-actions'

const defaultState = []

const reducerMap = {
  'USER/SET': (state, { payload }) => payload,
  'USER/ADD': (state, { payload }) => state.concat([payload]),
  'USER/PUT': (state, { payload }) =>
    state.map(m => (m.id === payload.id ? payload : m)),
  'USER/REMOVE': (state, { payload }) => state.filter(x => x.id !== payload),
}

export default handleActions(reducerMap, defaultState)
