import React from 'react'
import PropTypes from 'prop-types'
import { RaisedButton, Checkbox } from 'material-ui'
import { Button, MenuItem } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import '../SidebarForm.styl'
import {
  addButtonStyle,
  addButtonLabelStyle,
  checkboxStyle,
  dropdownInputStyles,
} from '../../../styles'
import {
  bankPropType,
  bankCustomerPropType,
  userPropType,
  enumPropType,
} from '../../shared_prop_types'
import { validateBankCustomerForm } from '../../../helpers/formsValidators'
import Dropdown from '../../Dropdown/Dropdown'
import Addresses from '../Addresses/Addresses'
import PhoneNumbers from '../PhoneNumbers/PhoneNumbers'
import HedgeLines from '../HedgeLines/HedgeLines'
import Emails from '../Emails/Emails'
import HedgeRequirements from '../HedgeRequirements/HedgeRequirements'
import EntityFormWrapper from '../Entity/EntityFormWrapper'
import { useEntityForm } from '../Entity/entityHooks'

const BankCustomerForm = props => {
  const defaults = {
    name: '',
    displayName: '',
    bankId: props.bankId || '',
    leiNumber: '',
    accountNumber: '',
    routingNumber: '',
    attentionPerson: '',
    relationshipManager: '',
    isActive: false,
    isExistingCustomer: false,
    defaultUserId: '',
    hedgeLine: 0,
    addresses: [],
    phoneNumbers: [],
    hedgeLines: [],
    emails: [],
    hedgeRequirements: [],
    recommendedAction: 'None',
    potentialHedgeDocumentType: 'None',
    ownership: 0,
    leiStatus: 'Inactive',
    leiWeblink: '',
    leiRenewalDate: null,
    isdaExecutionDate: null,
    maturityDate: null,
    permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
  }

  const {
    selected,
    getBankCustomer: getEntity,
    updateBankCustomer: updateEntity,
    createBankCustomer: createEntity,
    users,
  } = props

  const [newEntity, setEntity, errors, setErrors, submit] = useEntityForm({
    selected,
    defaults,
    getEntity,
    updateEntity,
    createEntity,
  })

  const validateForm = () => {
    const validationResult = validateBankCustomerForm(newEntity, errors)
    setErrors(validationResult.errors)

    if (validationResult.valid) {
      submit()
    }
  }

  const renderUserDropdown = () => {
    if (!selected) return null

    const filteredUsers = users.filter(x =>
      _.some(x.associatedEntities, entity => entity.id === selected.id)
    )
    const items = [
      <MenuItem key="" value="">
        (None)
      </MenuItem>,
    ]

    return (
      <Dropdown
        floatingLabelText="Default User (Optional)"
        value={newEntity.defaultUserId}
        onChange={e => setEntity({ defaultUserId: e.target.value })}
        errorText={errors.defaultUserId}
        hintText="Select a Default User (Optional)"
      >
        {items.concat(
          filteredUsers.map(name => (
            <MenuItem key={name.id} value={name.id}>
              {name.fullName}
            </MenuItem>
          ))
        )}
      </Dropdown>
    )
  }

  const renderHedgeDocumentType = () => (
    <Dropdown
      floatingLabelText="Hedge Document Type"
      value={newEntity.potentialHedgeDocumentType}
      styles={dropdownInputStyles}
      onChange={e => {
        setEntity({ potentialHedgeDocumentType: e.target.value })
      }}
      hintText="Select a Recommended Action (Optional)"
    >
      {props.hedgeDocumentTypes.map(x => (
        <MenuItem key={x.id} value={x.id}>
          {x.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )
  const renderRecommendedActions = () => (
    <Dropdown
      floatingLabelText="Recommended Action"
      value={newEntity.recommendedAction}
      className="col-xs-3"
      styles={dropdownInputStyles}
      onChange={e => {
        setEntity({ recommendedAction: e.target.value })
      }}
      hintText="Select a Recommended Action (Optional)"
    >
      {props.recommendedActions.map(x => (
        <MenuItem key={x.id} value={x.id}>
          {x.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )

  const renderLeiStatus = () => (
    <Dropdown
      styles={dropdownInputStyles}
      floatingLabelText="LEI Status"
      value={newEntity.leiStatus}
      onChange={e => {
        setEntity({ leiStatus: e.target.value })
      }}
      hintText="Select an LEI Status (Optional)"
    >
      {props.leiStatuses.map(x => (
        <MenuItem key={x.id} value={x.id}>
          {x.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )

  const renderDetails = () => (
    <div className="create">
      <div className="sidebar-title">
        {!_.isEmpty(props.selected) ? (
          'Edit Bank Customer'
        ) : (
          'Add Bank Customer'
        )}
      </div>
      <div className="create-form">
        <div className="entity-details">
          {!_.isEmpty(props.selected) && (
            <div className="row">
              <div className="col-xs-2">
                <Button
                  onClick={() => {
                    if (
                      confirm(
                        `Are you sure you want to DELETE all of this entity's trades?`
                      )
                    )
                      props.clearTrades(newEntity.id)
                  }}
                >
                  Clear Trades
                </Button>
              </div>
            </div>
          )}
          <div className="create-title">Bank Customer Details</div>
          <div className="row">
            <Input
              name="name"
              title="Bank Customer Name"
              defaultValue={newEntity.name}
              className="col-xs-3"
              onChange={e => {
                setEntity({ name: e.target.value })
              }}
              errorText={errors.name}
            />

            <Input
              name="displayName"
              title="Display Name"
              defaultValue={newEntity.displayName}
              className="col-xs-3"
              onChange={e => {
                setEntity({ displayName: e.target.value })
              }}
              errorText={errors.displayName}
            />

            {renderRecommendedActions()}
          </div>

          <br />
          <div className="row">
            <Checkbox
              label="Is Active"
              style={checkboxStyle}
              className="col-xs-3"
              onCheck={(e, isChecked) => {
                setEntity({ isActive: isChecked })
              }}
              checked={newEntity.isActive}
            />

            <Checkbox
              label="Is Existing Customer"
              style={checkboxStyle}
              className="col-xs-3"
              onCheck={(e, isChecked) => {
                setEntity({ isExistingCustomer: isChecked })
              }}
              checked={newEntity.isExistingCustomer}
            />

            <Checkbox
              label="Is Compliant"
              style={checkboxStyle}
              className="col-xs-3"
              onCheck={(e, isChecked) => {
                setEntity({ isCompliant: isChecked })
              }}
              checked={newEntity.isCompliant}
            />
          </div>
          <div className="row">
            <Input
              name="ownership"
              title="Ownership"
              defaultValue={newEntity.ownership}
              className="col-xs-3"
              onChange={e => {
                setEntity({ ownership: e.target.value })
              }}
              errorText={errors.ownership}
            />

            <DatePicker
              name="maturityDate"
              label="Maturity Date"
              format="MM/DD/YYYY"
              className="col-xs-3"
              value={newEntity.maturityDate}
              onChange={date => {
                setEntity({ maturityDate: date })
              }}
              autoOk
              clearable
              showTodayButton
            />
          </div>

          <div className="row">
            <Input
              name="leiNumber"
              title="LEI Number"
              errorText={errors.leiNumber}
              className="col-xs-3"
              defaultValue={newEntity.leiNumber}
              onChange={e => {
                setEntity({ leiNumber: e.target.value })
              }}
            />

            {renderLeiStatus()}

            <DatePicker
              name="leiRenewalDate"
              label="LEI Renewal Date"
              format="MM/DD/YYYY"
              value={newEntity.leiRenewalDate}
              className="col-xs-3"
              onChange={date => {
                setEntity({ leiRenewalDate: date })
              }}
              autoOk
              clearable
              showTodayButton
            />

            <Input
              name="leiWeblink"
              title="LEI Weblink"
              errorText={errors.leiWeblink}
              defaultValue={newEntity.leiWeblink}
              className="col-xs-3"
              onChange={e => {
                setEntity({ leiWeblink: e.target.value })
              }}
            />
          </div>
          <div className="row">
            <Input
              name="accountNumber"
              title="Account Number (Optional)"
              className="col-xs-3"
              defaultValue={newEntity.accountNumber}
              onChange={e => {
                setEntity({ accountNumber: e.target.value })
              }}
            />

            <Input
              name="routingNumber"
              title="Routing Number (Optional)"
              className="col-xs-3"
              defaultValue={newEntity.routingNumber}
              onChange={e => {
                setEntity({ routingNumber: e.target.value })
              }}
            />

            <DatePicker
              name="isdaExecutionDate"
              label="ISDA Execution Date"
              format="MM/DD/YYYY"
              className="col-xs-3"
              value={newEntity.isdaExecutionDate}
              onChange={date => {
                setEntity({ isdaExecutionDate: date })
              }}
              autoOk
              clearable
              showTodayButton
            />

            <Input
              name="attentionPerson"
              title="Attention Person"
              className="col-xs-3"
              defaultValue={newEntity.attentionPerson}
              onChange={e => {
                setEntity({ attentionPerson: e.target.value })
              }}
            />
          </div>

          <div className="row">
            <Input
              name="relationshipManager"
              title="Relationship Manager"
              defaultValue={newEntity.relationshipManager}
              className="col-xs-3"
              onChange={e => {
                setEntity({
                  relationshipManager: e.target.value,
                })
              }}
            />

            {renderUserDropdown()}
          </div>
          <br />
          <PhoneNumbers
            phoneNumbers={newEntity.phoneNumbers}
            onChange={phoneNumbers => {
              setEntity({ phoneNumbers })
            }}
          />
          <br />
          <Emails
            emails={newEntity.emails}
            onChange={emails => {
              setEntity({ emails })
            }}
          />
          <br />
          <Addresses
            addresses={newEntity.addresses}
            onChange={addresses => {
              setEntity({ addresses })
            }}
          />
          <br />
          <HedgeLines
            hedgeLines={newEntity.hedgeLines}
            onChange={hedgeLines => {
              setEntity({ hedgeLines })
            }}
          />
          <br />
          <HedgeRequirements
            hedgeRequirements={newEntity.hedgeRequirements}
            onChange={hedgeRequirements => {
              setEntity({ hedgeRequirements })
            }}
          />
          <div className="row">{renderHedgeDocumentType()}</div>

          <div className="add-button-bg">
            <RaisedButton
              primary
              label={!_.isEmpty(props.selected) ? 'Save' : 'Add'}
              onClick={() => {
                validateForm()
              }}
              style={addButtonStyle}
              buttonStyle={addButtonStyle}
              labelStyle={addButtonLabelStyle}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <EntityFormWrapper
      newEntity={newEntity}
      setEntity={setEntity}
      render={renderDetails}
    />
  )
}

BankCustomerForm.propTypes = {
  createBankCustomer: PropTypes.func.isRequired,
  updateBankCustomer: PropTypes.func.isRequired,
  getBankCustomer: PropTypes.func.isRequired,
  clearTrades: PropTypes.func.isRequired,
  selected: bankCustomerPropType,
  banks: PropTypes.arrayOf(bankPropType),
  users: PropTypes.arrayOf(userPropType),
  bankId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recommendedActions: PropTypes.arrayOf(enumPropType),
  hedgeDocumentTypes: PropTypes.arrayOf(enumPropType),
  leiStatuses: PropTypes.arrayOf(enumPropType),
}

const mapDispatchToProps = {
  createBankCustomer: actions.bankCustomer.create,
  updateBankCustomer: actions.bankCustomer.update,
  getBankCustomer: actions.bankCustomer.get,
  clearTrades: actions.entity.clearTrades,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    users: state.user,
    recommendedActions: state.enum.recommendedActions,
    hedgeDocumentTypes: state.enum.hedgeDocumentTypes,
    leiStatuses: state.enum.leiStatuses,
  }
}

export default build({
  component: BankCustomerForm,
  mapStateToProps,
  mapDispatchToProps,
})
