import { createActions } from 'redux-actions'
import tradeBlotterActions from './trade_blotter_actions'
import { tryCatchAction } from '../storeHelpers/reduxActionHelpers'

const { tradeedit: tradeEditActions } = createActions({
  TRADEEDIT: {
    CLOSE: undefined,
    SELECT: undefined,
  },
})

tradeEditActions.submit = data => ({ axios }) => async dispatch =>
  tryCatchAction(
    async () => {
      await axios.put(`api/trade`, data)
      dispatch(tradeBlotterActions.getAll())
      dispatch(tradeBlotterActions.getMTMSummary())
      // dispatch(tradeBlotterActions.trades.putmany(response.data.updated))
      // dispatch(tradeBlotterActions.trades.deletemany(response.data.deleted))
    },
    dispatch,
    'Trade(s) Updated Successfully.'
  )

export default tradeEditActions
