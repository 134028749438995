import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton } from 'material-ui'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import '../SidebarForm.styl'
import { addButtonStyle, addButtonLabelStyle } from '../../../styles'
import { privateEquityPropType } from '../../shared_prop_types'
import { NUMBER_REGEX } from '../../../constants'
import { validatePrivateEquityForm } from '../../../helpers/formsValidators'

class PrivateEquityForm extends Component {
  static onlyNumbers(event) {
    const pattern = new RegExp(NUMBER_REGEX)
    if (!pattern.test(event.key)) {
      event.preventDefault()
    }
  }

  constructor(props) {
    super(props)
    const selected = this.props.selected
    this.state = {
      newPrivateEquity: {
        name: selected ? selected.name : '',
        displayName: selected ? selected.displayName : '',
        abbreviation: selected ? selected.abbreviation : '',
        address: selected ? selected.address : '',
        city: selected ? selected.city : '',
        faxNumber: selected ? selected.faxNumber : '',
        leiNumber: selected ? selected.leiNumber : '',
        monthlyFee: selected ? selected.monthlyFee : '',
        phoneNumber: selected ? selected.phoneNumber : '',
        state: selected ? selected.state : '',
        zip: selected ? selected.zip : '',
        swapDataRepositoryName: selected ? selected.swapDataRepositoryName : '',
      },
      errors: {},
    }
  }

  validateForm() {
    const validationResult = validatePrivateEquityForm(
      this.state.newPrivateEquity,
      this.state.errors
    )
    this.setState({ ...this.state.errors, errors: validationResult.errors })
    if (validationResult.valid) {
      this.createPrivateEquity()
    }
  }

  savePrivateEquity = privateEquityCustObj => {
    if (Object.values(privateEquityCustObj)[0] !== '') {
      const upError = this.state.errors
      const propertyName = Object.keys(privateEquityCustObj)[0]
      upError[propertyName] = ''
      this.setState(upError)
    }
    const upPrivateEquityCust = this.state.newPrivateEquity
    this.setState({
      newPrivateEquity: { ...upPrivateEquityCust, ...privateEquityCustObj },
    })
  }

  createPrivateEquity = async () => {
    if (!_.isEmpty(this.props.selected)) {
      const upPrivateEquity = this.state.newPrivateEquity
      upPrivateEquity.id = this.props.selected.id
      await this.props.updatePrivateEquities(upPrivateEquity)
    } else {
      this.props.createPrivateEquities(this.state.newPrivateEquity)
    }
    this.props.toggleSidebar()
  }

  render() {
    return (
      <div className="create">
        <div className="sidebar-title">
          {!_.isEmpty(this.props.selected) ? (
            'Edit Private Equity'
          ) : (
            'Add Private Equity'
          )}
        </div>
        <div className="create-form">
          <div className="entity-details">
            {!_.isEmpty(this.props.selected) && (
              <div className="row">
                <div className="col-xs-2">
                  <Button
                    onClick={() => {
                      if (
                        confirm(
                          `Are you sure you want to DELETE all of this entity's trades?`
                        )
                      )
                        this.props.clearTrades(newPrivateEquity.id)
                    }}
                  >
                    Clear Trades
                  </Button>
                </div>
              </div>
            )}
            <div className="create-title">Private Equity Details</div>
            <div className="row">
              <Input
                className="col-xs-3"
                name="name"
                title="Bank Name"
                defaultValue={this.state.newPrivateEquity.name}
                onChange={e => {
                  this.savePrivateEquity({ name: e.target.value })
                }}
                errorText={this.state.errors.name}
              />
              <Input
                className="col-xs-3"
                name="displayName"
                title="Display Name"
                defaultValue={this.state.newPrivateEquity.displayName}
                onChange={e => {
                  this.savePrivateEquity({ displayName: e.target.value })
                }}
                errorText={this.state.errors.displayName}
              />
              <Input
                className="col-xs-3"
                name="abbrevation"
                title="Abbreviation"
                defaultValue={this.state.newPrivateEquity.abbreviation}
                onChange={e => {
                  this.savePrivateEquity({ abbreviation: e.target.value })
                }}
                errorText={this.state.errors.abbreviation}
              />
            </div>
            <div className="row">
              <Input
                name="phoneNumber"
                title="Phone Number (Optional)"
                defaultValue={this.state.newPrivateEquity.phoneNumber}
                onKeyPress={PrivateEquityForm.onlyNumbers}
                errorText={this.state.errors.phoneNumber}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ phoneNumber: e.target.value })
                }}
              />
              <Input
                name="faxNumber"
                title="Fax Number (Optional)"
                defaultValue={this.state.newPrivateEquity.faxNumber}
                onKeyPress={PrivateEquityForm.onlyNumbers}
                errorText={this.state.errors.faxNumber}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ faxNumber: e.target.value })
                }}
              />
            </div>

            <div className="row">
              <Input
                name="address"
                title="Address (Optional)"
                defaultValue={this.state.newPrivateEquity.address}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ address: e.target.value })
                }}
              />
              <Input
                name="city"
                title="City (Optional)"
                defaultValue={this.state.newPrivateEquity.city}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ city: e.target.value })
                }}
              />
              <Input
                name="state"
                title="State (Optional)"
                defaultValue={this.state.newPrivateEquity.state}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ state: e.target.value })
                }}
              />
              <Input
                name="zip"
                title="Zip Code (Optional)"
                defaultValue={this.state.newPrivateEquity.zip}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ zip: e.target.value })
                }}
              />
            </div>

            <div className="row">
              <Input
                name="leiNumber"
                title="LEI Number"
                errorText={this.state.errors.leiNumber}
                defaultValue={this.state.newPrivateEquity.leiNumber}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ leiNumber: e.target.value })
                }}
              />
              <Input
                name="monthlyFee"
                title="Monthly Fee"
                errorText={this.state.errors.monthlyFee}
                defaultValue={this.state.newPrivateEquity.monthlyFee}
                className="col-xs-3"
                onChange={e => {
                  this.savePrivateEquity({ monthlyFee: e.target.value })
                }}
              />

              <Input
                name="swapDataDepositoryName"
                title="Swap Data Depository Name"
                className="col-xs-3"
                defaultValue={this.state.swapDataRepositoryName}
                onChange={e => {
                  this.savePrivateEquity({
                    swapDataRepositoryName: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="add-button-bg">
            <RaisedButton
              primary
              label={!_.isEmpty(this.props.selected) ? 'Save' : 'Add'}
              onClick={() => {
                this.validateForm()
              }}
              style={addButtonStyle}
              buttonStyle={addButtonStyle}
              labelStyle={addButtonLabelStyle}
            />
          </div>
        </div>
      </div>
    )
  }
}

PrivateEquityForm.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  getAllPrivateEquities: PropTypes.func.isRequired,
  createPrivateEquities: PropTypes.func.isRequired,
  updatePrivateEquities: PropTypes.func.isRequired,
  clearTrades: PropTypes.func.isRequired,
  selected: privateEquityPropType,
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  privateEquitiyId: PropTypes.string,
}

const mapDispatchToProps = {
  createPrivateEquities: actions.privateEquity.create,
  getAllPrivateEquities: actions.privateEquity.getAll,
  updatePrivateEquities: actions.privateEquity.update,
  toggleSidebar: actions.sidebar.toggle,
  clearTrades: actions.entity.clearTrades,
}

const mapStateToProps = state => {
  return {
    privateEquities: state.privateEquity,
    sidebar: state.sidebar,
  }
}

export default build({
  component: PrivateEquityForm,
  mapStateToProps,
  mapDispatchToProps,
})
