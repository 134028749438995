import { handleActions } from 'redux-actions'
import { filter, cloneDeep, unionBy, merge } from 'lodash'

const getPerPage = () => {
  try {
    const perPage = JSON.parse(localStorage.getItem('perPage'))
    return perPage || 10
  } catch (e) {
    return 10
  }
}


const defaultState = {
  pagination: {
    page: 1,
    perPage: getPerPage(),
    pages: 1,
  },
  query: {},
  sorting: {},
  columns: [],
  trades: [],
  errors: {
    trades: '',
    mtmSummary: '',
  },
  selected: {
    date: '',
  },
  mtmSummary: {},
  columnVisibility: {
    isOpen: false,
  },
}

const reducerMap = {
  'TRADEBLOTTER/QUERY/SET': (state, { payload }) => {
    return {
      ...state,
      query: { ...state.query, ...payload },
    }
  },
  'TRADEBLOTTER/PAGINATION/PAGE': (state, { payload }) => {
    return {
      ...state,
      pagination: { ...state.pagination, ...payload },
    }
  },
  'TRADEBLOTTER/PAGINATION/PAGES': (state, { payload }) => {
    return {
      ...state,
      pagination: { ...state.pagination, ...payload },
    }
  },
  'TRADEBLOTTER/PAGINATION/PERPAGE': (state, { payload }) => {
    if (!payload.perPage) {
      return state
    }
    return {
      ...state,
      pagination: { ...state.pagination, ...payload },
    }
  },
  'TRADEBLOTTER/SORTED': (state, { payload }) => {
    return {
      ...state,
      sorting: payload.sortingColumns,
    }
  },
  'TRADEBLOTTER/COLUMNS/INITIALIZE': (state, { payload }) => {
    let visibilityState = {}
    try {
      visibilityState = JSON.parse(localStorage.getItem('visibilityState'))
    } catch (e) {}
    return {
      ...state,
      columns: payload.map(x =>
        merge({}, x, (visibilityState && visibilityState[x.property]) || {})
      ),
    }
  },
  'TRADEBLOTTER/COLUMNS/SET': (state, { payload }) => {
    const visibilityState = payload.reduce((obj, x) => {
      obj[x.property] = { visible: x.visible }
      return obj
    }, {})
    localStorage.setItem('visibilityState', JSON.stringify(visibilityState))
    return {
      ...state,
      columns: payload,
    }
  },
  'TRADEBLOTTER/TRADES/SET': (state, { payload }) => {
    return {
      ...state,
      trades: payload,
      errors: {
        ...state.errors,
        trades: '',
      },
    }
  },
  'TRADEBLOTTER/TRADES/ADD': (state, { payload }) => {
    return {
      ...state,
      trades: [...payload].concat(state.trades),
    }
  },
  'TRADEBLOTTER/TRADES/PUT': (state, { payload }) => {
    return {
      ...state,
      trades: state.trades.map(
        trade => (trade.id === payload.id ? payload : trade)
      ),
    }
  },
  'TRADEBLOTTER/TRADES/PUTMANY': (state, { payload }) => {
    return {
      ...state,
      trades: unionBy(payload, state.trades, 'id'),
    }
  },
  'TRADEBLOTTER/TRADES/ERROR': (state, { payload }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        trades: payload,
      },
    }
  },
  'TRADEBLOTTER/TRADES/DELETE': (state, { payload }) => {
    const trade = state.trades.find(({ id }) => id === payload)
    const deletedTrades = cloneDeep(state.deletedTrades) || []
    if (trade) deletedTrades.push(trade)
    return {
      ...state,
      trades: filter(state.trades, ({ id }) => id !== payload),
      deletedTrades,
    }
  },
  'TRADEBLOTTER/TRADES/DELETEDTRADES': (state, { payload }) =>
    Object.assign({}, state, { deletedTrades: payload }),

  'TRADEBLOTTER/SELECTED/SET': (state, { payload }) => {
    return {
      ...state,
      selected: { ...state.selected, ...payload },
    }
  },
  'TRADEBLOTTER/MTMSUMMARY/SET': (state, { payload }) => {
    return {
      ...state,
      mtmSummary: payload,
      errors: {
        ...state.errors,
        mtmSummary: '',
      },
    }
  },
  'TRADEBLOTTER/MTMSUMMARY/ERROR': (state, { payload }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        mtmSummary: payload,
      },
    }
  },
  'TRADEBLOTTER/COLUMNVISIBILITY/CLOSE': state => {
    return {
      ...state,
      columnVisibility: {
        isOpen: false,
      },
    }
  },
  'TRADEBLOTTER/COLUMNVISIBILITY/OPEN': state => {
    return {
      ...state,
      columnVisibility: {
        isOpen: true,
      },
    }
  },
}

export default handleActions(reducerMap, defaultState)
