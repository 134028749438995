export const sidebarStyles = {
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  sidebar: {
    zIndex: 20,
    position: 'absolute',
    top: 0,
    bottom: 0,
    transition: 'transform .3s ease-out',
    WebkitTransition: '-webkit-transform .3s ease-out',
    willChange: 'transform',
    overflowY: 'auto',
    backgroundColor: 'white',
    width: '50%',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    transition: 'left .3s ease-out, right .3s ease-out',
  },
  overlay: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .3s ease-out, visibility .3s ease-out',
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  dragHandle: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    bottom: 0,
  },
}

export const inputLabelStyles = {
  color: '#63666a',
  fontSize: '14px',
}

export const inputFocusLabelStyles = {
  color: '#63666a',
}

export const inputUnderlineStyles = {
  borderColor: '#63666a',
}

export const checkboxStyle = {
  display: 'inline-block',
  width: '100%',
}

export const addButtonStyle = {
  display: 'block',
  padding: '0',
  backgroundImage: 'linear-gradient(71deg, #00a9e0, #c7c9c7)',
  width: '150px',
  height: '50px',
  margin: 'auto',
}

export const addPaddedButtonStyle = {
  color: '#FFFFFF',
  backgroundImage: 'linear-gradient(71deg, #00a9e0, #c7c9c7)',
  padding: '8px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  appearance: 'none',
}

export const addButtonLabelStyle = {
  fontWeight: 'bold',
  top: '15px',
}

export const selectedMenuItemStyle = {
  color: 'rgb(38, 175, 219)',
}

export const dropdownInputStyles = {
  formControl: 'col-xs-3',
}
