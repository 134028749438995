import { createActions } from 'redux-actions'
import { entityActions } from '../storeHelpers/businessEntityHelpers'

const type = 'ClearingAgent'

const { [type.toLowerCase()]: actions } = createActions({
  [type.toUpperCase()]: {
    SET: undefined,
    ADD: undefined,
    PUT: undefined,
    REMOVE: undefined,
  },
})

actions.getAll = (checkActive = true) => ({ axios }) => async dispatch =>
  entityActions.getAll(axios, dispatch, actions.set, type, checkActive)

actions.get = brokerId => ({ axios }) => async dispatch =>
  entityActions.get(brokerId, axios, dispatch, actions.put, type)

actions.create = data => ({ axios }) => async dispatch =>
  entityActions.create(data, axios, dispatch, actions.add, type)

actions.update = data => ({ axios }) => async dispatch =>
  entityActions.update(data, axios, dispatch, actions.put, type)

actions.delete = id => ({ axios }) => async dispatch =>
  entityActions.delete(id, axios, dispatch, actions.remove, type)

const clearingAgentActions = actions
export default clearingAgentActions
