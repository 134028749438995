import { handleActions } from 'redux-actions'

const defaultState = { holidays: [], import: {} }

const reducerMap = {
  'HOLIDAY/SET': (state, { payload }) => {
    return {
      ...state,
      holidays: payload,
    }
  },
  'HOLIDAY/IMPORT': (state, { payload }) => {
    return {
      ...state,
      import: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
