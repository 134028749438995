import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './RoundedButton.styl'

class RoundedButton extends React.Component {
  getClassNames() {
    const classes = ['rounded-button']
    if (this.props.muted) {
      classes.push('rounded-button--muted')
    }
    return classes.join(' ')
  }
  render() {
    const props = _.omit(this.props, 'buttonText')
    return (
      <div className="button-wrapper">
        <button
          className={this.getClassNames()}
          onClick={this.props.onClick}
          {...props}
        >
          {this.props.buttonText}
        </button>
      </div>
    )
  }
}

RoundedButton.defaultProps = {
  buttonText: undefined,
  muted: false,
}

RoundedButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  muted: PropTypes.bool,
}

export default RoundedButton
