import _ from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'

const unitsForMonthFuncs = {
  UnitsPerDay: (quantity, yearAndMonth) =>
    yearAndMonth.daysInMonth() * quantity,
  UnitsPerMonth: quantity => quantity,
  ContractsPerDay: (quantity, yearAndMonth, unitsPerContract) =>
    yearAndMonth.daysInMonth() * quantity * unitsPerContract,
  ContractsPerMonth: (quantity, yearAndMonth, unitsPerContract) =>
    quantity * unitsPerContract,
}
const unitsForMonth = (
  yearAndMonth,
  { quantity, quantityType },
  { unitsPerContract }
) =>
  // TODO: calculate this based on quanityType
  unitsForMonthFuncs[quantityType](quantity, yearAndMonth, unitsPerContract)

const calcContractsPerMonth = (
  { quantity, quantityType },
  { unitsPerContract },
  startDate,
  endDate
) => {
  if (
    quantityType === 'ContractsPerMonth' ||
    quantityType === 'UnitsPerMonth'
  ) {
    return {
      isAverage: false,
      value:
        unitsForMonth(null, { quantity, quantityType }, { unitsPerContract }) /
        unitsPerContract,
    }
  }
  const numMonths = Math.round(endDate.diff(startDate, 'months', true))
  let unitsSum = 0
  for (let m = startDate; m.isBefore(endDate); m.add(1, 'months')) {
    unitsSum +=
      unitsForMonth(m, { quantity, quantityType }, { unitsPerContract }) /
      unitsPerContract
  }
  return {
    isAverage: numMonths > 1,
    value: unitsSum / numMonths,
  }
}

const createSummary = (
  { firstEntityId, action, secondEntityId, tradePrice },
  { id, unitType },
  startDate,
  endDate,
  { isAverage, value },
  entities
) => {
  const { name: firstEntityName } = entities.find(x => x.id === firstEntityId)
  const { name: secondEntityName } = entities.find(x => x.id === secondEntityId)
  const preposition = action === 'Sells' ? 'to' : 'from'

  return `${firstEntityName} ${action} ${preposition} ${secondEntityName} for $${tradePrice} 
  per ${pluralize.singular(unitType)}, ${isAverage
    ? 'an average of'
    : ''} ${value} 
  ${pluralize('contract', value)} per month of ${id} from ${startDate.format(
    'MMMM YYYY'
  )} 
  to ${endDate.format('MMMM YYYY')}`
}

const getDefaultUserId = (entityId, entities, users) => {
  const entity = entities.find(x => x.id === entityId)
  if (entity && entity.defaultUserId) {
    return entity.defaultUserId
  }
  const user = users.find(x => x.associatedEntityId === entityId)
  if (user) {
    return user.id
  }
  return null
}

const getBuyerSellerIds = (tradeAction, entities, users) => {
  const ids =
    tradeAction.action === 'Buys'
      ? {
          buyerId: tradeAction.firstEntityId,
          sellerId: tradeAction.secondEntityId,
        }
      : {
          buyerId: tradeAction.secondEntityId,
          sellerId: tradeAction.firstEntityId,
        }
  ids.buyerUserId = getDefaultUserId(ids.buyerId, entities, users)
  ids.sellerUserId = getDefaultUserId(ids.sellerId, entities, users)
  return ids
}

export const createTradeSummaries = (tradeEntry, products, entities) => {
  const { tradeActions, productEntry } = tradeEntry
  const product = products.find(x => x.id === productEntry.id)
  const startDate = moment(tradeEntry.startDate).startOf('month')
  const endDate = moment(tradeEntry.endDate).endOf('month')
  const contractsPerMonth = calcContractsPerMonth(
    productEntry,
    product,
    startDate,
    endDate
  )

  return tradeActions.map(item =>
    createSummary(
      item,
      product,
      startDate,
      endDate,
      contractsPerMonth,
      entities
    )
  )
}

export const createTrades = (tradeEntry, products, entities, users) => {
  const { tradeActions, productEntry } = tradeEntry
  const product = products.find(x => x.id === productEntry.id)
  const startDate = moment(tradeEntry.startDate).startOf('month')
  const endDate = moment(tradeEntry.endDate).endOf('month')

  const newTrades = []
  for (let m = moment(startDate); m.isBefore(endDate); m.add(1, 'months')) {
    const tradeStart = moment(m).startOf('month')
    const tradeEnd = moment(m).endOf('month')
    const isOption = tradeEntry.productEntry.instrumentType !== 'Swap'
    const tradesForMonth = tradeActions.map(action => {
      return {
        ...getBuyerSellerIds(action, entities, users),
        brokerId: tradeEntry.brokerId,
        brokerRate: tradeEntry.brokerRate,
        brokerUserId: getDefaultUserId(tradeEntry.brokerId, entities, users),
        tradeDateTime: moment(tradeEntry.tradeDateTime).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        traderName: tradeEntry.traderName,
        index: product.index,
        productType: product.productType,
        product: product.id,
        settlementType: product.settlementType,
        paymentDays: product.paymentDays,
        instrumentType: tradeEntry.productEntry.instrumentType,
        monthlyVolume: unitsForMonth(m, tradeEntry.productEntry, product),
        startDate: moment(tradeStart).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: moment(tradeEnd).format('YYYY-MM-DDTHH:mm:ss'),
        strikePrice: isOption ? tradeEntry.productEntry.strikePrice : 0,
        tradePremiumPrice: action.tradePrice,
        associatedGroupId: tradeEntry.productEntry.associatedGroupId,
        cmeTradeId: tradeEntry.cmeTradeId,
      }
    })
    newTrades.push(...tradesForMonth)
  }
  return newTrades
}

export const createTradeGroup = trades => {
  const prototype = _.first(trades)
  return {
    ..._.omit(prototype, 'id'),
    trades: trades.map(x => _.omit(x, 'id')),
  }
}
