import React from 'react'
import PropTypes from 'prop-types'
import './ChangePassword.styl'
import '../../application.styl'
import LoginSignUpBg from '../LoginSignUpBg/LoginSignUpBg'
import ChangePasswordWithTokenForm from '../Forms/ChangePassword/ChangePasswordWithTokenForm'

const ChangePasswordWithToken = props => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 padding-zero margin-zero">
          <LoginSignUpBg />
          <div className="col-xs-4 form-container">
            <ChangePasswordWithTokenForm {...props.match.params} />
          </div>
        </div>
      </div>
    </div>
  )

ChangePasswordWithToken.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
export default ChangePasswordWithToken
