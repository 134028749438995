import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { build, actions } from '../../container_helpers'
import './AddButton.styl'

class AddButton extends Component {
  toggleSidebarLocal = () => {
    this.props.toggleSidebar()
    this.props.selectedUser({})
  }

  render() {
    return (
      <div className="add-button-position rounded-button" onClick={this.toggleSidebarLocal}>
        +
      </div>
    )
  }
}

AddButton.propTypes = {
  toggleSidebar: PropTypes.func,
  selectedUser: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
  }
}

const mapDispatchToProps = {
  toggleSidebar: actions.sidebar.toggle,
  selectedUser: actions.selected,
}

export default build({
  component: AddButton,
  mapDispatchToProps,
  mapStateToProps,
})
