import UserInfo from './UserInfo'
import { build, actions } from '../container_helpers'

const mapDispatchToProps = {
  deleteSession: actions.login.delete,
}

const mapStateToProps = state => {
  return {
    logged: state.logger,
  }
}

export default build({
  component: UserInfo,
  mapDispatchToProps,
  mapStateToProps,
})
