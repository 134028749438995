import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import './Item.styl'

class Item extends Component {
  render() {
    return (
      <div className="content">
        <div className={this.props.imgUrl} />
        <div className="item-dashboard-title"> {this.props.title} </div>
        <Link to={this.props.link}>
          <div className="imgArrow" />
        </Link>
      </div>
    )
  }
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
}

export default Item
