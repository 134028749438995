import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import uuid from 'uuid'
import { RaisedButton } from 'material-ui'
import { build, actions } from '../container_helpers'
import {
  createTrades,
  createTradeSummaries,
  createTradeGroup,
} from '../../helpers/tradeEntry'
import { mergeEntities } from '../../helpers/tradeBlotterUtils'
import {
  bankPropType,
  privateEquityPropType,
  counterpartyPropType,
  productPropType,
  bankCustomerPropType,
  userPropType,
  brokerPropType,
  tradeEntryPropType,
  enumPropType,
  tradeBlotterPropType,
} from '../shared_prop_types'
import './TradeEntryReview.styl'
import EditableTable from '../EditableTable/EditableTable'
import { addButtonStyle, addButtonLabelStyle } from '../../styles'

class TradeEntryReview extends Component {
  state = {
    errors: {},
  }

  componentWillMount() {
    actions.tradeBlotter.trades.error('')
    const {
      tradeEntry,
      products,
      banks,
      privateEquities,
      counterparties,
      bankCustomers,
      users,
    } = this.props

    const entities = _.orderBy(
      mergeEntities(banks, privateEquities, counterparties, bankCustomers),
      x => x.name
    )
    const rows = createTrades(
      tradeEntry,
      products,
      entities,
      users
    ).map((x, index) => {
      return {
        ...x,
        id: uuid.v4(),
        tid: tradeEntry.nextTID + index,
      }
    })
    this.setState({ rows, entities })
  }

  componentWillReceiveProps({ trades, error }) {
    if (trades && !error) {
      this.props.onSuccess()
    }
  }

  submit = () => {
    const tradeGroup = createTradeGroup(this.state.rows)
    tradeGroup.usiNumber = this.props.tradeEntry.usiNumber
    tradeGroup.gid = this.props.tradeEntry.nextGID
    this.props.createTrades(tradeGroup)
  }

  updateTrades = update => {
    this.setState(update)
  }

  render() {
    const { tradeEntry, products, instrumentTypes } = this.props

    return (
      <div className="trade-entry-review">
        <div className="row header">
          <h2>Review Summary</h2>
          <h3>Summary</h3>
          <div>
            {createTradeSummaries(
              tradeEntry,
              products,
              this.state.entities
            ).map(x => <div key={x}>{x}</div>)}
          </div>
          <h3>Detailed Overview</h3>
          <EditableTable
            entities={this.state.entities}
            brokers={this.props.brokers}
            instrumentTypes={instrumentTypes}
            products={products}
            users={this.props.users}
            updateTrades={this.updateTrades}
            rows={this.state.rows}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            position: 'absolute',
            bottom: 0,
            marginBottom: '20px',
          }}
        >
          <div>{this.props.error}</div>
          <RaisedButton
            primary
            label="Back"
            onClick={() => {
              this.props.onBack()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
          <RaisedButton
            primary
            label="Accept & Create Trades"
            onClick={() => {
              this.submit()
            }}
            style={addButtonStyle}
            buttonStyle={addButtonStyle}
            labelStyle={addButtonLabelStyle}
          />
        </div>
      </div>
    )
  }
}

TradeEntryReview.propTypes = {
  banks: PropTypes.arrayOf(bankPropType),
  brokers: PropTypes.arrayOf(brokerPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  users: PropTypes.arrayOf(userPropType),
  products: PropTypes.arrayOf(productPropType),
  tradeEntry: tradeEntryPropType,
  instrumentTypes: PropTypes.arrayOf(enumPropType),
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  createTrades: PropTypes.func,
  trades: PropTypes.arrayOf(tradeBlotterPropType),
  error: PropTypes.string,
}

const mapDispatchToProps = {
  createTrades: actions.tradeBlotter.create,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {
  return {
    tradeEntry: state.tradeEntry,
    products: state.product.products,
    instrumentTypes: state.enum.instrumentTypes,
    trades: state.tradeBlotter.trades,
    error: state.tradeBlotter.errors.trades,
  }
}

export default build({
  component: TradeEntryReview,
  mapDispatchToProps,
  mapStateToProps,
})
