import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import classnames from 'classnames'
// import '../../../application.styl'
import styles from './ThresholdSummary.styl'
import { build, actions } from '../container_helpers'
import { currencyDiv } from '../../helpers/formatCurrency'
import { formatPercentage } from '../../helpers/formatPercentage'
import Dropdown from '../Dropdown/Dropdown'
import PickHistoricalDate from '../PickHistoricalDate/PickHistoricalDate'
import * as EntityPropType from '../shared_prop_types'
import { exportTableToCSV } from '../../helpers/csvExporter'
import RoundedButton from '../UtilsComponents/RoundedButton/RoundedButton'
import { getRenderData, getFileName } from '../../helpers/reportUtils'
import { reports } from '../../helpers/reportDefinitions'

class ThresholdSummary extends Component {
  constructor() {
    super()
    this.state = {
      selectedEntityId: null,
      showEntities: false,
    }
  }

  componentWillMount() {
    this.props.getAllBanks()
  }

  componentWillReceiveProps(newProps) {
    const { banks, selectedDate } = newProps
    if (banks !== this.props.banks) {
      if (banks.length === 1) {
        this.setState({ showEntities: false, selectedEntityId: banks[0].id })
        if (this.props.selectedDate) {
          this.props.getThresholdSummaries(banks[0].id, {
            settleDate: this.props.selectedDate,
          })
          this.props.getEntity(banks[0].id)
        }
      } else {
        this.setState({ showEntities: true })
      }
    }
    if (selectedDate !== this.props.selectedDate) {
      if (this.state.selectedEntityId) {
        this.props.getThresholdSummaries(this.state.selectedEntityId, {
          settleDate: selectedDate,
        })
        this.props.getEntity(this.state.selectedEntityId)
      }
    }
  }

  onEntityChange = event => {
    const selectedEntityId = event.target.value
    this.setState({
      selectedEntityId,
    })

    if (this.props.selectedDate) {
      this.props.getThresholdSummaries(selectedEntityId, {
        settleDate: this.props.selectedDate,
      })
    }
    this.props.getEntity(selectedEntityId)
  }

  onDateChange = date => {
    if (this.state.selectedEntityId) {
      this.props.getThresholdSummaries(this.state.selectedEntityId, {
        settleDate: date,
      })
    }
  }

  downloadCsv = async () => {
    const { entity, selectedDate } = this.props
    const report = reports['Threshold Summary']
    const fileName = getFileName(
      entity.displayName,
      report.reportFileName,
      selectedDate
    ).replace('.pdf', '.csv')
    exportTableToCSV(fileName, 'table.csv-table')
  }

  downloadPdf = () => {
    const { entity, selectedDate } = this.props
    const report = reports['Threshold Summary']
    const data = getRenderData(
      report,
      entity.id,
      selectedDate,
      null,
      entity.displayName
    )

    this.props.generateReport(
      data.url,
      data.fileName,
      data.scale,
      data.pageLayout,
      data.margins
    )
  }

  buttonsDisabled = () => {
    const { entity, selectedDate } = this.props

    return _.isEmpty(entity) || !selectedDate
  }

  renderEntityItems = () => {
    const { banks } = this.props

    return banks.map(el => (
      <MenuItem key={el.id} value={el.id}>
        {el.displayName}
      </MenuItem>
    ))
  }

  renderControls() {
    return (
      <div className="row titleRow">
        <div className="markTitle page-title col-xs-3">Threshold</div>
        <div className="selectField col-xs-2">
          <PickHistoricalDate noupdate />
        </div>
        {this.state.showEntities && (
          <div className="col-xs-2">
            <Dropdown
              floatingLabelText={`Select Entity`}
              value={this.state.selectedEntityId}
              onChange={this.onEntityChange}
              hintText="Select"
            >
              {this.renderEntityItems()}
            </Dropdown>
          </div>
        )}

        <div className="col-xs-2">
          <RoundedButton
            disabled={this.buttonsDisabled()}
            buttonText={'PDF'}
            onClick={this.downloadPdf}
          />
          <RoundedButton
            disabled={this.buttonsDisabled()}
            buttonText={'CSV'}
            onClick={this.downloadCsv}
          />
        </div>
      </div>
    )
  }
  renderReport(csvData) {
    const { thresholdSummaries, entity } = this.props
    const { selectedDate: settleDate } = this.props

    if (!entity) return null
    const dateText = moment(settleDate, 'MM-DD-YYYY').format('MM/DD/YYYY')

    const totals = {
      combined: _.sumBy(
        thresholdSummaries.map(x => {
          return { ...x, combined: parseFloat(x.combined.toFixed(2)) }
        }),
        'combined'
      ),
      impliedMargin: _.sumBy(
        thresholdSummaries.map(x => {
          return { ...x, impliedMargin: parseFloat(x.impliedMargin.toFixed(2)) }
        }),
        'impliedMargin'
      ),
      markToMarket: _.sumBy(
        thresholdSummaries.map(x => {
          return { ...x, markToMarket: parseFloat(x.markToMarket.toFixed(2)) }
        }),
        'markToMarket'
      ),
    }
    const logo = `${process.env.API_URL}/${entity.logo}`
    const customersOrCounterparties = _.every(
      thresholdSummaries,
      x => x.isBankCustomer
    )
      ? 'CUSTOMERS'
      : 'COUNTERPARTIES'
    return (
      <div className={styles.thresholdSummary} align="center">
        <table className={classnames('summary-table', csvData && 'csv-table')}>
          <thead>
            <tr className="summary-header-1">
              <th align="center" colSpan="8">
                <div>
                  <img
                    className="tappian-report-logo"
                    src="/img/tappian-logo-color.svg"
                    alt="tappian logo"
                  />
                  <h4>COMPANY THRESHOLD SUMMARY</h4>
                  <img className="report-logo" src={logo} alt="bank logo" />
                </div>
              </th>
            </tr>
            <tr className="summary-header-2">
              <th>{dateText}</th>
              <th>Combined</th>
              <th>Implied Margin</th>
              <th>Current MTM</th>
              <th>Hedge Line</th>
              <th>Exposure</th>
              <th>Capacity Utilization</th>
              <th>Good to Trade?</th>
            </tr>
            <tr className="summary-header-3">
              <th>{customersOrCounterparties}</th>
              <th colSpan="7" />
            </tr>
          </thead>
          <tbody>
            {thresholdSummaries.map(x => {
              let color
              if (x.capacityUtilization <= 0.7) color = '#87C947'
              else if (
                x.capacityUtilization > 0.7 &&
                x.capacityUtilization <= 0.8
              )
                color = 'yellow'
              else color = 'red'
              return (
                <tr key={x.name}>
                  <td>{x.name}</td>
                  <td>{csvData ? x.combined : currencyDiv(x.combined)}</td>
                  <td>
                    {csvData ? x.impliedMargin : currencyDiv(x.impliedMargin)}
                  </td>
                  <td>
                    {csvData ? x.markToMarket : currencyDiv(x.markToMarket)}
                  </td>
                  <td>{csvData ? x.hedgeLine : currencyDiv(x.hedgeLine)}</td>
                  <td>
                    {csvData ? x.exposure : formatPercentage(x.exposure * 100)}
                  </td>
                  <td>
                    {csvData ? (
                      x.capacityUtilization
                    ) : (
                      formatPercentage(x.capacityUtilization * 100)
                    )}
                  </td>
                  <td style={{ backgroundColor: color }} />
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td align="right">Totals</td>
              <td>
                {csvData ? totals.combined : currencyDiv(totals.combined)}
              </td>
              <td>
                {csvData ? (
                  totals.impliedMargin
                ) : (
                  currencyDiv(totals.impliedMargin)
                )}
              </td>
              <td>
                {csvData ? (
                  totals.markToMarket
                ) : (
                  currencyDiv(totals.markToMarket)
                )}
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderControls()}
        {this.renderReport()}
        {this.renderReport(true)}
      </div>
    )
  }
}

ThresholdSummary.propTypes = {
  banks: PropTypes.arrayOf(EntityPropType.bankPropType),
  generateReport: PropTypes.func,
  getAllBanks: PropTypes.func,
  getThresholdSummaries: PropTypes.func,
  thresholdSummaries: PropTypes.arrayOf(PropTypes.object),
  selectedDate: PropTypes.string,
  getEntity: PropTypes.func,
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
}

const mapDispatchToProps = {
  getAllBanks: actions.bank.getAll,
  getThresholdSummaries:
    actions.tradeSummary.getAllThresholdSummariesForEntityId,
  getEntity: actions.entity.get,
  generateReport: actions.pdf.generate,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    thresholdSummaries: state.tradeSummaries,
    selectedDate: state.tradeBlotter.selected.date,
    entity: state.entity,
  }
}

export default build({
  component: ThresholdSummary,
  mapDispatchToProps,
  mapStateToProps,
})
