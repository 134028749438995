import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { actions, build } from '../container_helpers'
import './TradeEntryForm.styl'
import {
  bankPropType,
  privateEquityPropType,
  counterpartyPropType,
  clearingAgentPropType,
  bankCustomerPropType,
  userPropType,
  brokerPropType,
  tradeEntryPropType,
  productPropType,
} from '../shared_prop_types'
import TradeEntryForm from './TradeEntryForm'
import TradeEntryReview from './TradeEntryReview'
import './../UtilsComponents/AddButton/AddButton.styl'
import style from './TradeEntryWizard.styl'

const styles = theme => {
  return {
    paper: {
      position: 'relative',
      width: '90%',
      height: '95%',
      marginTop: '1em',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: 'none',
      overflow: 'scroll',
      display: 'block',
    },
  }
}

class _TradeEntryWizard extends Component {
  state = {
    open: false,
    mode: 'entry',
  }

  componentWillMount() {
    this.props.getUsers()
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  entrySuccess = () => {
    this.setState({ mode: 'review' })
  }

  reviewBack = () => {
    this.setState({ mode: 'entry' })
  }

  renderContent = () => {
    if (this.state.mode === 'entry') {
      return (
        <TradeEntryForm
          banks={this.props.banks}
          brokers={this.props.brokers}
          privateEquities={this.props.privateEquities}
          bankCustomers={this.props.bankCustomers}
          counterparties={this.props.counterparties}
          clearingAgents={this.props.clearingAgents}
          users={this.props.users}
          onCancel={this.handleClose}
          onSuccess={this.entrySuccess}
        />
      )
    }
    return (
      <TradeEntryReview
        banks={this.props.banks}
        brokers={this.props.brokers}
        privateEquities={this.props.privateEquities}
        bankCustomers={this.props.bankCustomers}
        counterparties={this.props.counterparties}
        clearingAgents={this.props.clearingAgents}
        users={this.props.users}
        onBack={this.reviewBack}
        onSuccess={this.handleClose}
      />
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <div
          className="add-button-position rounded-button"
          onClick={this.handleOpen}
        >
          +
        </div>

        <Modal
          className={style.modal}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.paper}>{this.renderContent()}</div>
        </Modal>
      </div>
    )
  }
}

_TradeEntryWizard.propTypes = {
  banks: PropTypes.arrayOf(bankPropType),
  brokers: PropTypes.arrayOf(brokerPropType),
  privateEquities: PropTypes.arrayOf(privateEquityPropType),
  counterparties: PropTypes.arrayOf(counterpartyPropType),
  clearingAgents: PropTypes.arrayOf(clearingAgentPropType),
  bankCustomers: PropTypes.arrayOf(bankCustomerPropType),
  users: PropTypes.arrayOf(userPropType),
  classes: PropTypes.instanceOf(Object),
  products: PropTypes.arrayOf(productPropType),
  tradeEntry: tradeEntryPropType,
  getUsers: PropTypes.func,
}

const mapDispatchToProps = {
  getUsers: actions.user.getAll,
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {
  return {
    tradeEntry: state.tradeEntry,
    products: state.product.products,
    users: state.user,
  }
}

const TradeEntryWizard = withStyles(styles)(_TradeEntryWizard)
export default build({
  component: TradeEntryWizard,
  mapDispatchToProps,
  mapStateToProps,
})
