import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton } from 'material-ui'
import { Button, MenuItem } from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
import FileReaderInput from 'react-file-reader-input'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import Dropdown from '../../Dropdown/Dropdown'

import '../SidebarForm.styl'
import {
  addButtonStyle,
  addButtonLabelStyle,
  addPaddedButtonStyle,
  dropdownInputStyles,
} from '../../../styles'
import {
  bankPropType,
  userPropType,
  enumPropType,
} from '../../shared_prop_types'
import { validateBankForm } from '../../../helpers/formsValidators'
import Addresses from '../Addresses/Addresses'
import PhoneNumbers from '../PhoneNumbers/PhoneNumbers'
import Emails from '../Emails/Emails'
import EntityFormWrapper from '../Entity/EntityFormWrapper'
import { useEntityForm } from '../Entity/entityHooks'

const BankForm = props => {
  const defaults = {
    name: '',
    displayName: '',
    abbreviation: '',
    accountNumber: '',
    routingNumber: '',
    cmeAccountNumber: '',
    usiPrefix: '',
    logo: '',
    addresses: [],
    phoneNumbers: [],
    emails: [],
    hedgeRequirements: [],
    recommendedAction: 'None',
    potentialHedgeDocumentType: 'None',
    ownership: 0,
    leiNumber: '',
    leiStatus: 'Inactive',
    leiWeblink: '',
    maturityDate: null,
    leiRenewalDate: null,
    attentionPerson: '',
    relationshipManager: '',
    permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
    swapDataRepositoryName: '',
  }

  // blob: '',
  // selectedLogo: '',

  const { selected, getEntity, updateEntity, createEntity, users } = props

  const [newEntity, setEntity, errors, setErrors, submit] = useEntityForm({
    selected,
    defaults,
    getEntity,
    updateEntity,
    createEntity,
  })

  const [selectedLogo, setLogo] = useState()
  const [blob, setBlob] = useState()

  const validateForm = async () => {
    const validationResult = validateBankForm(newEntity, errors)
    setErrors(validationResult.errors)
    /**
      * If there's no error, then create the bank
      */
    if (validationResult.valid) {
      const result = await submit()
      if (selectedLogo && result) {
        await props.uploadLogo(selectedLogo, result.id)
      }
    }
  }

  const handleLogo = (e, results) => {
    const reader = new FileReader()
    reader.readAsDataURL(results[0][1])
    reader.onload = async () => {
      setBlob(reader.result)
    }
    setLogo(results[0][1])
  }

  const renderUserDropdown = () => {
    if (!selected) return null

    const filteredUsers = users.filter(x =>
      _.some(x.associatedEntities, entity => entity.id === selected.id)
    )
    const items = [
      <MenuItem key="" value="">
        (None)
      </MenuItem>,
    ]

    return (
      <Dropdown
        floatingLabelText="Default User (Optional)"
        value={newEntity.defaultUserId}
        onChange={e => setEntity({ defaultUserId: e.target.value })}
        errorText={errors.defaultUserId}
        hintText="Select a Default User (Optional)"
      >
        {items.concat(
          filteredUsers.map(name => (
            <MenuItem key={name.id} value={name.id}>
              {name.fullName}
            </MenuItem>
          ))
        )}
      </Dropdown>
    )
  }

  const renderLeiStatus = () => (
    <Dropdown
      floatingLabelText="LEI Status"
      value={newEntity.leiStatus}
      styles={dropdownInputStyles}
      onChange={e => {
        setEntity({ leiStatus: e.target.value })
      }}
      hintText="Select an LEI Status (Optional)"
    >
      {props.leiStatuses.map(x => (
        <MenuItem key={x.id} value={x.id}>
          {x.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )

  const renderDetails = () => {
    const logo = `${process.env.API_URL}/${newEntity.logo}`
    return (
      <div className="create">
        <div className="sidebar-title">
          {!_.isEmpty(selected) ? 'Edit Bank' : 'Add Bank'}
        </div>
        <div className="create-form">
          <div className="entity-details">
            {!_.isEmpty(props.selected) && (
              <div className="row">
                <div className="col-xs-2">
                  <Button
                    onClick={() => {
                      if (
                        confirm(
                          `Are you sure you want to DELETE all of this entity's trades?`
                        )
                      )
                        props.clearTrades(newEntity.id)
                    }}
                  >
                    Clear Trades
                  </Button>
                </div>
              </div>
            )}
            <div className="create-title"> Bank Details</div>
            <div className="row">
              <Input
                className="col-xs-3"
                name="name"
                title="Bank Name"
                defaultValue={newEntity.name}
                onChange={e => {
                  setEntity({ name: e.target.value })
                }}
                errorText={errors.name}
              />
              <Input
                className="col-xs-3"
                name="displayName"
                title="Display Name"
                defaultValue={newEntity.displayName}
                onChange={e => {
                  setEntity({ displayName: e.target.value })
                }}
                errorText={errors.displayName}
              />
              <Input
                className="col-xs-3"
                name="abbrevation"
                title="Abbreviation"
                defaultValue={newEntity.abbreviation}
                onChange={e => {
                  setEntity({ abbreviation: e.target.value })
                }}
                errorText={errors.abbreviation}
              />
            </div>
            <br />
            <PhoneNumbers
              phoneNumbers={newEntity.phoneNumbers}
              onChange={phoneNumbers => {
                setEntity({ phoneNumbers })
              }}
            />
            <br />
            <Emails
              emails={newEntity.emails}
              onChange={emails => {
                setEntity({ emails })
              }}
            />
            <br />
            <Addresses
              addresses={newEntity.addresses}
              onChange={addresses => {
                setEntity({ addresses })
              }}
            />
            <br />
            <div className="row">
              <Input
                className="col-xs-3"
                name="leiNumber"
                title="LEI Number"
                errorText={errors.leiNumber}
                defaultValue={newEntity.leiNumber}
                onChange={e => {
                  setEntity({ leiNumber: e.target.value })
                }}
              />

              {renderLeiStatus()}

              <DatePicker
                className="col-xs-3"
                name="leiRenewalDate"
                label="LEI Renewal Date"
                format="MM/DD/YYYY"
                views={['year', 'month', 'day']}
                value={newEntity.leiRenewalDate}
                onChange={date => {
                  setEntity({ leiRenewalDate: date.toDate() })
                }}
                clearable
                showTodayButton
              />

              <Input
                className="col-xs-3"
                name="leiWeblink"
                title="LEI Weblink"
                errorText={errors.leiWeblink}
                defaultValue={newEntity.leiWeblink}
                onChange={e => {
                  setEntity({ leiWeblink: e.target.value })
                }}
              />
            </div>

            <div className="row">
              <Input
                className="col-xs-3"
                name="accountNumber"
                title="Account Number (Optional)"
                defaultValue={newEntity.accountNumber}
                onChange={e => {
                  setEntity({ accountNumber: e.target.value })
                }}
              />
              <Input
                className="col-xs-3"
                name="routingNumber"
                title="Routing Number (Optional)"
                defaultValue={newEntity.routingNumber}
                onChange={e => {
                  setEntity({ routingNumber: e.target.value })
                }}
              />
              <Input
                className="col-xs-3"
                name="usiPrefix"
                title="USI Prefix (Optional)"
                defaultValue={newEntity.usiPrefix}
                onChange={e => {
                  setEntity({ usiPrefix: e.target.value })
                }}
              />

              <Input
                className="col-xs-3"
                name="attentionPerson"
                title="Attention Person"
                defaultValue={newEntity.attentionPerson}
                onChange={e => {
                  setEntity({ attentionPerson: e.target.value })
                }}
              />
            </div>

            <div className="row">
              <Input
                className="col-xs-3"
                name="relationshipManager"
                title="Relationship Manager"
                defaultValue={newEntity.relationshipManager}
                onChange={e => {
                  setEntity({ relationshipManager: e.target.value })
                }}
              />
              {!_.isEmpty(selected) && renderUserDropdown()}

              <Input
                name="swapDataDepositoryName"
                title="Swap Data Repository Name"
                className="col-xs-3"
                defaultValue={newEntity.swapDataRepositoryName}
                onChange={e => {
                  setEntity({ swapDataRepositoryName: e.target.value })
                }}
              />

              <img src={blob || logo} alt="logo" className="logo-inner" />

              <FileReaderInput
                as="text"
                id="my-file-input"
                name="Trade Blotter CSV"
                accept=".png,.jpg,.jpeg,.svg"
                placeholder="Logo"
                className="inputClass"
                onChange={handleLogo}
              >
                <button
                  className="button-left"
                  style={addPaddedButtonStyle}
                  type="button"
                >
                  Select a Logo
                </button>
              </FileReaderInput>
            </div>
          </div>
          <div className="add-button-bg">
            <RaisedButton
              primary
              label={!_.isEmpty(selected) ? 'Save' : 'Add'}
              onClick={() => {
                validateForm()
              }}
              style={addButtonStyle}
              buttonStyle={addButtonStyle}
              labelStyle={addButtonLabelStyle}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <EntityFormWrapper
      newEntity={newEntity}
      setEntity={setEntity}
      render={renderDetails}
    />
  )
}

BankForm.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  getEntity: PropTypes.func.isRequired,
  createEntity: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  clearTrades: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(userPropType),
  leiStatuses: PropTypes.arrayOf(enumPropType),
  selected: bankPropType,
}

const mapDispatchToProps = {
  getEntity: actions.bank.get,
  createEntity: actions.bank.create,
  updateEntity: actions.bank.update,
  uploadLogo: actions.entity.uploadLogo,
  toggleSidebar: actions.sidebar.toggle,
  clearTrades: actions.entity.clearTrades,
}

const mapStateToProps = state => {
  return {
    users: state.user,
    leiStatuses: state.enum.leiStatuses,
  }
}

export default build({
  component: BankForm,
  mapStateToProps,
  mapDispatchToProps,
})
