import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import { withRouter, Link } from 'react-router-dom'
import VerificationInput from 'react-verification-input'
import _ from 'lodash'
import PropTypes from 'prop-types'
import './LoginForm.styl'
import { loginPropType } from '../../shared_prop_types'
import { build, actions } from '../../container_helpers'
import TappianInput from '../../UtilsComponents/TappianInput/TappianInput'
import RoundedButton from '../../UtilsComponents/RoundedButton/RoundedButton'

import ResetPasswordForm from '../ResetPassword/ResetPasswordForm'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'login',
      loginUser: {
        email: '',
        password: '',
        code: '',
      },
      errors: '',
      openResetPassword: false,
    }
    this.actions = {
      login: () => this.props.login(this.state.loginUser),
      instructions: () => this.setState({ mode: 'register' }),
      register: () => this.props.registerTwoFactor(this.state.loginUser),
      twoFactor: () => this.props.loginTwoFactor(this.state.loginUser),
    }
  }
  componentWillReceiveProps(nextProps) {
    const { loginResponse, twoFactorResponse } = nextProps
    if (!_.isEmpty(twoFactorResponse)) {
      window.localStorage.setItem('currentUserId', twoFactorResponse.id)
      this.props.history.push('/dashboard')
    }
    let mode = 'login'
    if (!_.isEmpty(loginResponse)) {
      if (loginResponse.isTwoFactorRegistered === false) mode = 'instructions'
      else mode = 'twoFactor'
    }
    this.setState({ mode })
  }
  saveLoginUser = userObj => {
    const userInfo = this.state.loginUser
    this.setState({ loginUser: { ...userInfo, ...userObj } })
  }
  validateLogin = e => {
    e.preventDefault()
    this.actions[this.state.mode]()
  }
  loginTwoFactor = () => {
    this.props.loginTwoFactor(this.state.loginUser)
  }

  openResetPassword = () => {
    this.setState({ openResetPassword: true })
  }

  goToHome = e => {
    e.preventDefault()
    this.props.history.push('/')
  }

  renderLogin = () => (
    <form onSubmit={this.validateLogin}>
      <h1 className="login-form__heading">Sign Into Your Account</h1>
      <div>
        <TappianInput
          name="email"
          label="User Name"
          type="email"
          value={this.state.loginUser.email}
          onChange={e => {
            this.saveLoginUser({ email: e.target.value })
          }}
        />
      </div>
      <div>
        <TappianInput
          name="password"
          label="Password"
          type="password"
          value={this.state.loginUser.password}
          onChange={e => {
            this.saveLoginUser({ password: e.target.value })
          }}
        />
      </div>
      {this.props.loginError.length ? (
        <div className="error error-message">{`${this.props.loginError}`}</div>
      ) : (
        ''
      )}
      <div className="clearfix">
        <div className="forgot-password-link" style={{ paddingTop: '40px' }}>
          <a onClick={this.openResetPassword}>Forgot your password?</a>
        </div>
        <div style={{ float: 'right' }}>
          <RoundedButton buttonText={'Sign In'} onClick={this.validateLogin} />
        </div>

        <ResetPasswordForm
          open={this.state.openResetPassword}
          onClose={() => this.setState({ openResetPassword: false })}
        />
      </div>
    </form>
  )

  renderTwoFactorInstructions = () => (
    <div className="tfa-instructions">
      <Link className="back-link" to={`/login`}>
        &#8592; Back to Sign In
      </Link>
      <p>
        The Tappian Platform is a private and secure environment. Every log-in
        attempt requires two-factor authentication, which is not a setting that
        can be turned off. In order to access the Tappian Platform, you will
        need to use an authenticator. Google Authenticator is the recommended
        authenticator, but any authenticator application will work. Please
        follow the instructions below.
      </p>
      <ol>
        <li>
          <h1>Download the Google Authenticator app to your mobile device.</h1>
          <div className="app-links">
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noopener noreferrer"
            >
              iTunes
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play
            </a>
          </div>
        </li>
        <li>
          <h1>Set up Google Authenticator</h1>
          <p>
            Setup instructions are provided by the application once downloaded
            and opened.
          </p>
          <small>
            Note: You will need to connect your Google Authenticator application
            to your email account by scanning a QR Code or entering the provided
            16-character code manually. You will only need to do this once (per
            setup).
          </small>
        </li>
        <li>
          <h1>Use Google Authenticator every time you login.</h1>
          <p>
            Upon each login attempt, you will enter email address, password, and
            then the six-digit code provided by your authenticator application.
          </p>
          <small>
            Note: The code provided by the Google Authenticator application will
            change every 30 seconds, thus a new code will be needed for every
            login attempt.
          </small>
        </li>
      </ol>
      <RoundedButton buttonText={'Continue'} onClick={this.validateLogin} />
    </div>
  )

  renderTwoFactorRegister = () => {
    const { loginResponse } = this.props
    const muted = true
    return (
      <form onSubmit={this.validateLogin} className="two-factor">
        <h1 className="login-form__heading">Two-Factor Authentication</h1>
        <div>
          <p>
            Scan the QR code or enter this key to set up your authenticator app.
          </p>{' '}
          <kbd className="two-factor__key">{loginResponse.sharedKey}</kbd>
        </div>
        <div className="two-factor__qr">
          <QRCode value={loginResponse.authenticatorUri} />
        </div>
        <div>
          <p>Enter your verification code:</p>
          <VerificationInput
            placeholder=""
            autoFocus
            validChars="0-9"
            getInputRef={ref => {
              if (ref !== null) {
                ref.onchange = () => {
                  this.saveLoginUser({ code: ref.value })
                }
              }
            }}
            removeDefaultStyles
            container={{
              className: 'tfa-code-container',
            }}
            characters={{
              className: 'characters',
            }}
            character={{
              className: 'character',
              classNameInactive: 'character--inactive',
              classNameSelected: 'character--selected',
            }}
          />
        </div>
        <div style={{ float: 'left' }}>
          <RoundedButton
            buttonText="Cancel"
            onClick={this.goToHome}
            muted={muted}
            type="button"
          />
        </div>
        <RoundedButton buttonText={'Verify'} onClick={this.validateLogin} />
        {this.props.loginError.length ? (
          <div className="error error-message">{`${this.props
            .loginError}`}</div>
        ) : (
          ''
        )}
      </form>
    )
  }
  renderTwoFactorLogin = () => {
    const muted = true
    return (
      <form>
        <div>
          <h1 className="login-form__heading">Two-Factor Authentication</h1>
          <p>Enter your verification code:</p>
          <VerificationInput
            placeholder=""
            autoFocus
            getInputRef={ref => {
              if (ref !== null) {
                ref.onkeyup = () => {
                  this.saveLoginUser({ code: ref.value })
                }
                // ref.onChange = () => {
                //   this.saveLoginUser({ code: ref.value })
                // }
              }
            }}
            removeDefaultStyles
            container={{
              className: 'tfa-code-container',
            }}
            characters={{
              className: 'characters',
            }}
            character={{
              className: 'character',
              classNameInactive: 'character--inactive',
              classNameSelected: 'character--selected',
            }}
          />
        </div>
        <div style={{ float: 'left' }}>
          <RoundedButton
            buttonText={'Cancel'}
            onClick={this.goToHome}
            muted={muted}
            type="button"
          />
        </div>
        <RoundedButton buttonText={'Verify'} onClick={this.validateLogin} />
        {this.props.loginError.length ? (
          <div className="error error-message">{`${this.props
            .loginError}`}</div>
        ) : (
          ''
        )}
      </form>
    )
  }
  renderForm = () => {
    const { mode } = this.state
    if (mode === 'login') return this.renderLogin()
    if (mode === 'instructions') return this.renderTwoFactorInstructions()
    if (mode === 'register') return this.renderTwoFactorRegister()
    return this.renderTwoFactorLogin()
  }
  render() {
    const { loginResponse, twoFactorResponse } = this.props
    return (
      <div style={{ textAlign: 'center', display: 'flow-root' }}>
        <div className="logo-container">
          <div className="image-logo">
            <img src="/img/tappian-logo-color.svg" alt="Tappian Logo" />
          </div>
        </div>
        <div className="login-form">{this.renderForm()}</div>
        <Link className="back-link" to={`/`}>
          &#8592; Home
        </Link>
      </div>
    )
  }
}

LoginForm.propTypes = {
  loginResponse: PropTypes.object,
  registerResponse: PropTypes.object,
  twoFactorResponse: loginPropType,
  login: PropTypes.func,
  loginTwoFactor: PropTypes.func,
  registerTwoFactor: PropTypes.func,
  resetPassword: PropTypes.func,
  clearResetPasswordStatus: PropTypes.func,
  passwordResetStatus: PropTypes.shape({ success: PropTypes.bool }),
  loginError: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}
const mapDispatchToProps = {
  login: actions.login.create,
  loginTwoFactor: actions.login.twoFactor,
  registerTwoFactor: actions.login.registerTwoFactor,
  resetPassword: actions.resetPassword.resetPassword,
  clearResetPasswordStatus: actions.resetPassword.clearStatus,
}
const mapStateToProps = state => {
  return {
    loginResponse: state.login.first,
    twoFactorResponse: state.login.second,
    passwordResetStatus: state.resetPassword,
    loginError: state.login.error,
  }
}
export default withRouter(
  build({
    component: LoginForm,
    mapStateToProps,
    mapDispatchToProps,
  })
)
