import { createActions } from 'redux-actions'

const { accounts: accountsActions } = createActions({
  ACCOUNTS: {
    ENTITY: {
      SET: undefined,
    },
    ENTITYTYPE: {
      SET: undefined,
    },
    PARENT: {
      SET: undefined,
    },
  },
})

export default accountsActions
