import React, { Component } from 'react'
import _ from 'lodash'
import Sidebar from 'react-sidebar'
import PropTypes from 'prop-types'
import styles from './Accounts.styl'
import { sidebarStyles } from '../../styles'
import EntityShows from '../EntityShows/EntityShows'
import BankForm from './../Forms/Bank/BankForm'
import BankCustomerForm from './../Forms/BankCustomer/BankCustomerForm'
import CounterpartyForm from './../Forms/Counterparty/CounterpartyForm'
import PrivateEquityForm from './../Forms/PrivateEquity/PrivateEquityForm'
import BrokerForm from '../Forms/Brokers/BrokerForm'
import UserForm from './../Forms/User/UserForm'
import EntityItemList from '../EntityItemList/EntityItemList'
import { entitiesRaw } from '../../constants'
import * as EntityPropType from '../shared_prop_types'
import ToastController from '../ToastController/ToastController'
import { build, actions } from '../container_helpers'
import IndependentEntityForm from '../Forms/IndependentEntity/IndependentEntityForm'

class Accounts extends Component {
  constructor() {
    super()

    this.state = { accountsSelectedList: [] }
  }

  async componentWillMount() {
    await Promise.all([
      ..._.map(entitiesRaw, entityType => this.props[entityType.get](false)),
      this.props.getEnums('addresstypes'),
      this.props.getEnums('phonenumbertypes'),
      this.props.getEnums('recommendedactions'),
      this.props.getEnums('hedgedocumenttypes'),
      this.props.getEnums('emailtypes'),
      this.props.getEnums('hedgerequirementtypes'),
      this.props.getEnums('leistatuses'),
    ])
  }

  sidebarEntityForm = () => {
    if (!this.props.sidebar.sidebarOpen) return <div />

    const entityType = this.props.selectedEntityType
    const accountInstance = this.props.selectedEntity
    const parent = this.props.selectedEntityParent || { id: '' }

    // const latestSelection = _.last(this.state.accountsSelectedList)
    // // an entity or entity instance may be a child of a bank entity or a bank instance
    // // in this case we want the bankId to be passed to some forms for default association
    // let bankId
    // let bankInstance
    // const bankEntityIndex = _.findIndex(this.state.accountsSelectedList, {
    //   name: 'banks',
    // })

    // if (bankEntityIndex >= 0) {
    //   bankInstance = this.state.accountsSelectedList[bankEntityIndex + 1]
    //   bankId = bankInstance && bankInstance.id
    // }

    // // if the latestSelection (selected item down a list of items) is an instance
    // // the forms will (roughly, due to need of refactoring) be in an edit mode
    // let accountInstance
    // if (this.selectionIsEntityInstance(latestSelection)) {
    //   accountInstance = latestSelection
    // }
    switch (accountInstance
      ? accountInstance.entityType || entityType.type
      : entityType.type) {
      case 'IndependentEntity':
      case 'Tappian':
        return <IndependentEntityForm selected={accountInstance} />
      case 'Bank':
        return <BankForm selected={accountInstance} />
      case 'BankCustomer':
        return (
          <BankCustomerForm bankId={parent.id} selected={accountInstance} />
        )
      case 'CounterParty':
        return (
          <CounterpartyForm bankId={parent.id} selected={accountInstance} />
        )
      case 'PrivateEquity':
        return <PrivateEquityForm selected={accountInstance} />
      case 'Broker':
        return <BrokerForm bankId={parent.id} selected={accountInstance} />
      default:
        return (
          <UserForm
            entity={entityType}
            selected={accountInstance}
            associatedEntityId={parent.id}
          />
        )
    }
  }

  selectionIsEntityInstance = selection => selection && selection.id

  entityInstanceView() {
    // // the existing functionality presents a view for entity instances
    // // this view is (i.e. should be) displayed when an instance is clicked
    // const latestSelection = _.last(this.state.accountsSelectedList)

    // if (!this.selectionIsEntityInstance(latestSelection)) return null

    // // the list view alternates
    // // entity [e.g. 'Bank' ]
    // //   -> entity instance [e.g. Bank of Delaware with ID 100 ]
    // //        -> entity [e.g. 'Bank Customer']
    // // the parent here will be an entity type (descriptor object)
    // const entityType = _.cloneDeep(_.nth(this.state.accountsSelectedList, -2))
    // let associatedEntityInstance
    // let associatedEntityId
    // const associatedEntityType = entityType.associatedEntityType

    // if (associatedEntityType) {
    //   if (!Array.isArray(associatedEntityType)) {
    //     associatedEntityId = latestSelection[entityType.foreignKey]
    //     associatedEntityInstance = _.find(this.props[associatedEntityType], {
    //       id: associatedEntityId,
    //     })
    //   } else {
    //     associatedEntityId = latestSelection[entityType.foreignKey]
    //     const instances = associatedEntityType.map(type => {
    //       return {
    //         instance: _.find(this.props[type], {
    //           id: associatedEntityId,
    //         }),
    //         type,
    //       }
    //     })
    //     const foundInstance = instances.find(item => Boolean(item.instance))
    //     associatedEntityInstance = foundInstance ? foundInstance.instance : null
    //     entityType.associatedEntityType = associatedEntityInstance
    //       ? foundInstance.type
    //       : ''
    //   }
    // }
    if (!this.props.selectedEntity || !this.props.selectedEntityType)
      return null
    return (
      <EntityShows
        selectedEntity={this.props.selectedEntity}
        entityType={this.props.selectedEntityType}
      />
    )
  }

  render() {
    sidebarStyles.root.visibility = !this.props.sidebar.sidebarOpen
      ? 'collapse'
      : 'visible'
    return (
      <div className={styles.accounts}>
        <ToastController />
        <div className="row">
          <div className="page-title">Accounts</div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <div className="entities-section">
              <div>
                <EntityItemList
                  independents={this.props.independents}
                  banks={this.props.banks}
                  bankCustomers={this.props.bankCustomers}
                  privateEquities={this.props.privateEquities}
                  counterParties={this.props.counterParties}
                  clearingAgents={this.props.clearingAgents}
                  users={this.props.users}
                  brokers={this.props.brokers}
                  entityTypes={entitiesRaw}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-7">{this.entityInstanceView()}</div>
          <Sidebar
            rootClassName={
              !this.props.sidebar.sidebarOpen ? (
                'displayed display-none'
              ) : (
                'displayed'
              )
            }
            sidebar={this.sidebarEntityForm()}
            sidebarClassName={'create-form-sidebar'}
            open={this.props.sidebar.sidebarOpen}
            onSetOpen={this.props.toggleSidebar}
            styles={sidebarStyles}
            pullRight
          >
            <b />
          </Sidebar>
        </div>
      </div>
    )
  }
}

Accounts.propTypes = {
  getEnums: PropTypes.func,
  toggleSidebar: PropTypes.func,
  sidebar: PropTypes.shape({
    sidebarOpen: PropTypes.bool,
    entity: PropTypes.oneOfType([
      EntityPropType.bankCustomerPropType,
      EntityPropType.bankPropType,
      EntityPropType.brokerPropType,
      EntityPropType.privateEquityPropType,
      EntityPropType.userPropType,
    ]),
  }).isRequired,
  independents: PropTypes.arrayOf(EntityPropType.bankPropType),
  users: PropTypes.arrayOf(EntityPropType.userPropType),
  banks: PropTypes.arrayOf(EntityPropType.bankPropType),
  counterParties: PropTypes.arrayOf(EntityPropType.counterpartyPropType),
  brokers: PropTypes.arrayOf(EntityPropType.brokerPropType),
  privateEquities: PropTypes.arrayOf(EntityPropType.privateEquityPropType),
  bankCustomers: PropTypes.arrayOf(EntityPropType.bankCustomerPropType),
  clearingAgents: PropTypes.arrayOf(EntityPropType.clearingAgentPropType),
  selectedEntity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
  selectedEntityType: EntityPropType.entityTypePropType,
  selectedEntityParent: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
}

const mapDispatchToProps = {
  createUser: actions.user.create,
  updateUser: actions.user.update,
  createBank: actions.bank.create,
  selectParent: actions.accounts.parent.set,
  getUser: actions.user.get,
  getAllIndependents: actions.independentEntity.getAll,
  getAllBanks: actions.bank.getAll,
  getAllBankCustomers: actions.bankCustomer.getAll,
  getAllUsers: actions.user.getAll,
  getAllBrokers: actions.broker.getAll,
  getAllPrivateEquities: actions.privateEquity.getAll,
  getAllClearingAgents: actions.clearingAgent.getAll,
  getAllCounterparty: actions.counterparty.getAll,
  toggleSidebar: actions.sidebar.toggle,
  getEnums: actions.enum.getAll,
}

const mapStateToProps = state => {
  return {
    sidebar: state.sidebar,
    users: state.user,
    independents: state.independentEntity,
    banks: state.bank,
    brokers: state.broker,
    bankCustomers: state.bankCustomer,
    clearingAgents: state.clearingAgent,
    privateEquities: state.privateEquity,
    counterParties: state.counterparty,
    selectedEntity: state.accounts.entity,
    selectedEntityType: state.accounts.entityType,
    selectedEntityParent: state.accounts.parent,
  }
}

export default build({
  component: Accounts,
  mapDispatchToProps,
  mapStateToProps,
})
