import PropTypes from 'prop-types'
import React, { Component } from 'react'
import FileReaderInput from 'react-file-reader-input'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import actions from '../../../store/actions'
import './TradeImport.styl'

class TradeImport extends Component {
  constructor() {
    super()
    this.state = {
      files: [],
      loading: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    const haveResult = nextProps.importSuccess || nextProps.importError
    if (this.state.loading && haveResult) {
      this.setState({ loading: false })
    }
  }

  handleFile = (e, results) => {
    this.setState({ files: results.map(x => x[1]) })
  }

  handleSubmit = async e => {
    e.preventDefault()
    await Promise.all(this.state.files.map(x => this.props.importCSV(x)))
    this.setState({ files: [], loading: true })
  }

  resetForm = () => {
    this.props.resetImportData()
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="text-center">
          {this.state.loading && (
            <div className="entry">Importing...<CircularProgress/></div>            
          )}
          {this.props.importSuccess && (
            <div className="entry">Successfully imported!</div>
          )}
          {this.props.importError && (
            <div className="entry">Error: {this.props.importError}</div>
          )}
          {(this.props.importSuccess || this.props.importError) && (
            <div className="entry">
              <button onClick={this.resetForm}>Upload again</button>
            </div>
          )}

          {!this.props.importError &&
          !this.props.importSuccess &&
          !this.state.loading && (
            <div className="entry">
              <form onSubmit={this.handleSubmit}>
                <FileReaderInput
                  as="text"
                  id="my-file-input"
                  name="Trade Blotter CSV"
                  accept=".csv,.CSV"
                  placeholder="Trade Blotter CSV"
                  className="inputClass"
                  onChange={this.handleFile}
                  multiple
                >
                  <button type="button">Select a file!</button>
                </FileReaderInput>
                {Boolean(this.state.files.length) && (
                  <button className="import-button">Import!</button>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    )
  }
}

TradeImport.propTypes = {
  importCSV: PropTypes.func.isRequired,
  resetImportData: PropTypes.func.isRequired,
  importSuccess: PropTypes.bool,
  importError: PropTypes.number,
}

const mapDispatchToProps = {
  importCSV: actions.import.tradesCsv,
  resetImportData: actions.import.resetTrades,
}

const mapStateToProps = state => {
  return {
    importSuccess: state.import.success,
    importError: state.import.error,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeImport)
