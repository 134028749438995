import React from 'react'
import PropTypes from 'prop-types'
import { RaisedButton, MenuItem } from 'material-ui'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { build, actions } from '../../container_helpers'
import Input from '../../UtilsComponents/Input/Input'
import { addButtonStyle, addButtonLabelStyle } from '../../../styles'
import { NUMBER_REGEX } from '../../../constants'
import { validateUserForm } from '../../../helpers/formsValidators'
import { userPropType } from '../../shared_prop_types'
import Dropdown from '../../Dropdown/Dropdown'
import EntityAssociations from '../EntityAssociations/EntityAssociations'
import EntityFormWrapper from '../Entity/EntityFormWrapper'
import { useEntityForm } from '../Entity/entityHooks'
import { mergeEntities } from '../../../helpers/tradeBlotterUtils'
import '../SidebarForm.styl'

const onlyNumbers = event => {
  const pattern = new RegExp(NUMBER_REGEX)
  if (!pattern.test(event.key)) {
    event.preventDefault()
  }
}

const UserForm = props => {
  const mergedEntities = mergeEntities(
    props.banks,
    props.bankCustomers,
    props.brokers,
    props.privateEquities,
    props.clearingAgents,
    props.independentEntities
  )
  const associatedEntity = mergedEntities.find(
    x => x.id == props.associatedEntityId
  )

  const defaults = {
    id: '',
    isAdmin: false,
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    associatedEntityId: props.associatedEntityId || '',
    associatedEntities: associatedEntity ? [associatedEntity] : null,
    associatedEntityIds: props.associatedEntityId
      ? [props.associatedEntityId]
      : null,
    role: '',
    isTwoFactorRegistered: false,
    permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
  }

  const { selected, getUser, getAllRoles, updateUser, createUser } = props
  const [newUser, saveUser, errors, setErrors, submit] = useEntityForm({
    selected,
    defaults,
    getEntity: getUser,
    updateEntity: updateUser,
    createEntity: createUser,
    getData: async () => getAllRoles(),
  })

  // eslint-disable-next-line react/sort-comp
  const validateForm = async () => {
    const validationResult = validateUserForm(newUser, errors, props.users)
    setErrors(validationResult.errors)

    if (validationResult.valid) {
      submit()
    }
  }

  const renderDetails = () => (
    <div className="create">
      <div className="sidebar-title">
        {!_.isEmpty(props.selected) ? 'Edit User' : 'Add User'}
      </div>
      <div className="create-form">
        <div className="account-type">
          <div className="create-title">Account Type</div>
          <div className="is-admin-checkbox">
            <div>
              <Dropdown
                floatingLabelText="Role"
                value={newUser.role}
                onChange={e => saveUser({ role: e.target.value })}
                errorText={errors.role}
                hintText="Select a Role"
              >
                {props.roles.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="entity-details">
          <div className="create-title">Account Details</div>

          <br />

          {!_.isEmpty(props.selected) && (
            <div className="row">
              <div className="col">
                <Button disabled className="margin-left-half">
                  Two Factor Registered
                  <span className="margin-left">
                    {newUser.isTwoFactorRegistered ? 'True' : 'False'}
                  </span>
                </Button>
              </div>
              <div className="col">
                <Button
                  className="margin-left-half"
                  onClick={() => props.resetTwoFactor(newUser.id)}
                >
                  Reset Two Factor
                </Button>
              </div>
              <div className="col">
                <Button
                  className="margin-left-half"
                  onClick={() => props.resetPassword(newUser.email)}
                >
                  Reset Password
                </Button>
              </div>
              <div className="col">
                <Button
                  className="margin-left-half"
                  onClick={() => props.sendWelcomeEmail(newUser.id)}
                >
                  Send Welcome Email
                </Button>
              </div>

              <div className="col">
                <Button
                  className="margin-left-half"
                  onClick={() => props.loginAsUser(newUser)}
                >
                  Login As User
                </Button>
              </div>
            </div>
          )}
          <div className="row">
            <Input
              name="firstName"
              title="First Name"
              defaultValue={newUser.firstName}
              className="col-xs-6"
              onChange={e => {
                saveUser({ firstName: e.target.value })
              }}
              errorText={errors.firstName}
            />

            <Input
              name="lastName"
              title="Last Name"
              defaultValue={newUser.lastName}
              className="col-xs-6"
              onChange={e => {
                saveUser({ lastName: e.target.value })
              }}
              errorText={errors.lastName}
            />
          </div>

          <div className="row">
            <Input
              name="userName"
              title="User Name"
              defaultValue={newUser.userName}
              className="col-xs-6"
              onChange={e => {
                saveUser({ userName: e.target.value })
              }}
              errorText={errors.userName}
            />

            <Input
              name="email"
              title="Email"
              defaultValue={newUser.email}
              className="col-xs-6"
              onChange={e => {
                saveUser({ email: e.target.value })
              }}
              errorText={errors.email}
            />
          </div>

          <div className="row">
            <Input
              name="phoneNumber"
              title="Phone Number (Optional)"
              defaultValue={newUser.phoneNumber}
              className="col-xs-6"
              onChange={e => {
                saveUser({ phoneNumber: e.target.value })
              }}
              onKeyPress={onlyNumbers}
            />
          </div>

          <br />
          <EntityAssociations
            associated={newUser.associatedEntities}
            entities={mergedEntities}
            onChange={associatedEntities => {
              saveUser({
                associatedEntityIds: associatedEntities.map(x => x.id),
                associatedEntities,
              })
            }}
          />
        </div>
      </div>
      <div className="add-button-bg">
        <RaisedButton
          primary
          label={!_.isEmpty(props.selected) ? 'Save' : 'Add'}
          onClick={() => {
            validateForm()
          }}
          style={addButtonStyle}
          buttonStyle={addButtonStyle}
          labelStyle={addButtonLabelStyle}
        />
      </div>
    </div>
  )
  return (
    // <Tabs>
    //   <TabList>
    //     <Tab>Details</Tab>
    //     <Tab>Permissions</Tab>
    //   </TabList>

    //   <TabPanel>{renderDetails()}</TabPanel>
    //   <TabPanel>
    //     <PermissionsTab
    //       permissions={newUser.permissions}
    //       onChanged={permissions => saveUser({ ...newUser, permissions })}
    //     />
    //   </TabPanel>
    // </Tabs>
    <EntityFormWrapper
      newEntity={newUser}
      setEntity={saveUser}
      render={renderDetails}
    />
  )
}

UserForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  resetTwoFactor: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  sendWelcomeEmail: PropTypes.func.isRequired,
  loginAsUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(userPropType).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAllRoles: PropTypes.func,
  createUser: PropTypes.func.isRequired,
  selected: userPropType,
  associatedEntityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  independentEntities: PropTypes.arrayOf(PropTypes.object),
}

const mapDispatchToProps = {
  createUser: actions.user.create,
  updateUser: actions.user.update,
  resetTwoFactor: actions.user.resetTwoFactor,
  resetPassword: actions.resetPassword.resetPassword,
  sendWelcomeEmail: actions.user.sendWelcomeEmail,
  selectUser: actions.select,
  getAllRoles: actions.role.getAll,
  loginAsUser: actions.login.loginAsUser,
  getUser: actions.user.get,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    bankCustomers: state.bankCustomer,
    counterparties: state.counterparty,
    brokers: state.broker,
    privateEquities: state.privateEquity,
    clearingAgents: state.clearingAgent,
    independentEntities: state.independentEntity,
    users: state.user,
    roles: state.role,
  }
}

export default build({
  component: UserForm,
  mapDispatchToProps,
  mapStateToProps,
})
