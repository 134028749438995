import PropTypes from 'prop-types'

export const bankPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  leiNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const bankCustomerPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  bank: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  leiNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  type: PropTypes.string,
  zip: PropTypes.string,
})

export const brokerPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  rate: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const clearingAgentPropType = PropTypes.shape({
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  address: PropTypes.string,
  bank: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const counterpartyPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  bank: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const loginPropType = PropTypes.shape({
  loginUser: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  errors: PropTypes.string,
})

export const pdfPropType = PropTypes.shape({
  success: PropTypes.bool,
  error: PropTypes.object,
})

export const privateEquityPropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  faxNumber: PropTypes.string,
  leiNumber: PropTypes.string,
  monthlyFee: PropTypes.double,
  phoneNumber: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

export const tradeBlotterPropType = PropTypes.shape({
  buyerId: PropTypes.number,
  buyerEntityType: PropTypes.string,
  sellerId: PropTypes.number,
  sellerEntityType: PropTypes.string,
  brokerId: PropTypes.number,
  brokerRate: PropTypes.number,
  brokerUserId: PropTypes.string,
  traderName: PropTypes.string,
  index: PropTypes.string,
  productType: PropTypes.string,
  product: PropTypes.string,
  instrumentType: PropTypes.string,
  monthlyVolume: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  strikePrice: PropTypes.number,
  tradePremiumPrice: PropTypes.number,
  tradeDateTime: PropTypes.string,
  settlementType: PropTypes.string,
  expirationDate: PropTypes.string,
  paymentDays: PropTypes.number,
})

export const userPropType = PropTypes.shape({
  email: PropTypes.string,
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
})

export const entityTypePropType = PropTypes.shape({
  title: PropTypes.string,
  associationWith: PropTypes.string,
  associatedEntityType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      attr: PropTypes.arrayOf(PropTypes.string),
    })
  ),
})

export const tradeActionPropType = PropTypes.shape({
  id: PropTypes.number,
  firstEntityId: PropTypes.number,
  secondEntityId: PropTypes.number,
  secondEntityType: PropTypes.string,
  tradePrice: PropTypes.number,
  action: PropTypes.string,
  actionDisabled: PropTypes.bool,
})

export const productEntryPropType = PropTypes.shape({
  id: PropTypes.string,
  quantity: PropTypes.number,
  quantityType: PropTypes.string,
  instrumentType: PropTypes.string,
})

export const tradeEntryPropType = PropTypes.shape({
  traderName: PropTypes.string,
  tradeDateTime: PropTypes.PropTypes.instanceOf(Date),
  startDate: PropTypes.PropTypes.instanceOf(Date),
  endDate: PropTypes.PropTypes.instanceOf(Date),
  brokerId: PropTypes.number,
  brokerRate: PropTypes.number,
  productEntry: productEntryPropType,
  tradeActions: PropTypes.arrayOf(tradeActionPropType),
  valid: PropTypes.bool,
})

export const productPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  productType: PropTypes.string,
  index: PropTypes.string,
  unitsPerContract: PropTypes.number,
  settlementType: PropTypes.string,
  exerciseStyle: PropTypes.string,
  unitCurrency: PropTypes.string,
  paymentDays: PropTypes.number,
  unitType: PropTypes.string,
  isOption: PropTypes.bool,
})

export const enumPropType = PropTypes.shape({
  id: PropTypes.string,
  displayName: PropTypes.string,
})

export const startEndDateRouteType = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
  }).isRequired,
})

export const settleDateRouteType = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    settleDate: PropTypes.string.isRequired,
  }).isRequired,
})

export const addressType = PropTypes.shape({
  type: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  note: PropTypes.string,
})

export const hedgelineType = PropTypes.shape({
  value: PropTypes.number,
  writtenDate: PropTypes.string,
  approvedDate: PropTypes.string,
})

export const phoneNumberType = PropTypes.shape({
  type: PropTypes.string,
  note: PropTypes.string,
  number: PropTypes.string,
})

export const emailType = PropTypes.shape({
  type: PropTypes.string,
  emailAddress: PropTypes.string,
  note: PropTypes.string,
})

export const hedgeRequirementType = PropTypes.shape({
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  minTenor: PropTypes.string,
  maxTenor: PropTypes.string,
})
