import { createActions } from 'redux-actions'

const { role: roleActions } = createActions({
  ROLE: {
    SET: undefined,
  },
})

roleActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get(`api/account/roles`)
  dispatch(roleActions.set(response.data))
}

export default roleActions
