import _ from 'lodash'

export const ERRORS = {
  INPUT: {
    empty: 'This field is required',
    invalidEmail: 'Invalid email',
    invalidPassword: 'Password must be at least 6 characters long',
    duplicatedEmail: 'This email is already taken',
    duplicatedUserName: 'This username is already taken',
    noFloating: 'This field is invalid',
  },
}

export const NUMBER_REGEX = /^\d+$/

export const FLOATING_REGEX_KEY_UP = /^[0-9.]+$/

export const FLOATING_REGEX_SUBMIT = /^\d+(?:\.\d+)?$/

export const entitiesRaw = {
  IndependentEntity: {
    type: 'IndependentEntity',
    get: 'getAllIndependents',
    delete: 'deleteIndependentEntity',
    title: 'Independent Entities',
    name: 'independents',
    showAttr: ['name'],
    subEntities: ['BankCustomer', 'Counterparty', 'User', 'Broker'],
    fields: [
      { title: 'Active', attr: ['isActive'] },
      { title: 'Name', attr: ['name'] },
      { title: 'Display Name', attr: ['displayName'] },
      { title: 'Abbreviation', attr: ['abbreviation'] },
      { title: 'LEI Number', attr: ['leiNumber'] },
      { title: 'LEI Status', attr: ['leiStatus'] },
      { title: 'LEI Renewal Date', attr: ['leiRenewalDate'] },
      { title: 'LEI Weblink', attr: ['leiWeblink'] },
      {
        title: 'Contact Numbers',
        attr: ['phoneNumbers'],
        type: 'PhoneNumbers',
      },
      { title: 'Emails', attr: ['emails'], type: 'Emails' },
      { title: 'Addresses', attr: ['addresses'], type: 'Addresses' },
      { title: 'Routing Number', attr: ['routingNumber'] },
      { title: 'Account Number', attr: ['accountNumber'] },
      { title: 'Logo', attr: ['logo'], type: 'img' },
      {
        title: 'Swap Data Repository Name',
        attr: ['swapDataRepositoryName'],
      },
    ],
  },
  Bank: {
    type: 'Bank',
    get: 'getAllBanks',
    delete: 'deleteBank',
    title: 'Banks',
    name: 'banks',
    showAttr: ['name'],
    subEntities: ['BankCustomer', 'Counterparty', 'User', 'Broker'],
    fields: [
      { title: 'Active', attr: ['isActive'] },
      { title: 'Name', attr: ['name'] },
      { title: 'Display Name', attr: ['displayName'] },
      { title: 'Abbreviation', attr: ['abbreviation'] },
      { title: 'LEI Number', attr: ['leiNumber'] },
      { title: 'LEI Status', attr: ['leiStatus'] },
      { title: 'LEI Renewal Date', attr: ['leiRenewalDate'] },
      { title: 'LEI Weblink', attr: ['leiWeblink'] },
      {
        title: 'Contact Numbers',
        attr: ['phoneNumbers'],
        type: 'PhoneNumbers',
      },
      { title: 'Emails', attr: ['emails'], type: 'Emails' },
      { title: 'Addresses', attr: ['addresses'], type: 'Addresses' },
      { title: 'Routing Number', attr: ['routingNumber'] },
      { title: 'Account Number', attr: ['accountNumber'] },
      { title: 'Logo', attr: ['logo'], type: 'img' },
      {
        title: 'Swap Data Repository Name',
        attr: ['swapDataRepositoryName'],
      },
    ],
  },
  BankCustomer: {
    type: 'BankCustomer',
    get: 'getAllBankCustomers',
    delete: 'deleteBankCustomer',
    title: 'Bank Customers',
    name: 'bankCustomers',
    showAttr: ['name'],
    filter: 'bankId',
    subEntities: ['User'],
    foreignKey: 'bankId',
    associatedEntityType: 'banks',
    fields: [
      { title: 'Active', attr: ['isActive'] },
      { title: 'Name', attr: ['name'] },
      { title: 'Display Name', attr: ['displayName'] },
      { title: 'Type', attr: ['companyType'] },
      { title: 'LEI Number', attr: ['leiNumber'] },
      { title: 'Associated to', attr: [''] },
      { title: 'Attention Person', attr: ['attentionPerson'] },
      { title: 'Relationship Manager', attr: ['relationshipManager'] },
      { title: 'ISDA Execution Date', attr: ['isdaExecutionDate'] },
      { title: 'Routing Number', attr: ['routingNumber'] },
      { title: 'Account Number', attr: ['accountNumber'] },
      {
        title: 'Contact Numbers',
        attr: ['phoneNumbers'],
        type: 'PhoneNumbers',
      },
      { title: 'Emails', attr: ['emails'], type: 'Emails' },
      { title: 'Addresses', attr: ['addresses'], type: 'Addresses' },
      { title: 'Hedge Lines', attr: ['hedgeLines'], type: 'Hedgelines' },
      {
        title: 'Hedge Requirements',
        attr: ['hedgeRequirements'],
        type: 'HedgeRequirements',
      },
    ],
  },
  // PrivateEquities: {
  //   type: 'PrivateEquity',
  //   get: 'getAllPrivateEquities',
  //   title: 'Private Equities',
  //   name: 'privateEquities',
  //   showAttr: ['name'],
  //   subEntities: ['Users'],
  //   delete: 'deletePrivateEquity',
  //   fields: [
  //     { title: 'Name', attr: ['name'] },
  //     { title: 'Display Name', attr: ['displayName'] },
  //     { title: 'Abbreviation', attr: ['abbreviation'] },
  //     { title: 'LEI Number', attr: ['leiNumber'] },
  //     { title: 'Phone Number', attr: ['phoneNumber'] },
  //     { title: 'Fax Number', attr: ['faxNumber'] },
  //     { title: 'Location', attr: ['address', 'city', 'state', 'zip'] },
  //     { title: 'Monthly Fee', attr: ['monthlyFee'] },
  //   ],
  // },
  Broker: {
    type: 'Broker',
    get: 'getAllBrokers',
    delete: 'deleteBroker',
    title: 'Brokers',
    name: 'brokers',
    showAttr: ['name'],
    filter: 'bankId',
    subEntities: ['User'],
    foreignKey: 'bankId',
    associatedEntityType: 'banks',
    fields: [
      { title: 'Active', attr: ['isActive'] },
      { title: 'Name', attr: ['name'] },
      { title: 'Display Name', attr: ['displayName'] },
      { title: 'Rate', attr: ['rate'] },
      { title: 'Phone Number', attr: ['phoneNumber'] },
      { title: 'Fax Number', attr: ['faxNumber'] },
      {
        title: 'Contact Numbers',
        attr: ['phoneNumbers'],
        type: 'PhoneNumbers',
      },
      { title: 'Emails', attr: ['emails'], type: 'Emails' },
      { title: 'Addresses', attr: ['addresses'], type: 'Addresses' },
    ],
  },
  Counterparty: {
    type: 'CounterParty',
    get: 'getAllCounterparty',
    delete: 'deleteCounterparty',
    title: 'Counterparties',
    name: 'counterParties',
    showAttr: ['name'],
    filter: 'bankId',
    subEntities: ['User'],
    foreignKey: 'bankId',
    associatedEntityType: 'banks',
    fields: [
      { title: 'Active', attr: ['isActive'] },
      { title: 'Name', attr: ['name'] },
      { title: 'Display Name', attr: ['displayName'] },
      { title: 'Associated to', attr: [''] },
      { title: 'ISDA Execution Date', attr: ['isdaExecutionDate'] },
      {
        title: 'Contact Numbers',
        attr: ['phoneNumbers'],
        type: 'PhoneNumbers',
      },
      { title: 'Emails', attr: ['emails'], type: 'Emails' },
      { title: 'Addresses', attr: ['addresses'], type: 'Addresses' },
      { title: 'Hedge Lines', attr: ['hedgeLines'], type: 'Hedgelines' },
    ],
  },
  User: {
    get: 'getAllUsers',
    delete: 'deleteUser',
    title: 'Users',
    name: 'users',
    showAttr: ['firstName', 'lastName'],
    foreignKey: 'associatedEntityId',
    associatedEntityType: [
      'banks',
      'bankCustomers',
      'brokers',
      'counterparties',
      'privateEquities',
    ],
    fields: [
      { title: 'Role', attr: ['role'] },
      { title: 'User Name', attr: ['userName'] },
      { title: 'Email', attr: ['email'] },
      { title: 'Name', attr: ['firstName', 'lastName'] },
      { title: 'Phone Number', attr: ['phoneNumber'] },
      { title: 'Fax Number', attr: ['faxNumber'] },
      { title: 'Location', attr: ['address', 'city', 'state', 'zip'] },
    ],
  },
}

export const screenHeight = () => screen.height

export const menuItems = {
  dashboard: {
    link: 'dashboard',
    name: 'Dashboard',
  },

  'trade-blotter': {
    link: 'trade-blotter',
    name: 'Trade Blotter',
  },

  'mark-to-market': {
    link: 'mark-to-market',
    name: 'Mark to Market',
  },

  'threshold-summary': {
    link: 'threshold-summary',
    name: 'Threshold',
  },
  // positionReporting: {
  //   link: 'position-reporting',
  //   name: 'Position Reporting',
  // },

  accounts: {
    link: 'accounts',
    name: 'Accounts',
  },

  'data-import': {
    link: 'data-import',
    name: 'Data Import',
  },

  'report-generator': {
    link: 'report-generator',
    name: 'Report Generator',
  },
}

// export const roleAccess = {
//   Admin: Object.keys(menuItems),
//   BankAdmin: _.difference(Object.keys(menuItems), ['accounts', 'dataImport']),
//   BankUser: _.difference(Object.keys(menuItems), ['accounts', 'dataImport']),
//   User: _.difference(Object.keys(menuItems), [
//     'accounts',
//     'dataImport',
//     'markToMarket',
//     'threshold',
//   ]),
// }

export const userOptions = [
  { linkTo: '#', text: 'Logout', action: 'logout' },
  { linkTo: '#', text: 'Change Password', action: 'changePassword' },
]

// Which fields (from blotter_columns.js) can each role NOT see.
export const tradeBlotterColumnsByRole = {
  TappianAdmin: [],
  IEAdmin: ['productCode'],
  IEUser: [
    'productCode',
    'paymentDays',
    'livePrice',
    'active',
    'isExpired',
    'isExpirationDateOverriden',
    'isPaymentDateOverriden',
  ],
  User: [
    'usiNumber',
    'traderName',
    'buyerUserName',
    'sellerUserName',
    'brokerRate',
    'brokerUserName',
    'brokerName',
    'expirationDate',
    'productCode',
    'paymentDays',
    'paymentDate',
    'tradeMarkToMarket',
    'netOptionPremium',
    'net',
    'closePrice',
    'livePrice',
    'active',
    'isExpired',
    'isExpirationDateOverriden',
    'isPaymentDateOverriden',
  ],
}
