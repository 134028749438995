import React from 'react'
import './ChangePassword.styl'
import '../../application.styl'
import LoginSignUpBg from '../LoginSignUpBg/LoginSignUpBg'
import ChangePasswordForm from '../Forms/ChangePassword/ChangePasswordForm'

const ChangePassword = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-xs-12 padding-zero margin-zero">
        <LoginSignUpBg />
        <div className="col-xs-4 form-container">
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  </div>
)
export default ChangePassword
