import { handleActions } from 'redux-actions'
import moment from 'moment'

const defaultState = {
  traderName: 'Scott Lape',
  tradeDateTime: null,
  startDate: moment().toDate(),
  endDate: moment().toDate(),
  brokerId: null,
  brokerRate: null,
  usiNumber: '',
  cmeTradeId: '',
  productEntry: {
    id: null,
    productType: '',
    quantity: null,
    instrumentType: null,
    quantityType: 'UnitsPerMonth',
    associatedGroupId: 0,
  },
  tradeActions: [
    {
      id: 0,
      firstEntityId: null,
      secondEntityId: null,
      secondEntityType: 'counterparty',
      firstEntityType: '',
      tradePrice: null,
      action: 'Sells',
      actionDisabled: false,
    },
  ],
  valid: false,
  nextTID: null,
  nextGID: null,
}

const tradeActionIsComplete = tradeAction =>
  tradeAction &&
  tradeAction.firstEntityId &&
  tradeAction.secondEntityId &&
  tradeAction.tradePrice

const tradeActionUpdate = (state, payload) => {
  let tradeActions = state.tradeActions
  if (tradeActions.length > 1) {
    tradeActions = state.tradeActions.map(item => {
      if (item === tradeActions[0]) return item
      return {
        ...item,
        action: tradeActions[0].action === 'Buys' ? 'Sells' : 'Buys',
      }
    })
  }

  if (!tradeActionIsComplete(payload)) {
    return {
      ...state,
      tradeActions,
    }
  }

  if (tradeActions.length === 1) {
    tradeActions = state.tradeActions.concat({
      ...tradeActions[0],
      id: tradeActions.length,
      secondEntityId: null,
      tradePrice: null,
      action: tradeActions[0].action === 'Buys' ? 'Sells' : 'Buys',
      actionDisabled: true,
      secondEntityType:
        tradeActions[0].secondEntityType === 'counterparty'
          ? 'bankCustomer'
          : 'counterParty',
    })
  }
  return {
    ...state,
    tradeActions,
  }
}

const reducerMap = {
  RESET: () => {
    return {
      ...defaultState,
    }
  },
  SET: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    }
  },
  'TRADEACTIONS/UPDATE': (state, { payload }) => {
    const newState = {
      ...state,
      tradeActions: state.tradeActions.map(
        item => (item.id === payload.id ? { ...item, ...payload } : item)
      ),
    }
    return tradeActionUpdate(newState, payload)
  },
  'PRODUCTENTRY/UPDATE': (state, { payload }) => {
    return {
      ...state,
      productEntry: { ...state.productEntry, ...payload },
    }
  },
  'NEXT/TID': (state, { payload }) => {
    return {
      ...state,
      nextTID: payload,
    }
  },
  'NEXT/GID': (state, { payload }) => {
    return {
      ...state,
      nextGID: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
