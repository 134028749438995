import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RaisedButton } from 'material-ui'
import { MenuItem } from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
import FileReaderInput from 'react-file-reader-input'
import _ from 'lodash'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'
import Dropdown from '../../Dropdown/Dropdown'

import '../SidebarForm.styl'
import {
  addButtonStyle,
  addButtonLabelStyle,
  addPaddedButtonStyle,
} from '../../../styles'
import {
  bankPropType,
  userPropType,
  enumPropType,
} from '../../shared_prop_types'
import { NUMBER_REGEX } from '../../../constants'
import { validateBankForm } from '../../../helpers/formsValidators'
import Addresses from '../Addresses/Addresses'
import PhoneNumbers from '../PhoneNumbers/PhoneNumbers'
import Emails from '../Emails/Emails'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PermissionsTab from '../Permissions/PermissionsTab'

class IndependentEntityForm extends Component {
  static onlyNumbers(event) {
    const pattern = new RegExp(NUMBER_REGEX)
    if (!pattern.test(event.key)) {
      event.preventDefault()
    }
  }
  constructor(props) {
    super(props)
    const selected = this.props.selected
    this.state = {
      newEntity: {
        ...{
          name: '',
          displayName: '',
          abbreviation: '',
          accountNumber: '',
          routingNumber: '',
          cmeAccountNumber: '',
          usiPrefix: '',
          logo: false,
          addresses: [],
          phoneNumbers: [],
          emails: [],
          hedgeRequirements: [],
          recommendedAction: 'None',
          potentialHedgeDocumentType: 'None',
          maturityDate: '',
          ownership: 0,
          leiNumber: '',
          leiStatus: 'Inactive',
          leiWeblink: '',
          leiRenewalDate: null,
          attentionPerson: '',
          relationshipManager: '',
          permissions: { pages: {}, reports: {}, tradeBlotterColumns: {} },
          swapDataRepositoryName: '',
        },
        ...selected,
      },
      blob: '',
      selectedLogo: '',
      errors: {
        faxNumber: '',
        phoneNumber: '',
      },
    }
  }

  validateForm() {
    const validationResult = validateBankForm(
      this.state.newEntity,
      this.state.errors
    )
    this.setState({ ...this.state.errors, errors: validationResult.errors })
    /**
      * If there's no error, then create the bank
      */
    if (validationResult.valid) {
      this.createEntity()
    }
  }

  saveEntity = bankObj => {
    if (Object.values(bankObj)[0] !== '') {
      const upError = this.state.errors
      const propertyName = Object.keys(bankObj)[0]
      upError[propertyName] = ''
      this.setState(upError)
    }
    const upEntity = this.state.newEntity
    this.setState({ newEntity: { ...upEntity, ...bankObj } })
  }

  createEntity = async () => {
    const upEntity = this.state.newEntity
    let id = null
    let result
    if (!_.isEmpty(this.props.selected)) {
      upEntity.id = this.props.selected.id
      id = this.props.selected.id
      await this.props.updateEntity(upEntity)
    } else {
      result = await this.props.createEntity(upEntity)
      id = result.id
    }

    if (this.state.selectedLogo) {
      result = await this.props.uploadLogo(this.state.selectedLogo, id)
    }
    if (result) {
      this.props.toggleSidebar()
    }
  }

  handleLogo = (e, results) => {
    const reader = new FileReader()
    reader.readAsDataURL(results[0][1])
    reader.onload = async () => {
      this.setState({ blob: reader.result })
    }
    this.setState({ selectedLogo: results[0][1] })
  }

  renderUserDropdown = () => {
    const { users, selected } = this.props
    if (!selected) return null

    const filteredUsers = users.filter(
      x => x.associatedEntityId === selected.id
    )
    const items = [
      <MenuItem key="" value="">
        (None)
      </MenuItem>,
    ]

    return (
      <Dropdown
        floatingLabelText="Default User (Optional)"
        value={this.state.newEntity.defaultUserId}
        onChange={e => this.saveEntity({ defaultUserId: e.target.value })}
        errorText={this.state.errors.defaultUserId}
        hintText="Select a Default User (Optional)"
      >
        {items.concat(
          filteredUsers.map(name => (
            <MenuItem key={name.id} value={name.id}>
              {name.fullName}
            </MenuItem>
          ))
        )}
      </Dropdown>
    )
  }

  renderLeiStatus = () => (
    <Dropdown
      className="col-xs-3"
      floatingLabelText="LEI Status"
      value={this.state.newEntity.leiStatus}
      onChange={e => {
        this.saveEntity({ leiStatus: e.target.value })
      }}
      hintText="Select an LEI Status (Optional)"
    >
      {this.props.leiStatuses.map(x => (
        <MenuItem key={x.id} value={x.id}>
          {x.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )

  renderDetails() {
    const logo = `${process.env.API_URL}/${this.state.newEntity.logo}`
    return (
      <div className="create">
        <div className="sidebar-title">
          {!_.isEmpty(this.props.selected) ? 'Edit Entity' : 'Add Entity'}
        </div>
        <div className="create-form">
          <div className="entity-details">
            <div className="create-title"> Entity Details</div>
            <div className="row">
              <Input
                className="col-xs-3"
                name="name"
                title="Entity Name"
                defaultValue={this.state.newEntity.name}
                onChange={e => {
                  this.saveEntity({ name: e.target.value })
                }}
                errorText={this.state.errors.name}
              />
              <Input
                className="col-xs-3"
                name="displayName"
                title="Display Name"
                defaultValue={this.state.newEntity.displayName}
                onChange={e => {
                  this.saveEntity({ displayName: e.target.value })
                }}
                errorText={this.state.errors.displayName}
              />
              <Input
                className="col-xs-3"
                name="abbrevation"
                title="Abbreviation"
                defaultValue={this.state.newEntity.abbreviation}
                onChange={e => {
                  this.saveEntity({ abbreviation: e.target.value })
                }}
                errorText={this.state.errors.abbreviation}
              />
            </div>
            <br />
            <PhoneNumbers
              phoneNumbers={this.state.newEntity.phoneNumbers}
              onChange={phoneNumbers => {
                this.saveEntity({ phoneNumbers })
              }}
            />
            <br />
            <Emails
              emails={this.state.newEntity.emails}
              onChange={emails => {
                this.saveEntity({ emails })
              }}
            />
            <br />
            <Addresses
              addresses={this.state.newEntity.addresses}
              onChange={addresses => {
                this.saveEntity({ addresses })
              }}
            />

            <div className="row">
              <Input
                className="col-xs-3"
                name="leiNumber"
                title="LEI Number"
                errorText={this.state.errors.leiNumber}
                defaultValue={this.state.newEntity.leiNumber}
                onChange={e => {
                  this.saveEntity({ leiNumber: e.target.value })
                }}
              />

              {this.renderLeiStatus()}

              <DatePicker
                className="col-xs-3"
                name="leiRenewalDate"
                label="LEI Renewal Date"
                format="MM/DD/YYYY"
                views={['year', 'month', 'day']}
                value={this.state.newEntity.leiRenewalDate}
                onChange={date => {
                  this.saveEntity({ leiRenewalDate: date.toDate() })
                }}
                clearable
                showTodayButton
              />

              <Input
                className="col-xs-3"
                name="leiWeblink"
                title="LEI Weblink"
                errorText={this.state.errors.leiWeblink}
                defaultValue={this.state.newEntity.leiWeblink}
                onChange={e => {
                  this.saveEntity({ leiWeblink: e.target.value })
                }}
              />
            </div>

            <div className="row">
              <Input
                name="accountNumber"
                title="Account Number (Optional)"
                className="col-xs-3"
                defaultValue={this.state.newEntity.accountNumber}
                onChange={e => {
                  this.saveEntity({ accountNumber: e.target.value })
                }}
              />
              <Input
                name="routingNumber"
                title="Routing Number (Optional)"
                className="col-xs-3"
                defaultValue={this.state.newEntity.routingNumber}
                onChange={e => {
                  this.saveEntity({ routingNumber: e.target.value })
                }}
              />
              <Input
                name="usiPrefix"
                title="USI Prefix (Optional)"
                className="col-xs-3"
                defaultValue={this.state.newEntity.usiPrefix}
                onChange={e => {
                  this.saveEntity({ usiPrefix: e.target.value })
                }}
              />
              <Input
                name="attentionPerson"
                title="Attention Person"
                className="col-xs-3"
                defaultValue={this.state.newEntity.attentionPerson}
                onChange={e => {
                  this.saveEntity({ attentionPerson: e.target.value })
                }}
              />
            </div>
            <div className="row">
              <Input
                name="relationshipManager"
                title="Relationship Manager"
                className="col-xs-3"
                defaultValue={this.state.newEntity.relationshipManager}
                onChange={e => {
                  this.saveEntity({ relationshipManager: e.target.value })
                }}
              />
              {!_.isEmpty(this.props.selected) && this.renderUserDropdown()}

              <Input
                name="swapDataDepositoryName"
                title="Swap Data Repository Name"
                className="col-xs-3"
                defaultValue={this.state.newEntity.swapDataRepositoryName}
                onChange={e => {
                  this.saveEntity({ swapDataRepositoryName: e.target.value })
                }}
              />

              <img
                src={this.state.blob ? this.state.blob : logo}
                alt="logo"
                className="logo-inner"
              />
              <FileReaderInput
                as="text"
                id="my-file-input"
                name="Trade Blotter CSV"
                accept=".png,.jpg,.jpeg,.svg"
                placeholder="Logo"
                className="inputClass"
                onChange={this.handleLogo}
              >
                <button
                  className="button-left"
                  style={addPaddedButtonStyle}
                  type="button"
                >
                  Select a Logo
                </button>
              </FileReaderInput>
            </div>
          </div>
          <div className="add-button-bg">
            <RaisedButton
              primary
              label={!_.isEmpty(this.props.selected) ? 'Save' : 'Add'}
              onClick={() => {
                this.validateForm()
              }}
              style={addButtonStyle}
              buttonStyle={addButtonStyle}
              labelStyle={addButtonLabelStyle}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Permissions</Tab>
        </TabList>

        <TabPanel>{this.renderDetails()}</TabPanel>
        <TabPanel>
          <PermissionsTab
            permissions={this.state.newEntity.permissions}
            onChanged={permissions => this.saveEntity({ permissions })}
          />
        </TabPanel>
      </Tabs>
    )
  }
}

IndependentEntityForm.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  getAllEntitys: PropTypes.func.isRequired,
  createEntity: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  banks: PropTypes.arrayOf(bankPropType),
  users: PropTypes.arrayOf(userPropType),
  leiStatuses: PropTypes.arrayOf(enumPropType),
  selected: bankPropType,
}

const mapDispatchToProps = {
  createEntity: actions.bank.create,
  getAllEntitys: actions.bank.getAll,
  updateEntity: actions.bank.update,
  uploadLogo: actions.entity.uploadLogo,
  toggleSidebar: actions.sidebar.toggle,
}

const mapStateToProps = state => {
  return {
    banks: state.bank,
    sidebar: state.sidebar,
    users: state.user,
    leiStatuses: state.enum.leiStatuses,
  }
}

export default build({
  component: IndependentEntityForm,
  mapStateToProps,
  mapDispatchToProps,
})
