import { handleActions } from 'redux-actions'

const defaultState = {
  quantityTypes: [],
  instrumentTypes: [],
  addressTypes: [],
  phoneNumberTypes: [],
  recommendedActions: [],
  hedgeDocumentTypes: [],
  emailTypes: [],
  hedgeRequirementTypes: [],
  leiStatuses: [],
}

const reducerMap = {
  'ENUM/QUANTITYTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      quantityTypes: [...payload],
    }
  },
  'ENUM/INSTRUMENTTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      instrumentTypes: [...payload],
    }
  },
  'ENUM/ADDRESSTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      addressTypes: [...payload],
    }
  },
  'ENUM/PHONENUMBERTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      phoneNumberTypes: [...payload],
    }
  },
  'ENUM/RECOMMENDEDACTIONS/SET': (state, { payload }) => {
    return {
      ...state,
      recommendedActions: [...payload],
    }
  },
  'ENUM/HEDGEDOCUMENTTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      hedgeDocumentTypes: [...payload],
    }
  },
  'ENUM/EMAILTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      emailTypes: [...payload],
    }
  },
  'ENUM/HEDGEREQUIREMENTTYPES/SET': (state, { payload }) => {
    return {
      ...state,
      hedgeRequirementTypes: [...payload],
    }
  },
  'ENUM/LEISTATUSES/SET': (state, { payload }) => {
    return {
      ...state,
      leiStatuses: [...payload],
    }
  },
}

export default handleActions(reducerMap, defaultState)
