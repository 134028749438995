import { createActions } from 'redux-actions'
import clone from 'lodash/clone'
import { parseError } from '../../helpers/errorUtils'
import { tryCatchAction } from '../storeHelpers/reduxActionHelpers'

const { login: loginActions } = createActions({
  LOGIN: {
    SET: {
      FIRST: undefined,
      SECOND: undefined,
      RECOVERYCODES: undefined,
      USER: undefined,
    },
    RESET: undefined,
    ERROR: undefined,
  },
})

loginActions.create = data => ({ axios }) => async dispatch => {
  try {
    const response = await axios.post('api/account/login', data)
    dispatch(loginActions.set.first(response.data))
  } catch (error) {
    dispatch(loginActions.error(parseError(error)))
  }
}

loginActions.twoFactor = data => ({ axios }) => async dispatch => {
  try {
    const response = await axios.post('api/account/logintwofactor', data)
    dispatch(loginActions.set.second(response.data))
  } catch (error) {
    dispatch(loginActions.error(parseError(error)))
  }
}

loginActions.registerTwoFactor = data => ({ axios }) => async dispatch => {
  try {
    const response = await axios.post('api/account/registertwofactor', data)
    dispatch(loginActions.set.recoverycodes(response.data))
    dispatch(loginActions.twoFactor(data))
  } catch (error) {
    dispatch(loginActions.error(parseError(error)))
  }
}

loginActions.get = () => ({ axios }) => async dispatch => {
  const response = await axios.get(`api/user`)
  dispatch(loginActions.set.user(response.data))
}

loginActions.delete = () => ({ axios }) => async dispatch => {
  try {
    await axios.delete(`api/account/logout`)
    dispatch(loginActions.reset())
  } catch (error) {
    dispatch(loginActions.error(parseError(error)))
  }
}

loginActions.loginAsUser = ({ id, firstName, lastName }) => ({
  axios,
}) => async dispatch => {
  tryCatchAction(
    async () => {
      const response = await axios.post(`/api/user/${id}/login`)
      dispatch(loginActions.set.second(response.data))
    },
    dispatch,
    `Logged in as user [${firstName} ${lastName}] Successfully.`
  )
}

export default loginActions
