import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileReaderInput from 'react-file-reader-input'
import { connect } from 'react-redux'
import actions from '../../../store/actions'
import './HolidayImport.styl'

class HolidayImport extends Component {
  constructor() {
    super()
    this.state = {
      files: [],
      loading: false,
    }
  }
  componentWillMount() {
    this.props.getHolidays()
  }

  componentWillReceiveProps(nextProps) {
    const haveResult = nextProps.importSuccess || nextProps.importError
    if (this.state.loading && haveResult) {
      this.setState({ loading: false })
    }
  }

  handleFile = (e, results) => {
    this.setState({ files: results.map(x => x[1]) })
  }

  handleSubmit = async () => {
    // e.preventDefault()
    this.setState({ loading: true })
    await Promise.all(this.state.files.map(x => this.props.importHolidays(x)))
    this.setState({ files: [] })
    await this.props.getHolidays()
  }

  resetForm = () => {
    // this.props.resetProductPrices()
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="text-center">
          {this.state.loading && (
            <div className="entry">
              Importing...<CircularProgress />
            </div>
          )}
          {this.props.importSuccess && (
            <div className="entry">Successfully imported!</div>
          )}
          {this.props.importError && (
            <div className="entry">Error: {this.props.importError}</div>
          )}

          {!this.state.loading && (
            <div className="entry">
              <FileReaderInput
                as="text"
                id="my-file-input"
                name="Holiday CSV"
                accept=".csv,.CSV"
                placeholder="Holiday CSV"
                className="inputClass"
                onChange={this.handleFile}
                multiple
              >
                <button type="button">Select a file!</button>
              </FileReaderInput>
              {Boolean(this.state.files.length) && (
                <button
                  type="button"
                  className="import-button"
                  onClick={this.handleSubmit}
                >
                  Import!
                </button>
              )}
            </div>
          )}
          <button
            type="button"
            onClick={() => {
              if (confirm(`Are you sure you want to clear out all holidays?`))
                this.props.clearHolidays()
            }}
          >
            Clear All
          </button>
          {this.props.holidays.map(x => {
            const value = ['name', 'date']
              .map(column => `${column}: ${x[column]}`)
              .join(' | ')
            return <div key={x.id}>{value}</div>
          })}
        </div>
      </div>
    )
  }
}

HolidayImport.propTypes = {
  getHolidays: PropTypes.func.isRequired,
  importHolidays: PropTypes.func.isRequired,
  clearHolidays: PropTypes.func.isRequired,
  importSuccess: PropTypes.bool,
  importError: PropTypes.number,
  holidays: PropTypes.arrayOf(PropTypes.object),
}

const mapDispatchToProps = {
  getHolidays: actions.holiday.getAll,
  importHolidays: actions.holiday.importCsv,
  clearHolidays: actions.holiday.clearAll,
}

const mapStateToProps = state => {
  return {
    importSuccess: state.holiday.import.success,
    importError: state.holiday.import.error,
    holidays: state.holiday.holidays,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayImport)
