import React from 'react'
import { cloneDeep, findIndex } from 'lodash'
import * as Table from 'reactabular-table'
import { Button } from '@material-ui/core'
import * as edit from 'react-edit'
import moment from 'moment'
import uuid from 'uuid'
import datetime from './datetime'
import date from './date'

class EditableTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: this.getColumns(), // initial columns
    }
    if (!props.updateTrades) {
      props.updateTrades = () => {
        console.log('no update trades!')
      }
    }
  }
  getColumns() {
    const editable = edit.edit({
      isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,
      onActivate: ({ columnIndex, rowData }) => {
        const index = findIndex(this.props.rows, { id: rowData.id })
        const rows = cloneDeep(this.props.rows)

        rows[index].editing = columnIndex

        this.props.updateTrades({ rows })
      },
      onValue: ({ value, rowData, property }) => {
        const index = findIndex(this.props.rows, { id: rowData.id })
        const rows = cloneDeep(this.props.rows)

        rows[index][property] = value
        rows[index].editing = false

        this.props.updateTrades({ rows })
      },
    })

    const { users, entities, brokers, instrumentTypes, products } = this.props
    const buyerSellerOptions = entities.map(x => {
      return { value: x.id.toString(), name: x.displayName }
    })
    const brokerOptions = brokers.map(x => {
      return { value: x.id.toString(), name: x.displayName }
    })
    const instrumentTypeOptions = instrumentTypes.map(x => {
      return { value: x.id, name: x.displayName }
    })
    const productOptions = products.map(x => {
      return { value: x.id, name: x.id }
    })
    const userOptions = users.map(x => {
      return { value: x.id, name: x.fullName }
    })

    return [
      {
        property: 'tid',
        header: {
          label: 'TID',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'number' } }))],
        },
      },
      {
        property: 'buyerId',
        header: {
          label: 'Buyer',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: buyerSellerOptions,
                props: { type: 'number' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = entities.find(x => x.id == id)
              return entity ? entity.displayName : null
            },
          ],
        },
      },
      {
        property: 'buyerUserId',
        header: {
          label: 'Buyer User',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: userOptions,
                props: { type: 'string' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = users.find(x => x.id == id)
              return entity ? entity.fullName : null
            },
          ],
        },
      },
      {
        property: 'sellerId',
        header: {
          label: 'Seller',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: buyerSellerOptions,
                props: { type: 'number' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = entities.find(x => x.id == id)
              return entity ? entity.displayName : null
            },
          ],
        },
      },
      {
        property: 'sellerUserId',
        header: {
          label: 'Seller User',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: userOptions,
                props: { type: 'string' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = users.find(x => x.id == id)
              return entity ? entity.fullName : null
            },
          ],
        },
      },
      {
        property: 'brokerId',
        header: {
          label: 'Broker',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: brokerOptions,
                props: { type: 'number' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = brokers.find(x => x.id == id)
              return entity ? entity.displayName : null
            },
          ],
        },
      },
      {
        property: 'brokerRate',
        header: {
          label: 'Broker Rate',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'number' } }))],
        },
      },
      {
        property: 'brokerUserId',
        header: {
          label: 'Broker User',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: userOptions,
                props: { type: 'string' },
              })
            ),
          ],
          formatters: [
            id => {
              const entity = users.find(x => x.id == id)
              return entity ? entity.fullName : null
            },
          ],
        },
      },
      {
        property: 'traderName',
        header: {
          label: 'Trader Name',
        },
        cell: {
          transforms: [editable(edit.input())],
        },
      },
      {
        property: 'index',
        header: {
          label: 'Index',
        },
        cell: {
          transforms: [editable(edit.input())],
        },
      },
      {
        property: 'productType',
        header: {
          label: 'Product Type',
        },
        cell: {
          transforms: [editable(edit.input())],
        },
      },
      {
        property: 'product',
        header: {
          label: 'Product',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: productOptions,
              })
            ),
          ],
        },
      },
      {
        property: 'instrumentType',
        header: {
          label: 'Instrument Type',
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: instrumentTypeOptions,
              })
            ),
          ],
        },
      },
      {
        property: 'monthlyVolume',
        header: {
          label: 'Monthly Volume',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'number' } }))],
        },
      },
      {
        property: 'startDate',
        header: {
          label: 'Start Date',
        },
        cell: {
          transforms: [editable(date())],
          formatters: [dateTime => moment(dateTime).format('MM/DD/YY')],
        },
      },
      {
        property: 'endDate',
        header: {
          label: 'End Date',
        },
        cell: {
          transforms: [editable(date())],
          formatters: [dateTime => moment(dateTime).format('MM/DD/YY')],
        },
      },
      {
        property: 'strikePrice',
        header: {
          label: 'Strike Price',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'number' } }))],
        },
      },
      {
        property: 'tradePremiumPrice',
        header: {
          label: 'Trade/Premium Price',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'number' } }))],
        },
      },
      {
        property: 'tradeDateTime',
        header: {
          label: 'Trade Date/Time',
        },
        cell: {
          transforms: [editable(datetime())],
          formatters: [dateTime => moment(dateTime).format('MM/DD/YY hh:mm A')],
        },
      },
      {
        property: 'cmeTradeId',
        header: {
          label: 'CME TID',
        },
        cell: {
          transforms: [editable(edit.input({ props: { type: 'string' } }))],
        },
      },
      {
        props: {
          style: {
            width: 50,
          },
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span
                className="remove"
                onClick={() => this.onRemove(rowData.id)}
                style={{ cursor: 'pointer' }}
              >
                &#10007;
              </span>
            ),
          ],
        },
      },
    ]
  }

  onAdd = e => {
    e.preventDefault()

    const rows = [{ id: uuid.v4() }].concat(this.props.rows)
    this.props.updateTrades({ rows })
  }
  onRemove = id => {
    const rows = [...this.props.rows]
    const idx = findIndex(rows, { id })

    rows.splice(idx, 1)

    this.props.updateTrades({ rows })
  }

  render() {
    const { rows } = this.props
    const { columns } = this.state

    return (
      <div className="table-container">
        {/* <Button onClick={this.OnAdd}>Add</Button> */}
        <Table.Provider
          className="pure-table pure-table-striped"
          columns={columns}
          style={{
            marginTop: '20px',
            display: 'block',
            overflow: 'auto',
          }}
        >
          <Table.Header />
          <Table.Body rows={rows} rowKey="id" />
        </Table.Provider>
      </div>
    )
  }
}

export default EditableTable
