import { handleActions } from 'redux-actions'

const defaultState = {
  entity: null,
  entityType: null,
  parent: null,
}

const reducerMap = {
  'ACCOUNTS/ENTITY/SET': (state, { payload }) => {
    return {
      ...state,
      entity: payload,
    }
  },
  'ACCOUNTS/ENTITYTYPE/SET': (state, { payload }) => {
    return {
      ...state,
      entityType: payload,
    }
  },
  'ACCOUNTS/PARENT/SET': (state, { payload }) => {
    return {
      ...state,
      parent: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
