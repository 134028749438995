import { createActions } from 'redux-actions'

const { trade: tradeActions } = createActions({
  TRADE: {
    SET: undefined,
    ADD: undefined,
  },
})

tradeActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/trades')
  dispatch(tradeActions.set(response.data))
}

tradeActions.create = data => ({ axios }) => async dispatch => {
  const response = await axios.post('/trade', data)
  dispatch(tradeActions.add(response.data))
}

export default tradeActions
