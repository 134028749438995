import { createActions } from 'redux-actions'

const { enum: enumActions } = createActions({
  ENUM: {
    QUANTITYTYPES: {
      SET: undefined,
    },
    INSTRUMENTTYPES: {
      SET: undefined,
    },
    ADDRESSTYPES: {
      SET: undefined,
    },
    PHONENUMBERTYPES: {
      SET: undefined,
    },
    RECOMMENDEDACTIONS: {
      SET: undefined,
    },
    HEDGEDOCUMENTTYPES: {
      SET: undefined,
    },
    EMAILTYPES: {
      SET: undefined,
    },
    HEDGEREQUIREMENTTYPES: {
      SET: undefined,
    },
    LEISTATUSES: {
      SET: undefined,
    },
  },
})

enumActions.getAll = type => ({ axios }) => async dispatch => {
  const response = await axios.get(`api/enums/${type}`)
  dispatch(enumActions[type].set(response.data))
}

export default enumActions
