import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import './MainMenu.styl'
import UserInfo from './../UserInfo'
import { build } from '../container_helpers'
import LinkMenu from './../LinkMenu/LinkMenu'
import { menuItems } from '../../constants'
import MTMSummaryBelt from './../Belt/MTMSummaryBelt'

class MainMenu extends Component {
  getItems() {
    const { user } = this.props
    const allItems = []

    if (!isEmpty(user)) {
      user.allowedPages.forEach(item => {
        allItems.push(item)
      })
    }
    return allItems.map(item => (
      <LinkMenu
        key={`${menuItems[item].link}`}
        to={`${menuItems[item].link}`}
        itemName={`${menuItems[item].name}`}
      />
    ))
  }

  render() {
    const { user } = this.props
    let logo = '/img/tappian-logo-color.svg'
    if (user.logo) {
      logo = `${process.env.API_URL}/${user.logo}`
    }
    return (
      <div id="navbar" className="height-menu">
        <div className="row">
          <div className="logo">
            <a href="/dashboard">
              <img src={logo} alt="logo" className="logo-inner" />
            </a>
          </div>
          <div className="hidden-sm hidden-xs menu-items">
            <ul>{this.getItems()}</ul>
          </div>
          <div className="user-space">
            <UserInfo />
          </div>
        </div>
        {this.props.belt || <MTMSummaryBelt />}
      </div>
    )
  }
}

MainMenu.propTypes = {
  belt: PropTypes.node,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
  }
}

export default build({
  component: MainMenu,
  mapStateToProps,
})
