import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import GenericContainer from './../../containers/GenericContainer'
import TradeBlotter from '../TradeBlotter'
import LoggedContainer from '../../containers/LoggedContainer'
import LoginSignUpContainer from './../../containers/LoginSignUpContainer'
import Dashboard from './../Dashboard/Dashboard'
import MarkToMarket from './../MarkToMarket/MarkToMarket'
import Accounts from './../Accounts/Accounts'
import DataImport from './../DataImport/DataImport'
import store from '../../store'
import Login from './../Login/Login'
import ChangePassword from './../ChangePassword/ChangePassword'
import ChangePasswordWithToken from './../ChangePassword/ChangePasswordWithToken'
import { requireAuthentication } from './auth'
import Unauthorized from '../Unauthorized/Unauthorized'
import Landing from './../Landing/Landing'
import ReportGenerator from './../ReportGenerator/ReportGenerator'
import DailyTransactionSummary from '../Reports/DailyTransactionSummary/DailyTransactionSummary'
import DailySettlementSummary from '../Reports/DailySettlementSummary/DailySettlementSummary'
import MarkToMarketReport from '../Reports/MarkToMarket/MarkToMarket'
import MarkToMarketDetail from '../Reports/MarkToMarketDetail/MarkToMarketDetail'
import MarkToMarketSummaryReport from './../Reports/MarkToMarketSummary/MarkToMarketSummaryReport'
import OptionPremiumReport from '../Reports/OptionPremiumReport/OptionPremiumReport'
import TradeConfirmation from '../Reports/TradeConfirmation/TradeConfirmation'
import ThresholdSummaryReport from '../Reports/ThresholdSummaryReport/ThresholdSummaryReport'
import ThresholdSummary from '../ThresholdSummary/ThresholdSummary'

import Belt from '../Belt/Belt'

export default class Root extends Component {
  requireLogin = (nextState, replace, done) => {
    const userId = window.localStorage.getItem('currentUserId')
    if (!userId) {
      replace({ pathname: '/login' })
    }
    done()
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" name="landing">
              <GenericContainer>
                <Landing />
              </GenericContainer>
            </Route>
            <Route path="/login" name="login">
              <LoginSignUpContainer>
                <Login />
              </LoginSignUpContainer>
            </Route>
            <Route path="/changepassword" name="changepassword">
              <LoginSignUpContainer>
                <ChangePassword />
              </LoginSignUpContainer>
            </Route>
            <Route
              path="/change-password-with-token/:id/:token"
              component={ChangePasswordWithToken}
            />

            <Route
              path="/dashboard"
              name="dashboard"
              component={requireAuthentication(
                LoggedContainer,
                <Dashboard />,
                <Belt />
              )}
            />
            <Route
              path="/trade-blotter"
              name="trade-blotter"
              component={requireAuthentication(
                LoggedContainer,
                <TradeBlotter />
              )}
            />
            <Route
              path="/mark-to-market"
              name="mark-to-market"
              component={requireAuthentication(
                LoggedContainer,
                <MarkToMarket />
              )}
            />
            <Route
              path="/accounts"
              name="accounts"
              component={requireAuthentication(LoggedContainer, <Accounts />)}
            />
            <Route
              path="/data-import"
              name="data-import"
              component={requireAuthentication(LoggedContainer, <DataImport />)}
            />
            <Route
              path="/report-generator/(preview|csv)/:reportName?/:id?/:startDate?/:endDate?"
              name="report-generator"
              component={requireAuthentication(
                LoggedContainer,
                <ReportGenerator />
              )}
            />
            <Route
              path="/report-generator/:reportName?/:id?/:startDate?/:endDate?"
              name="report-generator"
              component={requireAuthentication(
                LoggedContainer,
                <ReportGenerator />
              )}
            />
            <Route
              path="/threshold-summary"
              name="threshold-summary"
              component={requireAuthentication(
                LoggedContainer,
                <ThresholdSummary />
              )}
            />
            <Route
              name="transaction-summary"
              path="/transaction-summary/:id/:startDate/:endDate?"
              component={DailyTransactionSummary}
            />
            <Route
              name="mark-to-market-summary"
              path="/mark-to-market-summary/:id/:settleDate"
              component={MarkToMarketSummaryReport}
            />
            <Route
              name="mark-to-market-report"
              path="/mark-to-market-report/:id/:settleDate"
              component={MarkToMarketReport}
            />
            <Route
              name="mark-to-market-detail"
              path="/mark-to-market-detail/:id/:settleDate"
              component={MarkToMarketDetail}
            />
            <Route
              name="settlement-summary"
              path="/settlement-summary/:id/:startDate/:endDate?"
              component={DailySettlementSummary}
            />
            <Route
              name="option-premium"
              path="/option-premium/:id/:startDate/:endDate?"
              component={OptionPremiumReport}
            />
            <Route
              name="trade-confirmation"
              path="/trade-confirmation/:id/:startDate/:endDate?"
              component={TradeConfirmation}
            />
            <Route
              name="threshold-summary-report"
              path="/threshold-summary-report/:id/:settleDate"
              component={ThresholdSummaryReport}
            />
            <Route path="/unauthorized" component={Unauthorized} />
          </Switch>
          {/* <Redirect from="*" to="/" /> */}
        </BrowserRouter>
      </Provider>
    )
  }
}
