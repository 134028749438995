import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, FlatButton } from 'material-ui'
import Input from '../../UtilsComponents/Input/Input'
import { build, actions } from '../../container_helpers'

const modalInputOverrideStyle = {
  width: '100%',
}

const ResetPasswordForm = props => {
  const [email, setEmail] = useState('')

  const onEmailChanged = value => {
    setEmail(value)
  }
  const handleClose = () => {
    setEmail('')
    props.clearResetPasswordStatus()
    props.onClose()
  }

  const isPasswordReset = () =>
    props.passwordResetStatus && props.passwordResetStatus.success

  const handleSubmit = () => {
    if (isPasswordReset()) {
      handleClose()
    } else if (email) {
      props.resetPassword(email)
    }
  }

  const modalActions = [
    <FlatButton
      label={isPasswordReset() ? 'Close' : 'Submit'}
      primary
      keyboardFocused={false}
      onClick={handleSubmit}
    />,
  ]

  return (
    <Dialog
      title="Forgot Password"
      actions={modalActions}
      modal={false}
      open={props.open}
      onRequestClose={handleClose}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          this.handleSubmit()
        }}
      >
        {isPasswordReset() && (
          <div>Please check your email to recover your password </div>
        )}
        {props.passwordResetStatus &&
        !props.passwordResetStatus.success && (
          <div>Something went wrong. Please try again. </div>
        )}
        {!isPasswordReset() && (
          <Input
            name="email"
            title="User Name or Email"
            defaultValue={email}
            onChange={e => {
              onEmailChanged(e.target.value)
            }}
            errorText=""
            style={modalInputOverrideStyle}
          />
        )}
      </form>
    </Dialog>
  )
}

ResetPasswordForm.propTypes = {
  open: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  clearResetPasswordStatus: PropTypes.func.isRequired,
  passwordResetStatus: PropTypes.shape({ success: PropTypes.bool }),
  onClose: PropTypes.func,
}

const mapDispatchToProps = {
  resetPassword: actions.resetPassword.resetPassword,
  clearResetPasswordStatus: actions.resetPassword.clearStatus,
}
const mapStateToProps = state => {
  return {
    passwordResetStatus: state.resetPassword,
  }
}
export default build({
  component: ResetPasswordForm,
  mapStateToProps,
  mapDispatchToProps,
})
