import React from 'react'
import PropTypes from 'prop-types'
import LoadingBar from 'react-redux-loading-bar'
import MainMenu from './../components/MainMenu/MainMenu'

const LoggedContainer = props => (
  <div>
    <MainMenu belt={props.belt}/>
    <LoadingBar style={{ backgroundColor: '#00a9e0' }} />
    <div className="container-fluid">{props.children}</div>
  </div>
)

LoggedContainer.propTypes = {
  belt: PropTypes.node,
}

export default LoggedContainer
