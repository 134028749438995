import { createActions } from 'redux-actions'
import { parseError } from '../../helpers/errorUtils'

const { password: passwordActions } = createActions({
  PASSWORD: {
    SUCCESS: undefined,
    ERROR: undefined,
  },
})

passwordActions.change = data => ({ axios }) => async dispatch => {
  try {
    await axios.put(`api/user/password/change`, data)
    dispatch(passwordActions.success(true))
  } catch (e) {
    const error = parseError(e)
    dispatch(passwordActions.error(error))
  }
}

passwordActions.changeWithToken = data => ({ axios }) => async dispatch => {
  try {
    await axios.put(`api/user/password/changewithtoken`, data)
    dispatch(passwordActions.success(true))
  } catch (e) {
    const error = parseError(e)
    dispatch(passwordActions.error(error))
  }
}

export default passwordActions
