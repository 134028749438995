/* eslint filenames/match-exported: 0 */

// The tradeBlotterColumns function takes the basic column specifications
// from the "blotter_columns.js" and creates the full column specifications
// that "reactablular" expects.

import React from 'react'
// import * as reactEdit from 'react-edit'
import * as sortablular from 'sortabular'
import * as searchtabular from 'searchtabular'
import _ from 'lodash'
import blotterColumns from './blotter_columns'
import './stylesheet.styl'
import store from '../../store'
import { tradeBlotterColumnsByRole } from '../../constants'

function sortHeader(sortable, getSortingColumns) {
  return (value, { columnIndex }) => {
    const sortingColumns = getSortingColumns() || []
    const columnProps = sortable(
      value,
      {
        columnIndex,
      },
      {
        style: {
          position: 'absolute',
          top: '20px',
          right: '0px',
        },
      }
    )
    return (
      <div
        style={{ display: 'inline-block', position: 'relative' }}
        className={sortingColumns[columnIndex] ? 'activeColumn' : ''}
      >
        <span className="value">{value}</span>
        <span {...columnProps} />
      </div>
    )
  }
}

function noSortHeader(sortable) {
  return (value, __) => (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <span className="value">{value}</span>
    </div>
  )
}

const tradeBlotterColumns = (sorting, { allowedTradeBlotterColumns }) => {
  const sortable = sortablular.sort({
    getSortingColumns: () => store.getState().tradeBlotter.sorting || [],
    onSort: selectedColumn => {
      sorting({
        sortingColumns: sortablular.byColumn({
          sortingColumns: store.getState().tradeBlotter.sorting,
          selectedColumn,
        }),
      })
    },
  })

  const sortableHeader = sortHeader(
    sortable,
    () => store.getState().tradeBlotter.sorting
  )

  const nonSortableHeader = noSortHeader(sortable)

  const columnSpecs = blotterColumns
    .filter(([property]) => _.includes(allowedTradeBlotterColumns, property))
    .map(([property, options = {}]) => {
      const formatters = options.formatters || []
      return {
        property,
        header: {
          label: options.label || _.startCase(property),
          formatters: options.nonSortable
            ? [nonSortableHeader]
            : [sortableHeader],
          props: {
            style: {
              width: options.width || 100,
            },
          },
        },
        cell: {
          transforms: [value => (value !== null ? {} : { align: 'center' })],
          formatters: [
            ...formatters,
            value => value || '-',
            searchtabular.highlightCell,
          ],
        },
        visible: true,
      }
    })

  return columnSpecs
}

export default tradeBlotterColumns
