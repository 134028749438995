import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Checkbox, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RoundedButton from '../../UtilsComponents/RoundedButton/RoundedButton'

const useStyles = makeStyles(theme => {
  return {
    paper: {
      position: 'relative',
      width: '60%',
      height: '360px',
      marginTop: '0.5em',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: 'none',
      // overflow: 'scroll',
      display: 'block',
    },
  }
})

const Permissions = props => {
  const classes = useStyles(props)

  const [permissions, setPermissions] = useState(props.permissions)

  console.log('permissions', permissions)
  // update state if props.values change
  // useEffect(
  //   () => {
  //     setPermissions(props.permissions)
  //   },
  //   [props.permissions]
  // )

  const apply = () => {
    if (props.onChanged)
      props.onChanged(
        _.mapValues(permissions, ({ enabled, overridable }) => {
          return { enabled, overridable }
        })
      )
  }

  const checked = field => event => {
    const newVal = {
      [field]: {
        ...permissions[field],
        ...{ enabled: !permissions[field].enabled },
      },
    }
    setPermissions({ ...permissions, ...newVal })
  }

  const checkAll = () => {
    setPermissions(
      _.mapValues(permissions, x => {
        return { ...x, enabled: x.overridable }
      })
    )
  }

  const clearAll = () => {
    setPermissions(
      _.mapValues(permissions, x => {
        return { ...x, enabled: false }
      })
    )
    props.onChanged({})
  }

  if (_.isEmpty(permissions)) {
    return (
      <h2>
        Permissions can only be set after an entity has been initially created.
      </h2>
    )
  }
  return (
    <div>
      <div className="container" style={{ width: '100%' }}>
        <Button onClick={checkAll}>Check All</Button>
        <Button onClick={clearAll}>Clear All</Button>
        {Object.keys(permissions).map(x => (
          <div className="item" key={x}>
            <label style={{ padding: 0 }}>
              <Checkbox
                style={{ padding: 0 }}
                checked={permissions[x].enabled}
                onChange={checked(x)}
                disabled={!permissions[x].overridable}
                // value={permissions[x].enabled}
                color="primary"
              />
              {permissions[x].content || x}
            </label>
          </div>
        ))}
      </div>
      <div className="footer">
        <RoundedButton
          buttonText="Apply"
          onClick={apply}
          style={{ fontSize: '0.8rem' }}
        />
      </div>
    </div>
  )
}

Permissions.propTypes = {
  permissions: PropTypes.object,
  onChanged: PropTypes.func,
}

Permissions.defaultProps = {
  editable: true,
}

const mapStateToProps = state => {
  return {
    addressTypes: state.enum.addressTypes,
  }
}

export default Permissions
