import {
  tryCatchAction,
  tryLogAction,
  updateAccountsState,
} from '../storeHelpers/reduxActionHelpers'
import store from '../../store'
import accountsActions from '../actions/accounts_actions'

export const entityActions = {
  get: ({ id, displayName, name }, axios, dispatch, action, type) =>
    tryLogAction(
      async () => {
        const response = await axios.get(`api/businessEntity/${id}`)
        dispatch(action(response.data))
        updateAccountsState(dispatch, response.data)
        return response.data
      },
      dispatch,
      `${type} ${displayName || name} Refreshed Successfully.`
    ),
  getAll: (axios, dispatch, action, type, checkActive = true) =>
    tryLogAction(
      async () => {
        const queryString = !checkActive ? '?checkActive=false' : ''
        const response = await axios.get(
          `api/businessEntity/list/${type}${queryString}`
        )
        dispatch(action(response.data))
        return response.data
      },
      dispatch,
      `All ${type} Fetched Successfully.`
    ),
  create: (data, axios, dispatch, action, type) =>
    tryCatchAction(
      async () => {
        const response = await axios.post(`api/businessEntity/${type}`, data)
        dispatch(action(response.data))
        const state = store.getState()
        if (!state.accounts.entity) {
          dispatch(accountsActions.entity.set(response.data))
        }
        return response.data
      },
      dispatch,
      `${type} ${data.displayName || data.name} Created Successfully.`
    ),

  update: (data, axios, dispatch, action, type) =>
    tryCatchAction(
      async () => {
        const response = await axios.put(
          `api/businessEntity/${type}/${data.id}`,
          data
        )
        dispatch(action(response.data))
        updateAccountsState(dispatch, response.data)
        return response.data
      },
      dispatch,
      `${type} ${data.displayName || data.name} Updated Successfully.`
    ),

  delete: (id, axios, dispatch, action, type) =>
    tryCatchAction(
      async () => {
        const response = await axios.delete(`api/businessEntity/${id}`)
        dispatch(action(response.data))
        return response.data
      },
      dispatch,
      `${type} Deleted Successfully.`
    ),
}
