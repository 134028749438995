import { createActions } from 'redux-actions'

const tradeEntryActions = createActions({
  RESET: undefined,
  SET: undefined,
  TRADEACTIONS: {
    UPDATE: undefined,
  },
  PRODUCTENTRY: {
    UPDATE: undefined,
  },
  NEXT: {
    TID: undefined,
    GID: undefined,
  },
})

tradeEntryActions.getNextIds = entityId => ({ axios }) => async dispatch => {
  const tidPromise = axios.get(`api/trade/next/${entityId}`)
  const gidPromise = axios.get(`api/tradeGroup/next/${entityId}`)

  try {
    const tid = await tidPromise
    const gid = await gidPromise
    dispatch(tradeEntryActions.next.tid(tid.data))
    dispatch(tradeEntryActions.next.gid(gid.data))
  } catch (e) {
    console.log('ERROR GETTING NEXT IDS', e)
  }
  // dispatch(tradeBlotterActions.trades.putmany(response.data.updated))
  // dispatch(tradeBlotterActions.trades.deletemany(response.data.deleted))
}

tradeEntryActions.updateTradeActions = tradeAction => () => async dispatch => {
  if (tradeAction.id === 0) {
    dispatch(tradeEntryActions.getNextIds(tradeAction.firstEntityId))
  }
  dispatch(tradeEntryActions.tradeactions.update(tradeAction))
}

export default tradeEntryActions
