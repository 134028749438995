import React, { Component } from 'react'

export default class Unauthorized extends Component {
  render() {
    return (
      <div className="unauthorized">
        Unauthorized access, please contact the webmaster
      </div>
    )
  }
}
