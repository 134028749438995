import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import classnames from 'classnames'
import '../../../application.styl'
import styles from './DailyTransactionSummary.styl'
import { build, actions } from '../../container_helpers'
import { currencyFragment, currencyDiv } from '../../../helpers/formatCurrency'
import * as EntityPropType from '../../shared_prop_types'

class DailyTransactionSummary extends Component {
  constructor() {
    super()
    this.state = {
      bank: null,
      trades: [],
      firstUpdate: false,
    }
  }

  componentWillMount() {
    const { id, startDate, endDate } = this.props.match.params
    this.props.getTradeSummaries(id, {
      tradeDate: startDate,
      tradeEndDate: endDate,
    })
    this.props.getEntity(id)
    this.setState({ firstUpdate: false })
  }

  componentWillReceiveProps(props) {
    if (
      this.props.match.params.id !== props.match.params.id ||
      this.props.match.params.startDate !== props.match.params.startDate ||
      this.props.match.params.endDate !== props.match.params.endDate
    ) {
      const { id, startDate, endDate } = props.match.params
      this.props.getTradeSummaries(id, {
        tradeDate: startDate,
        tradeEndDate: endDate,
      })
      this.props.getEntity(id)
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { tradeSummaries, entity } = this.props
    return !_.isEmpty(entity) && !_.isEmpty(tradeSummaries)
  }

  renderReport(csvData) {
    try {
      const { tradeSummaries, entity } = this.props
      const { startDate, endDate } = this.props.match.params
      if (!this.isReadyToRender()) return null

      const totalGross = _.sumBy(tradeSummaries, x => Math.abs(x.gross))
      const dateTitle = `Date${endDate ? ' Range' : ''}`
      const dateText =
        moment(startDate).format('MM/DD/YYYY') +
        (endDate ? ` - ${moment(endDate).format('MM/DD/YYYY')}` : '')
      const logo = `${process.env.API_URL}/${entity.logo}`

      return (
        <div className={styles.dailyTransactionSummary} align="center">
          <div>
            <table
              className={classnames(
                'summary-table',
                'odd-rows-print',
                csvData && 'csv-table'
              )}
            >
              <thead>
                <tr className="summary-header-1">
                  <th align="center" colSpan="19">
                    <div>
                      <img
                        className="tappian-report-logo"
                        src="/img/tappian-logo-color.svg"
                        alt="tappian logo"
                      />
                      <h4>{entity.name}</h4>
                      <img className="report-logo" src={logo} alt="bank logo" />
                    </div>
                  </th>
                </tr>
                <tr className="summary-header-2">
                  <th colSpan="3">
                    <h4>{dateTitle}</h4>
                  </th>
                  <th colSpan="3">
                    <h4>{dateText}</h4>
                  </th>
                  <th colSpan="12">
                    <h4>TRANSACTION SUMMARY</h4>
                  </th>
                  <th colSpan="1">
                    <div className="total-gross-label">Total Gross</div>
                    <div className="total-gross-value currency">
                      {csvData ? totalGross : currencyFragment(totalGross)}
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Trade Date</th>
                  <th>Trade Time</th>
                  <th>Reference ID</th>
                  <th>
                    Trade<br />Group ID
                  </th>
                  <th>
                    Trader<br />Name
                  </th>
                  <th>Buyer</th>
                  <th>Seller</th>
                  <th>C_Trader/Broker</th>
                  <th>
                    Monthly<br />Volume
                  </th>
                  <th>
                    Product<br />Type
                  </th>
                  <th>Product</th>
                  <th>Instrument</th>
                  <th>
                    Strike<br />Price
                  </th>
                  <th>
                    Trade<br />Price
                  </th>
                  <th>Start Month</th>
                  <th>End Month</th>
                  <th>
                    Settlement<br />Type
                  </th>
                  <th>
                    Payment<br />Days
                  </th>
                  <th style={{ minWidth: '140px' }}>Gross</th>
                </tr>
              </thead>
              <tbody>
                {csvData &&
                  tradeSummaries.map(x => {
                    const { counterparty: first, customer: second } = x
                    return [first, second].map((y, index) => (
                      <tr key={y.tid}>
                        <td>{moment(y.tradeDateTime).format('MM/DD/YYYY')}</td>
                        <td>{moment(y.tradeDateTime).format('hh:mm A')}</td>
                        <td>
                          <div>{y.referenceId}</div>
                        </td>
                        <td>
                          <div>{y.tradeGroupId}</div>
                        </td>
                        <td>
                          <div>{y.traderName}</div>
                        </td>
                        <td>
                          <div>{y.buyerName}</div>
                        </td>
                        <td>
                          <div>{y.sellerName}</div>
                        </td>
                        <td>
                          <div>{y.userName}</div>
                        </td>
                        <td>
                          <div>{y.monthlyVolume.toLocaleString('en-US')}</div>
                        </td>
                        <td>
                          <div>{y.productType}</div>
                        </td>
                        <td>
                          <div>{y.productId}</div>
                        </td>
                        <td>
                          <div>{y.instrumentType}</div>
                        </td>
                        <td>{y.strikePrice}</td>
                        <td>{y.tradePrice}</td>
                        <td>
                          <div>{moment(y.startDate).format('MMM-YY')}</div>
                        </td>
                        <td>
                          <div>{moment(y.endDate).format('MMM-YY')}</div>
                        </td>
                        <td>
                          <div>{y.settlementType.replace('_', ' ')}</div>
                        </td>
                        <td>
                          <div>{y.paymentDays}</div>
                        </td>
                        <td>{index === 0 ? Math.abs(x.gross) : ''}</td>
                      </tr>
                    ))
                  })}
                {!csvData &&
                  tradeSummaries.map(x => {
                    const { counterparty: first, customer: second } = x
                    return (
                      <tr key={first.tradeGroupId}>
                        <td>
                          <div>
                            {moment(first.tradeDateTime).format('MM/DD/YYYY')}
                          </div>
                          <div>
                            {moment(second.tradeDateTime).format('MM/DD/YYYY')}
                          </div>
                        </td>
                        <td>
                          <div>
                            {moment(first.tradeDateTime).format('hh:mm A')}
                          </div>
                          <div>
                            {moment(second.tradeDateTime).format('hh:mm A')}
                          </div>
                        </td>
                        <td>
                          <div>{first.referenceId}</div>
                          <div>{second.referenceId}</div>
                        </td>
                        <td>
                          <div>{first.tradeGroupId}</div>
                          <div>{second.tradeGroupId}</div>
                        </td>
                        <td>
                          <div>{first.traderName}</div>
                          <div>{second.traderName}</div>
                        </td>
                        <td>
                          <div>{first.buyerName}</div>
                          <div>{second.buyerName}</div>
                        </td>
                        <td>
                          <div>{first.sellerName}</div>
                          <div>{second.sellerName}</div>
                        </td>
                        <td>
                          <div>{first.userName}</div>
                          <div>{second.userName}</div>
                        </td>
                        <td>
                          <div>
                            {first.monthlyVolume.toLocaleString('en-US')}
                          </div>
                          <div>
                            {second.monthlyVolume.toLocaleString('en-US')}
                          </div>
                        </td>
                        <td>
                          <div>{first.productType}</div>
                          <div>{second.productType}</div>
                        </td>
                        <td>
                          <div>{first.productId}</div>
                          <div>{second.productId}</div>
                        </td>
                        <td>
                          <div>{first.instrumentType}</div>
                          <div>{second.instrumentType}</div>
                        </td>
                        <td>
                          {csvData ? (
                            first.strikePrice
                          ) : (
                            currencyDiv(first.strikePrice)
                          )}
                          {csvData ? (
                            second.strikePrice
                          ) : (
                            currencyDiv(second.strikePrice)
                          )}
                        </td>
                        <td>
                          {csvData ? (
                            first.tradePrice
                          ) : (
                            currencyDiv(first.tradePrice, 4)
                          )}
                          {csvData ? (
                            second.tradePrice
                          ) : (
                            currencyDiv(second.tradePrice, 4)
                          )}
                        </td>
                        <td>
                          <div>{moment(first.startDate).format('MMM-YY')}</div>
                          <div>{moment(second.startDate).format('MMM-YY')}</div>
                        </td>
                        <td>
                          <div>{moment(first.endDate).format('MMM-YY')}</div>
                          <div>{moment(second.endDate).format('MMM-YY')}</div>
                        </td>
                        <td>
                          <div>{first.settlementType.replace('_', ' ')}</div>
                          <div>{second.settlementType.replace('_', ' ')}</div>
                        </td>
                        <td>
                          <div>{first.paymentDays}</div>
                          <div>{second.paymentDays}</div>
                        </td>
                        <td>
                          {csvData ? (
                            Math.abs(x.gross)
                          ) : (
                            currencyDiv(Math.abs(x.gross))
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )
    } catch (e) {
      return null
    }
  }

  render() {
    return (
      <div>
        {this.renderReport()}
        {this.props.csvData && this.renderReport(true)}
      </div>
    )
  }
}

DailyTransactionSummary.propTypes = {
  tradeSummaries: PropTypes.arrayOf(PropTypes.object),
  getTradeSummaries: PropTypes.func,
  clearTradeSummaries: PropTypes.func,
  getEntity: PropTypes.func,
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
  match: EntityPropType.startEndDateRouteType.isRequired,
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
}

const mapDispatchToProps = {
  getTradeSummaries: actions.tradeSummary.getAllTransactionSummariesForEntityId,
  clearTradeSummaries: actions.tradeSummary.clear,
  getEntity: actions.entity.get,
}

const mapStateToProps = state => {
  return {
    tradeSummaries: state.tradeSummaries,
    entity: state.entity,
  }
}

export default build({
  component: DailyTransactionSummary,
  mapDispatchToProps,
  mapStateToProps,
})
