import { createActions } from 'redux-actions'

const { email: emailActions } = createActions({
  EMAIL: {
    SET: undefined,
  },
})

emailActions.send = data => ({ axios }) => async dispatch => {
  const response = await axios.post('api/email', data)
  dispatch(emailActions.set(response.data))
}

export default emailActions
