import tradeActions from './trade_actions'
import userActions from './user_actions'
import sidebarActions from './sidebar_actions'
import bankActions from './bank_actions'
import bankCustomerActions from './bank_customer_actions'
import clearingAgentActions from './clearing_agent_actions'
import privateEquityActions from './private_equity_actions'
import selectedActions from './selected_actions'
import entityActions from './entity_actions'
import productPriceActions from './product_price_actions'
import counterpartyActions from './counterparty_actions'
import brokerActions from './broker_actions'
import loginActions from './login_actions'
import importActions from './import_actions'
import independentEntityActions from './independent_entity_actions'
import emailActions from './email_actions'
import tradeBlotterActions from './trade_blotter_actions'
import resetPasswordActions from './reset_password_actions'
import passwordActions from './password_actions'
import pdfGenerationActions from './pdf_generation_actions'
import commodityPriceActions from './commodity_price_actions'
import roleActions from './role_actions'
import tradeEntryActions from './trade_entry_actions'
import productActions from './product_actions'
import enumActions from './enum_actions'
import tradeSummaryActions from './trade_summary_actions'
import tradeGroupActions from './trade_group_actions'
import tradeEditActions from './trade_edit_actions'
import reportActions from './report_actions'
import accountsActions from './accounts_actions'
import notificationActions from './notification_actions'
import holidayActions from './holiday_actions'
/**
 * Order alphabetically to find easier each reducer
 */
const actions = {
  accounts: accountsActions,
  bank: bankActions,
  bankCustomer: bankCustomerActions,
  broker: brokerActions,
  clearingAgent: clearingAgentActions,
  commodityPrice: commodityPriceActions,
  counterparty: counterpartyActions,
  entity: entityActions,
  enum: enumActions,
  email: emailActions,
  holiday: holidayActions,
  import: importActions,
  independentEntity: independentEntityActions,
  login: loginActions,
  notification: notificationActions,
  password: passwordActions,
  pdf: pdfGenerationActions,
  privateEquity: privateEquityActions,
  product: productActions,
  productPrice: productPriceActions,
  resetPassword: resetPasswordActions,
  selected: selectedActions,
  sidebar: sidebarActions,
  trade: tradeActions,
  tradeBlotter: tradeBlotterActions,
  tradeEdit: tradeEditActions,
  tradeEntry: tradeEntryActions,
  tradeGroup: tradeGroupActions,
  tradeSummary: tradeSummaryActions,
  user: userActions,
  role: roleActions,
  report: reportActions,
}
export default actions
