import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { List, ListItem } from 'material-ui/List'
import Button from '@material-ui/core/Button'
import ContentAdd from 'material-ui/svg-icons/content/add-circle-outline'
import ContentRemove from 'material-ui/svg-icons/content/remove-circle-outline'
import AddBox from 'material-ui/svg-icons/content/add-box'
import Edit from 'material-ui/svg-icons/editor/mode-edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from 'material-ui/IconButton'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import * as EntityPropType from '../shared_prop_types'
import { build, actions } from '../container_helpers'
import './EntityItemList.styl'

const styles = {
  icon: {
    width: 20,
    height: 20,
    fill: '#757575',
  },
  button: {
    width: 20,
    height: 20,
    padding: 0,
  },
}

class EntityItemList extends Component {
  static plusIcon() {
    return (
      <IconButton iconStyle={styles.icon} style={styles.button}>
        <ChevronRightIcon />
      </IconButton>
    )
  }

  static minusIcon() {
    return (
      <IconButton iconStyle={styles.icon} style={styles.button}>
        <ExpandMoreIcon />
      </IconButton>
    )
  }

  static addIcon() {
    return (
      <IconButton iconStyle={styles.icon} style={styles.button}>
        <AddBox />
      </IconButton>
    )
  }

  constructor(props) {
    super(props)

    this.state = { open: {} }
  }

  toggleListItemState = listKey => {
    const newOpenList = Object.assign({}, this.state.open, {
      [listKey]: !this.state.open[listKey],
    })

    // the material-ui ListItem will by default close subLists, so we need the icons to follow
    // this closes all subLists (toggles the state 'open' var so the +/- icon is aptly set to +)
    if (!newOpenList[listKey]) {
      const subListItems = _.pickBy(newOpenList, (v, key) =>
        key.startsWith(listKey)
      )
      _.each(subListItems, (v, key) => {
        newOpenList[key] = false
      })
    }

    this.setState({ open: newOpenList })
  }

  handleEntityClick(entityType, listKey) {
    this.toggleListItemState(listKey)
    this.props.selectEntityType(entityType)
    this.props.selectEntity(null)
  }

  handleInstanceClick(instance, entityType, listKey) {
    this.toggleListItemState(listKey)
    this.props.selectEntityType(entityType)
    this.props.selectEntity(instance)
  }

  addIcon(entityType, parentInstance) {
    const {
      selectEntityType,
      selectEntity,
      selectParentEntity,
      toggleSidebar,
    } = this.props
    return (
      <IconButton
        iconStyle={styles.icon}
        style={styles.button}
        onClick={() => {
          selectEntityType(entityType)
          selectEntity()
          selectParentEntity(parentInstance)
          toggleSidebar()
        }}
      >
        <AddBox />
      </IconButton>
    )
  }

  editIcon(entityType, instance) {
    const { selectEntityType, selectEntity, toggleSidebar } = this.props

    return (
      <IconButton
        iconStyle={styles.icon}
        style={styles.button}
        onClick={() => {
          selectEntityType(entityType)
          selectEntity(instance)
          toggleSidebar()
        }}
      >
        <Edit />
      </IconButton>
    )
  }

  deleteIcon(entityType, instance) {
    return (
      <IconButton
        iconStyle={styles.icon}
        style={styles.button}
        onClick={() => {
          if (
            confirm(`Are you sure you want to DELETE this ${entityType.title}?`)
          ) {
            this.props[entityType.delete](instance.id)
          }
        }}
      >
        <DeleteOutline />
      </IconButton>
    )
  }

  entityTypeList(entityTypes, ancestorList = [], baseKey = '') {
    return _.map(entityTypes, entity => {
      const listKey = `${baseKey}_${entity.title}`
      const nestedItems = this.instancesOfEntityType(
        entity,
        ancestorList.concat(entity),
        listKey
      )
      const listItemOpen = this.state.open[listKey]
      const directParent = _.nth(ancestorList, -1)
      let leftIcon = null
      if (!_.isEmpty(nestedItems))
        leftIcon = listItemOpen
          ? EntityItemList.minusIcon()
          : EntityItemList.plusIcon()

      return (
        <ListItem
          key={listKey}
          className="entity-type-item"
          primaryTogglesNestedList
          leftIcon={leftIcon}
          onClick={() => this.handleEntityClick(entity, listKey)}
          primaryText={entity.title}
          nestedItems={nestedItems}
          rightIconButton={this.addIcon(entity, directParent)}
        />
      )
    })
  }

  instancesOfEntityType(entityType, ancestorList, baseKey) {
    let instances = this.props[entityType.name] || []

    // the directParent, assuming existence, is always an instance of an entity
    const directParent = _.nth(ancestorList, -2)

    if (entityType.filter && directParent) {
      instances = _.filter(instances, { [entityType.filter]: directParent.id })
    } else if (directParent && _.isArray(entityType.associatedEntityType)) {
      // this is to filter user instances, since they associate across different entities
      instances = _.filter(instances, x =>
        _.some(x.associatedEntities, entity => entity.id === directParent.id)
      ) // { associatedEntityId: directParent.id })
    }

    return instances.map(instance => {
      const listKey = `${baseKey}_${instance.id}`
      let leftIcon
      const listItemOpen = this.state.open[listKey]

      if (!_.isEmpty(entityType.subEntities)) {
        leftIcon = listItemOpen
          ? EntityItemList.minusIcon()
          : EntityItemList.plusIcon()
      }

      const instanceText = entityType.showAttr
        .map(attr => instance[attr])
        .join(' ')
      const subEntityProperties = _.pick(
        this.props.entityTypes,
        entityType.subEntities
      )

      return (
        <ListItem
          key={listKey}
          className="entity-item"
          primaryTogglesNestedList
          rightToggle={<div />}
          leftIcon={leftIcon || <div />}
          onClick={() =>
            this.handleInstanceClick(instance, entityType, listKey)}
          primaryText={instanceText}
          nestedItems={this.entityTypeList(
            subEntityProperties,
            ancestorList.concat(instance),
            listKey
          )}
          rightIconButton={
            <span>
              {this.editIcon(entityType, instance)}
              {this.deleteIcon(entityType, instance)}
            </span>
          }
        />
        // <EntityItem
        //   toggleState={listItemOpen}
        //   key={listKey}
        //   className="entity-list"
        //   entity={instance}
        //   entityDefinition={entity}
        // ></EntityItem>
      )
    })
  }

  render() {
    const {
      selectEntityType,
      selectEntity,
      selectParentEntity,
      toggleSidebar,
    } = this.props
    const listItems = this.instancesOfEntityType(
      this.props.entityTypes.IndependentEntity,
      [],
      'root'
    )
    return (
      <div>
        <Button
          onClick={() => {
            selectEntityType(this.props.entityTypes.Bank)
            selectEntity()
            selectParentEntity(null)
            toggleSidebar()
          }}
        >
          Add IE
        </Button>
        {/* <List>{this.entityTypeList(this.props.entityTypes)}</List> */}
        <List>{listItems}</List>
      </div>
    )
  }
}

EntityItemList.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  selectEntity: PropTypes.func,
  selectEntityType: PropTypes.func,
  selectParentEntity: PropTypes.func,
  deleteBank: PropTypes.func,
  deleteBankCustomer: PropTypes.func,
  deleteUser: PropTypes.func,
  deleteCounterparty: PropTypes.func,
  deleteBroker: PropTypes.func,
  deleteIndependentEntity: PropTypes.func,
  deletePrivateEquity: PropTypes.func,
  getAssociated: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
  independents: PropTypes.arrayOf(EntityPropType.bankPropType),
  banks: PropTypes.arrayOf(EntityPropType.bankPropType),
  bankCustomers: PropTypes.arrayOf(EntityPropType.bankCustomerPropType),
  privateEquities: PropTypes.arrayOf(EntityPropType.privateEquityPropType),
  counterParties: PropTypes.arrayOf(EntityPropType.counterpartyPropType),
  clearingAgents: PropTypes.arrayOf(EntityPropType.clearingAgentPropType),
  users: PropTypes.arrayOf(EntityPropType.userPropType),
  brokers: PropTypes.arrayOf(EntityPropType.brokerPropType),
  entityTypes: PropTypes.objectOf(EntityPropType.entityTypePropType),
  selectedEntity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.userPropType,
  ]),
}

const mapDispatchToProps = {
  selectEntityType: actions.accounts.entitytype.set,
  selectParentEntity: actions.accounts.parent.set,
  toggleSidebar: actions.sidebar.toggle,
  selectEntity: actions.accounts.entity.set,
  deleteBank: actions.bank.delete,
  deleteBankCustomer: actions.bankCustomer.delete,
  deleteUser: actions.user.delete,
  deleteCounterparty: actions.counterparty.delete,
  deletePrivateEquity: actions.privateEquity.delete,
  deleteBroker: actions.broker.delete,
  deleteIndependentEntity: actions.independentEntity.delete,
}

export default build({
  component: EntityItemList,
  mapDispatchToProps,
})
