import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import bankCustomerReducer from './bank_customer_reducer'
import bankReducer from './bank_reducer'
import brokerReducer from './broker_reducer'
import clearingAgentReducer from './clearing_agent_reducer'
import commodityPriceReducer from './commodity_price_reducer'
import counterpartyReducer from './counterparty_reducer'
import emailReducer from './email_reducer'
import importReducer from './import_reducer'
import independentEntityReducer from './independent_entity_reducer'
import loginReducer from './login_reducer'
import passwordReducer from './password_reducer'
import pdfGenerationReducer from './pdf_generation_reducer'
import privateEquityReducer from './private_equity_reducer'
import productPriceReducer from './product_price_reducer'
import resetPasswordReducer from './reset_password_reducer'
import selectedUserReducer from './selected_user_reducer'
import entityReducer from './entity_reducer'
import sidebarReducer from './sidebar_reducer'
import tradeBlotterReducer from './trade_blotter_reducer'
import tradeReducer from './trade_reducer'
import userReducer from './user_reducer'
import roleReducer from './role_reducer'
import tradeEntryReducer from './trade_entry_reducer'
import productReducer from './product_reducer'
import enumReducer from './enum_reducer'
import productPriceDatesReducer from './product_price_dates_reducer'
import tradeSummaryReducer from './trade_summary_reducer'
import tradeGroupReducer from './trade_group_reducer'
import tradeEditReducer from './trade_edit_reducer'
import reportReducer from './report_reducer'
import accountsReducer from './accounts_reducer'
import notificationReducer from './notification_reducer'
import holidayReducer from './holiday_reducer'
/**
 * Order alphabetically to find easier each reducer
 */
export default combineReducers({
  accounts: accountsReducer,
  bankCustomer: bankCustomerReducer,
  bank: bankReducer,
  broker: brokerReducer,
  clearingAgent: clearingAgentReducer,
  commodityPrice: commodityPriceReducer,
  counterparty: counterpartyReducer,
  email: emailReducer,
  entity: entityReducer,
  enum: enumReducer,
  holiday: holidayReducer,
  import: importReducer,
  independentEntity: independentEntityReducer,
  login: loginReducer,
  notification: notificationReducer,
  password: passwordReducer,
  pdf: pdfGenerationReducer,
  privateEquity: privateEquityReducer,
  product: productReducer,
  productPrice: productPriceReducer,
  productPriceDate: productPriceDatesReducer,
  resetPassword: resetPasswordReducer,
  routing: routerReducer,
  selected: selectedUserReducer,
  sidebar: sidebarReducer,
  tradeBlotter: tradeBlotterReducer,
  tradeGroup: tradeGroupReducer,
  tradeEdit: tradeEditReducer,
  tradeEntry: tradeEntryReducer,
  trades: tradeReducer,
  tradeSummaries: tradeSummaryReducer,
  user: userReducer,
  role: roleReducer,
  loadingBar: loadingBarReducer,
  report: reportReducer,
})
