import React from 'react'
import MarkToMarketSummary from './MarkToMarketSummary'
import { settleDateRouteType } from '../../shared_prop_types'

const MarkToMarketSummaryReport = props => (
  <MarkToMarketSummary {...props.match.params} {...props} />
)

MarkToMarketSummaryReport.propTypes = {
  match: settleDateRouteType.isRequired,
}

export default MarkToMarketSummaryReport
