import React from 'react'
import PropTypes from 'prop-types'
import Pagify from 'react-pagify'
import segmentize from 'segmentize'

import styles from './stylesheet.styl'

const Paginator = ({ pagination, pages, onSelect }) => (
  <Pagify.Context
    className={styles.pagination}
    segments={segmentize({
      page: pagination.page,
      pages: pagination.pages,
      beginPages: 3,
      endPages: 3,
      sidePages: 2,
    })}
    onSelect={onSelect}
  >
    <Pagify.Button page={pagination.page - 1}>Previous</Pagify.Button>

    <Pagify.Segment field="beginPages" />

    <Pagify.Ellipsis
      className="ellipsis"
      previousField="beginPages"
      nextField="previousPages"
    />

    <Pagify.Segment field="previousPages" />
    <Pagify.Segment field="centerPage" className="selected" />
    <Pagify.Segment field="nextPages" />

    <Pagify.Ellipsis
      className="ellipsis"
      previousField="nextPages"
      nextField="endPages"
    />

    <Pagify.Segment field="endPages" />

    <Pagify.Button page={pagination.page + 1}>Next</Pagify.Button>
  </Pagify.Context>
)
Paginator.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    pages: PropTypes.number,
  }),
  pages: PropTypes.number,
  onSelect: PropTypes.func,
}

export default Paginator
