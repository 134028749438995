import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const EditTable = props => {
  // const [values, setItems] = React.useState(props.values)

  //   useEffect(
  //     () => {
  //       const { onChange } = props
  //       if (onChange) onChange(values)
  //     },
  //     [values]
  //   )

  const handleChange = async (items, newItem) => {
    const { onChange } = props
    if (onChange) {
      onChange(items, newItem)
    }
  }

  // update state if props.values change
  //   useEffect(
  //     () => {
  //       setItems(props.values)
  //     },
  //     [props.values]
  //   )

  const addItem = async newItem => {
    // await setItems(items)
    const values = props.values || []
    handleChange([...values, newItem], newItem)
  }

  const removeItem = async address => {
    const values = props.values || []
    // await setItems(values.filter(x => address !== x))
    handleChange(values.filter(x => address !== x))
  }

  const updateItem = async (newItem, oldItem) => {
    const values = props.values || []
    // await setItems(values.map(x => (x === oldItem ? newItem : x)))
    handleChange(values.map(x => (x === oldItem ? newItem : x)), newItem)
  }

  return (
    <MaterialTable
      icons={tableIcons}
      title={props.title}
      columns={props.columns}
      data={props.values || []}
      editable={
        props.editable ? (
          {
            onRowAdd: addItem,
            onRowUpdate: updateItem,
            onRowDelete: removeItem,
          }
        ) : null
      }
      options={{
        ...{
          padding: 'dense',
          search: false,
          paging: false,
          toolbar: Boolean(props.editable),
          // paging: values && values.length && values.length > 5,
        },
        ...props.options,
      }}
    />
  )
}

EditTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  options: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
}

EditTable.defaultProps = {
  editable: true,
}

export default EditTable
