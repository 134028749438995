import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import '../../../application.styl'
import styles from './MarkToMarket.styl'
import { build, actions } from '../../container_helpers'
import { currencyDiv } from '../../../helpers/formatCurrency'
import * as EntityPropType from '../../shared_prop_types'

class MarkToMarket extends Component {
  constructor() {
    super()
    this.state = {
      bank: null,
      trades: [],
      firstUpdate: false,
    }
  }

  componentWillMount() {
    const { id, settleDate } = this.props.match.params
    this.props.getReportData(id, { settleDate })
    this.props.getEntity(id)
  }

  componentWillReceiveProps(props) {
    if (
      this.props.match.params.id !== props.match.params.id ||
      this.props.match.params.settleDate !== props.match.params.settleDate
    ) {
      const { id, settleDate } = props.match.params
      this.props.getReportData(id, { settleDate })
      this.props.getEntity(id)
    }
  }

  componentDidUpdate() {
    if (this.isReadyToRender()) {
      if (!this.state.firstUpdate && this.props.onMounted) {
        this.props.onMounted()
        this.setState({ firstUpdate: true })
      }
    }
  }

  isReadyToRender = () => {
    const { reportData, entity } = this.props
    return !_.isEmpty(entity) && !_.isEmpty(reportData)
  }

  renderReport(csvData) {
    const { reportData, entity } = this.props
    const { settleDate } = this.props.match.params

    if (!reportData || !entity) return null
    const logo = `${process.env.API_URL}/${entity.logo}`

    const { grandTotal } = reportData
    return (
      <div className={styles.markToMarket} align="center">
        <table
          className={classnames(
            'summary-table',
            'odd-rows-print',
            csvData && 'csv-table'
          )}
        >
          <thead>
            <tr className="summary-header-1">
              <th align="center" colSpan="19">
                <div>
                  <img
                    className="tappian-report-logo"
                    src="/img/tappian-logo-color.svg"
                    alt="tappian logo"
                  />
                  <h4>{entity.name}</h4>
                  <img className="report-logo" src={logo} alt="bank logo" />
                </div>
              </th>
            </tr>
            <tr className="summary-header-2">
              <th colSpan="2">
                <h4>Date</h4>
              </th>
              <th colSpan="2">
                <h4>{moment(settleDate).format('MM/DD/YYYY')}</h4>
              </th>
              <th colSpan="11">
                <h4>MARK TO MARKET</h4>
              </th>
              <th className="summary-total-label" colSpan="1">
                <h4>Totals</h4>
              </th>
              <th className="summary-total" colSpan="1">
                {csvData ? (
                  grandTotal.tradeMarkToMarket
                ) : (
                  currencyDiv(grandTotal.tradeMarkToMarket)
                )}
              </th>
              <th className="summary-total" colSpan="1">
                {csvData ? (
                  grandTotal.netOptionPremium
                ) : (
                  currencyDiv(grandTotal.netOptionPremium)
                )}
              </th>
              <th className="summary-total" colSpan="1">
                {csvData ? grandTotal.net : currencyDiv(grandTotal.net)}
              </th>
            </tr>
            <tr>
              <th>Trade Date</th>
              <th>Reference ID</th>
              <th>
                Trade<br />Group ID
              </th>
              <th>Buyer</th>
              <th>Seller</th>
              <th>
                Monthly<br />Volume
              </th>
              <th>Product Type</th>
              <th>Product</th>
              <th>Instrument</th>
              <th>
                Strike<br />Price
              </th>
              <th>
                Trade<br />Price
              </th>
              <th>Start Month</th>
              <th>End Month</th>
              <th>
                Settlement<br />Type
              </th>
              <th>
                Payment<br />Days
              </th>
              <th>
                Average<br />Close Price
              </th>
              <th>MTM</th>
              <th>Option Premium</th>
              <th>Net</th>
            </tr>
          </thead>
          <tbody>
            {reportData.items.map(x => (
              <tr key={x.id}>
                <td>{moment(x.tradeDateTime).format('MM/DD/YYYY')}</td>
                <td>{x.referenceId}</td>
                <td>{x.tradeGroupReferenceId}</td>
                <td>{x.buyerName}</td>
                <td>{x.sellerName}</td>
                <td>{x.monthlyVolume.toLocaleString('en-US')}</td>
                <td>{x.productType}</td>
                <td>{x.product}</td>
                <td>{x.instrumentType}</td>
                <td>{csvData ? x.strikePrice : currencyDiv(x.strikePrice)}</td>
                <td>
                  {csvData ? (
                    x.tradePremiumPrice
                  ) : (
                    currencyDiv(x.tradePremiumPrice, 4)
                  )}
                </td>
                <td>{moment(x.startDate).format('MMM-YY')}</td>
                <td>{moment(x.endDate).format('MMM-YY')}</td>
                <td>{x.settlementType.replace('_', ' ')}</td>
                <td>{x.paymentDays}</td>
                <td>
                  {csvData ? (
                    x.averageClosePrice
                  ) : (
                    currencyDiv(x.averageClosePrice, 4)
                  )}
                </td>
                <td>
                  {csvData ? (
                    x.tradeMarkToMarket
                  ) : (
                    currencyDiv(x.tradeMarkToMarket)
                  )}
                </td>
                <td>
                  {csvData ? (
                    x.netOptionPremium
                  ) : (
                    currencyDiv(x.netOptionPremium)
                  )}
                </td>
                <td>{csvData ? x.net : currencyDiv(x.net)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
  render() {
    return (
      <div>
        {this.renderReport()}
        {this.props.csvData && this.renderReport(true)}
      </div>
    )
  }
}
MarkToMarket.propTypes = {
  reportData: PropTypes.object,
  entity: PropTypes.oneOfType([
    EntityPropType.bankCustomerPropType,
    EntityPropType.bankPropType,
    EntityPropType.brokerPropType,
    EntityPropType.privateEquityPropType,
    EntityPropType.counterpartyPropType,
  ]),
  getReportData: PropTypes.func,
  getEntity: PropTypes.func,
  match: EntityPropType.settleDateRouteType.isRequired,
  generateReport: PropTypes.func,
  onMounted: PropTypes.func,
  csvData: PropTypes.bool,
}

const mapDispatchToProps = {
  getReportData: actions.report.getMarkToMarketForEntityId,
  generateReport: actions.pdf.generate,
  getEntity: actions.entity.get,
}

const mapStateToProps = state => {
  return {
    reportData: state.report.MarkToMarket,
    entity: state.entity,
  }
}

export default build({
  component: MarkToMarket,
  mapDispatchToProps,
  mapStateToProps,
})
