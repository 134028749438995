import { handleActions } from 'redux-actions'

const defaultState = {
  sidebarOpen: false,
  entity: {},
}

const reducerMap = {
  TOGGLE: state => {
    return { ...state, sidebarOpen: !state.sidebarOpen }
  },
  CLOSE: state => {
    return { ...state, sidebarOpen: false }
  },
  SETENTITY: (state, { payload }) => {  
    return {
      ...state,
      entity: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
