import { handleActions } from 'redux-actions'

const defaultState = []

const reducerMap = {
  'TRADE/SET': (state, { payload }) => payload,
  'TRADE/ADD': (state, { payload }) => state.concat([payload]),
}

export default handleActions(reducerMap, defaultState)
