import { handleActions } from 'redux-actions'

const defaultState = { products: [], import: {} }

const reducerMap = {
  'PRODUCT/SET': (state, { payload }) => {
    return {
      ...state,
      products: payload,
    }
  },
  'PRODUCT/IMPORT': (state, { payload }) => {
    return {
      ...state,
      import: payload,
    }
  },
}

export default handleActions(reducerMap, defaultState)
