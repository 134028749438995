import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileReaderInput from 'react-file-reader-input'
import { connect } from 'react-redux'
import actions from '../../../store/actions'
import './ProductPriceImport.styl'

class ProductPriceImport extends Component {
  constructor() {
    super()
    this.state = {
      files: [],
      loading: false,
    }
  }
  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
    const haveResult = nextProps.importSuccess || nextProps.importError
    if (this.state.loading && haveResult) {
      this.setState({ loading: false })
    }
  }

  handleFile = (e, results) => {
    this.setState({ files: results.map(x => x[1]) })
  }

  handleSubmit = async e => {
    e.preventDefault()

    await Promise.all(
      this.state.files.map(x => this.props.importProductPrices(x))
    )
    this.setState({ files: [], loading: true })
  }

  resetForm = () => {
    this.props.resetProductPrices()
  }



  render() {

    return (
      <div className="container-fluid">
        <div className="text-center">
          {this.state.loading && (
            <div className="entry">Importing...<CircularProgress/></div>            
          )}
          {this.props.importSuccess && (
            <div className="entry">Successfully imported!</div>
          )}
          {this.props.importError && (
            <div className="entry">Error: {this.props.importError}</div>
          )}
          {(this.props.importSuccess || this.props.importError) && (
            <div className="entry">
              <button onClick={this.resetForm}>Upload again</button>
            </div>
          )}

          {!this.props.importError &&
          !this.props.importSuccess &&
          !this.state.loading && (
            <div className="entry">
              <form onSubmit={this.handleSubmit}>
                <FileReaderInput
                  as="text"
                  id="my-file-input"
                  name="Product Price CSV"
                  accept=".csv,.CSV"
                  placeholder="Product Price CSV"
                  className="inputClass"
                  onChange={this.handleFile}
                  multiple
                >
                  <button type="button">Select a file!</button>
                </FileReaderInput>
                {Boolean(this.state.files.length) && (
                  <button className="import-button">Import!</button>
                )}
              </form>
            </div>
          )}
          {this.props.productPrices.map(productPrice => {
            const value = [
              'productType',
              'product',
              'year',
              'month',
              'instrumentType',
              'strikePrice',
              'closePrice',
              'livePrice',
              'expiresAt',
              'delta',
              'margin',
            ]
              .map(column => `${column}: ${productPrice[column]}`)
              .join(' | ')
            return <div key={productPrice.id}>{value}</div>
          })}
        </div>
      </div>
    )
  }
}

ProductPriceImport.propTypes = {
  getProductPrices: PropTypes.func.isRequired,
  importProductPrices: PropTypes.func.isRequired,
  resetProductPrices: PropTypes.func.isRequired,
  importSuccess: PropTypes.bool,
  importError: PropTypes.number,
  productPrices: PropTypes.arrayOf(PropTypes.object),
}

const mapDispatchToProps = {
  getProductPrices: actions.productPrice.getAll,
  importProductPrices: actions.import.productPricesCsv,
  resetProductPrices: actions.import.resetProductPrices,
}

const mapStateToProps = state => {
  return {
    importSuccess: state.import.success,
    importError: state.import.error,
    productPrices: state.productPrice,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceImport)
