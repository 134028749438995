import { createActions } from 'redux-actions'

const { productprice: productPriceActions } = createActions({
  PRODUCTPRICE: {
    SET: undefined,
    ADD: undefined,
    PUT: undefined,
    DATES: {
      SET: undefined,
    },
  },
})

productPriceActions.getAllDates = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/api/product-prices/dates')
  dispatch(productPriceActions.dates.set(response.data))
}

productPriceActions.getAll = () => ({ axios }) => async dispatch => {
  const response = await axios.get('/api/product-prices')
  dispatch(productPriceActions.set(response.data))
}

productPriceActions.get = userId => ({ axios }) => async dispatch => {
  const response = await axios.get(`/api/product-prices/${userId}`)
  dispatch(productPriceActions.set(response.data))
}

productPriceActions.createOrUpdate = data => ({ axios }) => async dispatch => {
  const response = await axios.post(`/api/product-prices/`, data)
  dispatch(productPriceActions.add(response.data))
}

productPriceActions.getAllByProductId = productId => ({ axios }) => async dispatch => {
  const response = await axios.get(`/api/products/${productId}/prices`)
  dispatch(productPriceActions.set(response.data))
}

export default productPriceActions
